import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BaseViewCardPropsType, FetchDataParams, QueryParamsPropsType } from 'types/wctTypes';
import { filterParams, getOktaToken } from 'utils/common-methods';
import { EHSS_URL } from 'utils/constants';
import { StringKeyDataProps } from 'utils/data-types';
 
interface EhssDataItem {
    overdue?: number | string;
    manager_name?: string;
    manager_staff_id?: string;
    pending?: number | string;
    target?: number | string;
    la_name?: string;
    la_code?: string;
    cost_center_name?: number | string;
    cost_center_code?: number | string;
}

interface ehssCompliancePercentage {
    compliance_percentage?: number | string;
    
    shift_compliance?: number | string;
    cost_center_compliance?: number | string;
}
interface ehssSiteCompliance {
    aoh_compliance?: number | string;
}
 
interface EhssGraphItem {
    compliance_date?: string;
    cumulative_overdue?: number | string;
    cumulative_on_track?: number | string;
    compliance_rate?: number | string;
}
 
interface EhssDataType {
    compliance_percentage: ehssCompliancePercentage;
    site_compliance: ehssSiteCompliance;
    data: EhssDataItem[];
    target: string;
    graph_data: EhssGraphItem[]
    query_params: QueryParamsPropsType
}
  
interface EhssInfoState {
    ehssInfo: BaseViewCardPropsType | null;
    loading: boolean;
    error: boolean;
}
 
const initialState: EhssInfoState = {
    ehssInfo: null,
    loading: false,
    error: false,
}

const fetchEhss = createAsyncThunk<EhssDataType, FetchDataParams>(
    EHSS_URL,
    async (params) => {
        const filteredParams = filterParams(params)
        const token: string = getOktaToken();

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}${EHSS_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
 
        if (!response.ok) {
            throw new Error('Failed to fetch ehss');
        }
        return await response.json();
    }
)

const ehssConvertedData = (data: EhssGraphItem[]): StringKeyDataProps[] => {
    const sortedData = [...data].sort((a, b) => {
      if (
        typeof a.compliance_date === "string" &&
        typeof b.compliance_date === "string"
      ) {
        const prevDate = new Date(a.compliance_date);
        const nextDate = new Date(b.compliance_date);
        const monthDifference = prevDate.getMonth() - nextDate.getMonth();
        if (monthDifference !== 0) {
          return monthDifference;
        }
        return prevDate.getDate() - nextDate.getDate();
      }
      return 0;
    });
    const datas = sortedData.map((item) => {
      if (typeof item.compliance_date === "string") {
        const dateFormat = new Date(item.compliance_date).toLocaleString(
          "en-US",
          { month: "short", day: "numeric" }
        );
        return {
          name: dateFormat,
          value: item.compliance_rate ?? 0,
        };
      }
      return { name: "", value: "" };
    });
    return datas;
  };

const transformData = (ehssData: EhssDataType): BaseViewCardPropsType => {
    const ehssBaseViewData: BaseViewCardPropsType = {
        cardData: {},
        graphData: [],
        graphParams: {},
        cardProps: {
            alertType: 'success'
        }
    }

    const tierLabel = ehssData.query_params?.tierLabel

    const EhssSanitizedData: StringKeyDataProps = {};
    let lineCompliancePercentage;
    let shiftCompliancePercentage;
    let costCenterCompliancePercentage;
    let siteCompliancePercentage

    if (ehssData?.compliance_percentage && Object.keys(ehssData?.compliance_percentage).length > 0) {
      lineCompliancePercentage = Number(
        Number(
          ehssData?.compliance_percentage?.compliance_percentage
        ).toFixed(2)
      );
      shiftCompliancePercentage = Number(
        Number(ehssData?.compliance_percentage?.shift_compliance).toFixed(
          2
        )
      );
      costCenterCompliancePercentage = Number(
        Number(
          ehssData?.compliance_percentage?.cost_center_compliance
        ).toFixed(2)
      );
    }
    if (ehssData?.site_compliance && Object.keys(ehssData?.site_compliance).length) {
      siteCompliancePercentage = Number(
        Number(ehssData?.site_compliance?.aoh_compliance).toFixed(2)
      );
    }
    if (
      typeof lineCompliancePercentage === "number" &&
      lineCompliancePercentage >= 0
    ) {
      EhssSanitizedData["LINE COMPLIANCE %"] = lineCompliancePercentage;
    }
    if (
      typeof shiftCompliancePercentage === "number" &&
      shiftCompliancePercentage >= 0
    ) {
      EhssSanitizedData["SHIFT COMPLIANCE %"] = shiftCompliancePercentage;
    }
    if (tierLabel.toLowerCase() !== "tier 3") {
      if (
        typeof costCenterCompliancePercentage === "number" &&
        costCenterCompliancePercentage >= 0
      ) {
        EhssSanitizedData["BUILDING COMPLIANCE %"] =
          costCenterCompliancePercentage;
      }
    }
    if (
      typeof siteCompliancePercentage === "number" &&
      siteCompliancePercentage >= 0
    ) {
      EhssSanitizedData["SITE COMPLIANCE %"] = siteCompliancePercentage;
    }
    ehssData?.data?.forEach((item, index) => {
      if (typeof item.la_name === "string") {
        EhssSanitizedData[" " + "&" + item.la_name] = item.pending ?? "";
      }
    });
    ehssData?.data?.forEach((item, index) => {
      if (typeof item.manager_name === "string") {
        EhssSanitizedData[item.manager_name] = item.pending ?? "";
      }
    });
    ehssData?.data?.forEach((item, index) => {
      if (typeof item.cost_center_name === "string") {
        EhssSanitizedData[item.cost_center_name] = item.overdue ?? "";
      }
    })

    if (parseInt(ehssData.target) >= 0) {
      ehssBaseViewData.graphParams.referenceLine = ehssData.target
    }

    if (
      tierLabel?.toLowerCase() === "tier 0" ||
      tierLabel?.toLowerCase() === "tier 1" ||
      tierLabel?.toLowerCase() === "tier 2"
    ) {
      ehssBaseViewData.graphParams.tooltipContent = "% Compliance each day over last 5 Days"
    } else if (tierLabel?.toLowerCase() === "tier 3") {
      ehssBaseViewData.graphParams.tooltipContent = "% Compliance each week over last 6 Months"
    }
// code commentde don't remove to further uses
    // if (
    //   tierLabel?.toLowerCase() === "tier 0" &&
    //   typeof lineCompliancePercentage === "number" &&
    //   lineCompliancePercentage <= 95
    // ) {
    //   ehssBaseViewData.cardProps.alertType = "error"
    // } else if (
    //   tierLabel?.toLowerCase() === "tier 1" &&
    //   typeof shiftCompliancePercentage === "number" &&
    //   shiftCompliancePercentage <= 95
    // ) {
    //   ehssBaseViewData.cardProps.alertType = "error"
    // } else if (
    //   tierLabel?.toLowerCase() === "tier 2" &&
    //   typeof costCenterCompliancePercentage === "number" &&
    //   costCenterCompliancePercentage <= 95
    // ) {
    //   ehssBaseViewData.cardProps.alertType = "error"
    // } else if (
    //   tierLabel?.toLowerCase() === "tier 3" &&
    //   typeof siteCompliancePercentage === "number" &&
    //   siteCompliancePercentage <= 95
    // ) {
    //   ehssBaseViewData.cardProps.alertType = "error"
    // } else {
    //   ehssBaseViewData.cardProps.alertType = "success"
    // }

    if (ehssData?.graph_data?.length) {
      const outputData = ehssConvertedData(ehssData?.graph_data);
      ehssBaseViewData.graphData = outputData
      ehssBaseViewData.graphParams.isGraph = true
    } else {
      ehssBaseViewData.graphData = []
      ehssBaseViewData.graphParams.isGraph = false
    }
    ehssBaseViewData.cardData = EhssSanitizedData

    return ehssBaseViewData
  }

const ehssReducer = createSlice({
    name: 'ehss',
    initialState,
    reducers: {
        clearEhssData(state) {
            state.ehssInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEhss.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchEhss.fulfilled, (state, action) => {
                state.loading = false;
                state.ehssInfo = transformData(action.payload)
            })
            .addCase(fetchEhss.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
 
    },
});
 
export { fetchEhss };

export const { clearEhssData } = ehssReducer.actions
 
export default ehssReducer.reducer;