import { Button, StatusIssueIcon, StatusPositiveIcon } from '@gitlab-rtsensing/component-library';
import { Alert } from 'antd';
import CustomMetricCard from 'components/resuable/CustomMetricCard/CustomMetricCard'
import metricsCss from "./MetricsComponent.module.css"
import { MetricNames } from 'utils/constants';
import { getFormattedMonthYear } from 'utils/common-methods';
import React, { useEffect, useState } from 'react'

interface CalClosureType {
    classification: string;
    count: number;
}

interface CustomAlertCSSProperties extends React.CSSProperties {
    "--alert-top-position"?: string;
}

interface MetricsContainerProps {
    metrics: { key: number; label: string }[];
    sqdipType: string
    metricNames: MetricNames
    calendarInfo: any
    incaInfo?: any;
    seeCardsInfo?: any;
    workOrderInfo?: any;
    ehssInfo?: any;
    capaInfo?: any;
    minorDeviationInfo?: any
    majorDeviationInfo?: any
    gmpTrainingInfo?: any
    qualityChangeControlInfo?: any
    qualityCAPAInfo?: any
    qualityRFTInfo?: any
    agingWorkorderInfo?: any
    pmAdherenceInfo?: any
    deliveryCalibrationInfo?: any
    batchDispoitionInfo?: any
    OnTimeScheduleAdherenceBVInfo?: any
    turnOverInfo?: any
    kanbanHealthBVInfo?: any
    criticalMfcInfo?: any
    setIsOpenDeepDive?: (isOpen: boolean) => void;
    setActiveKeyDeepDiveTab?: (key: string) => void;
    tierLabel: string;
}

const MetricsContainer: React.FC<MetricsContainerProps> = ({
    metrics,
    sqdipType,
    metricNames,
    calendarInfo,
    incaInfo,
    seeCardsInfo,
    workOrderInfo,
    ehssInfo,
    capaInfo,
    minorDeviationInfo,
    majorDeviationInfo,
    gmpTrainingInfo,
    qualityChangeControlInfo,
    qualityCAPAInfo,
    qualityRFTInfo,
    agingWorkorderInfo,
    pmAdherenceInfo,
    deliveryCalibrationInfo,
    setIsOpenDeepDive,
    setActiveKeyDeepDiveTab,
    batchDispoitionInfo,
    OnTimeScheduleAdherenceBVInfo,
    turnOverInfo,
    kanbanHealthBVInfo,
    criticalMfcInfo,
    tierLabel,
}) => {
    const [topPosition, setTopPosition] = useState<string>('280px')

    useEffect(() => {
        if (tierLabel === 'Tier 3') {
            setTopPosition('236px')
        } else {
            setTopPosition('284px')
        }
    }, [tierLabel])

    const customAlertStyle: CustomAlertCSSProperties = {
        '--alert-top-position': topPosition,
    }

    const successAlert = (sqdipType: string): boolean => {
        // switch (sqdipType) {
        //     case "Safety":
        //         return incaInfo?.cardProps.alertType === "success" &&
        //             seeCardsInfo?.cardProps.alertType === "success" &&
        //             workOrderInfo?.cardProps.alertType === "success" &&
        //             ehssInfo?.cardProps.alertType === "success" &&
        //             capaInfo?.cardProps.alertType === "success"
        //     case "Quality":
        //         return minorDeviationInfo?.cardProps.alertType === "success" &&
        //             majorDeviationInfo?.cardProps.alertType === "success" &&
        //             gmpTrainingInfo?.cardProps.alertType === "success"
        //     case "Delivery":
        //         return agingWorkorderInfo?.cardProps.alertType === "success" &&
        //             pmAdherenceInfo?.cardProps.alertType === "success"

        //     default:
        //         return true
        // }
        return true
    }
    return (
        <div id={`wct-${sqdipType.toLowerCase()}`} className={metricsCss.cardContainer}>
            <Alert
                className={metricsCss.customAlert}
                style={customAlertStyle}
                closable={false}
                showIcon
                icon={
                    successAlert(sqdipType) ? (
                        <StatusPositiveIcon
                            fill={"#2CC84D"}
                            height={"20"}
                            width={"20"}
                        />
                    ) : (
                        <StatusIssueIcon
                            fill={"var(--ops-sem-red-primary)"}
                            height={"20"}
                            width={"20"}
                        />
                    )
                }
                message={sqdipType}
                type={`${successAlert(sqdipType) ? "success" : "error"}`}
                action={
                    <Button
                        className={metricsCss.sqdipBtn}
                        label="View"
                        onClick={() => {
                            setIsOpenDeepDive?.(true);
                        }}
                        type="secondary"
                    />
                }
            />

            <CustomMetricCard
                title={`${getFormattedMonthYear()} Summary`}
                isCalendar
                greenDates={calendarInfo?.greenDates}
                redDates={calendarInfo?.redDates}
                className={metricsCss.customSubCard}
                id="safety-month-summary"
            />
            {metrics &&
                metrics.length > 0 &&
                metrics.map(({ key, label }: { key: number; label: string }) => {
                    if (label.toLowerCase() === metricNames?.inca?.toLowerCase()) {
                        return (
                            <CustomMetricCard
                                key={key}
                                title={label}
                                isAlert
                                {...incaInfo?.cardProps}
                                isGrid
                                data={incaInfo?.cardData}
                                {...incaInfo?.graphParams}
                                graphData={incaInfo?.graphData}
                                className={metricsCss.customSubCard}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) =>
                                    setActiveKeyDeepDiveTab?.(key)
                                }
                                id={`safety-${label.toLowerCase().replace(/ /g, "-")}`}
                                actionKey="inca"
                                tierLabel={tierLabel}
                            />
                        );
                    }
                    if (
                        label.toLowerCase() === metricNames?.seeCards?.toLowerCase()
                    ) {
                        return (
                            <CustomMetricCard
                                key={key}
                                title={label}
                                isAlert
                                {...seeCardsInfo?.cardProps}
                                isGrid
                                {...seeCardsInfo?.graphParams}
                                graphData={seeCardsInfo?.graphData}
                                data={seeCardsInfo?.cardData}
                                className={metricsCss.customSubCard}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) =>
                                    setActiveKeyDeepDiveTab?.(key)
                                }
                                id={`safety-${label.toLowerCase().replace(/ /g, "-")}`}
                                actionKey="seecards"
                                tierLabel={tierLabel}
                            />
                        );
                    }

                    if (
                        label.toLowerCase() ===
                        metricNames?.workorder?.toLowerCase()
                    ) {
                        return (
                            <CustomMetricCard
                                key={key}
                                title={label}
                                isAlert
                                {...workOrderInfo?.cardProps}
                                isGrid
                                data={workOrderInfo?.cardData}
                                className={metricsCss.customSubCard}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) =>
                                    setActiveKeyDeepDiveTab?.(key)
                                }
                                id={`safety-${label.toLowerCase().replace(/ /g, "-")}`}
                                actionKey="workorder"
                                tierLabel={tierLabel}
                            />
                        );
                    }

                    if (label.toLowerCase() === metricNames?.ehss?.toLowerCase()) {
                        return (
                            <CustomMetricCard
                                key={key}
                                title={label}
                                label
                                isAlert
                                {...ehssInfo?.cardProps}
                                {...ehssInfo?.graphParams}
                                graphData={ehssInfo?.graphData}
                                isGrid
                                data={ehssInfo?.cardData}
                                className={metricsCss.customSubCard}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) =>
                                    setActiveKeyDeepDiveTab?.(key)
                                }
                                id={`safety-${label.toLowerCase().replace(/ /g, "-")}`}
                                actionKey="ehss"
                                tierLabel={tierLabel}
                            />
                        );
                    }

                    if (label.toLowerCase() === metricNames?.capa?.toLowerCase()) {
                        return (
                            <CustomMetricCard
                                key={key}
                                title={label}
                                isAlert
                                {...capaInfo?.cardProps}
                                {...capaInfo?.graphParams}
                                graphData={capaInfo?.graphData}
                                isGrid
                                data={capaInfo?.cardData}
                                className={metricsCss.customSubCard}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) =>
                                    setActiveKeyDeepDiveTab?.(key)
                                }
                                id={`safety-${label.toLowerCase().replace(/ /g, "-")}`}
                                actionKey="capa"
                                tierLabel={tierLabel}
                            />
                        );
                    }
                    // quality base view
                    if (label.toLowerCase() === metricNames?.rft?.toLowerCase()) {
                        return (
                            <CustomMetricCard
                                key={key}
                                title={label}
                                isAlert
                                {...qualityRFTInfo?.cardProps}
                                {...qualityRFTInfo?.graphParams}
                                graphData={qualityRFTInfo?.trendGraphData}
                                isGrid
                                data={qualityRFTInfo?.cardData}
                                className={metricsCss.customSubCard}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) =>
                                    setActiveKeyDeepDiveTab?.(key)
                                }
                                id={`quality-${label.toLowerCase().replace(/ /g, "-")}`}
                                actionKey="rft"
                                tierLabel={tierLabel}
                            />
                        );
                    }
                    if (
                        label.toLowerCase() ===
                        metricNames?.majorDeviation?.toLowerCase()
                    ) {
                        return (
                            <CustomMetricCard
                                key={key}
                                title={label}
                                isAlert
                                {...majorDeviationInfo?.cardProps}
                                isGrid
                                data={majorDeviationInfo?.cardData}
                                {...majorDeviationInfo?.graphParams}
                                graphData={majorDeviationInfo?.graphData}
                                className={metricsCss.customSubCard}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) =>
                                    setActiveKeyDeepDiveTab?.(key)
                                }
                                id={`quality-${label.toLowerCase().replace(/ /g, "-")}`}
                                actionKey="majordeviation"
                                tierLabel={tierLabel}
                            />
                        );
                    }
                    if (
                        label.toLowerCase() ===
                        metricNames?.minorDeviation?.toLowerCase()
                    ) {
                        return (
                            <CustomMetricCard
                                key={key}
                                title={label}
                                isAlert
                                {...minorDeviationInfo?.cardProps}
                                isGrid
                                data={minorDeviationInfo?.cardData}
                                {...minorDeviationInfo?.graphParams}
                                graphData={minorDeviationInfo?.graphData}
                                className={metricsCss.customSubCard}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) =>
                                    setActiveKeyDeepDiveTab?.(key)
                                }
                                id={`quality-${label.toLowerCase().replace(/ /g, "-")}`}
                                actionKey="minordeviation"
                                tierLabel={tierLabel}
                            />
                        );
                    }
                    if (
                        label.toLowerCase() === metricNames?.gxpTraining?.toLowerCase()
                    ) {
                        return (
                            <CustomMetricCard
                                key={key}
                                title={label}
                                label
                                isAlert
                                {...gmpTrainingInfo?.cardProps}
                                isGrid
                                data={gmpTrainingInfo?.cardData}
                                graphData={gmpTrainingInfo?.graphData}
                                {...gmpTrainingInfo?.graphParams}
                                className={metricsCss.customSubCard}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) =>
                                    setActiveKeyDeepDiveTab?.(key)
                                }
                                id={`quality-${label.toLowerCase().replace(/ /g, "-")}`}
                                actionKey="gxptraining"
                                tierLabel={tierLabel}
                            />
                        );
                    }
                    if (label.toLowerCase() === metricNames?.capastatus?.toLowerCase()) {
                        return (
                            <CustomMetricCard
                                key={"q-capa"}
                                title={label}
                                isAlert
                                {...qualityCAPAInfo?.cardProps}
                                isGrid
                                data={qualityCAPAInfo?.cardData}
                                className={metricsCss.customSubCard}
                                tooltipContent={""}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) =>
                                    setActiveKeyDeepDiveTab?.(key)
                                }
                                actionKey="capastatus"
                                tierLabel={tierLabel}
                            />
                        )
                    }
                    if (label.toLowerCase() === metricNames?.changecontrol?.toLowerCase()) {
                        return (
                            <CustomMetricCard
                                key={key}
                                title={label}
                                isAlert
                                isGrid
                                data={qualityChangeControlInfo?.cardData}
                                isGraph
                                graphData={qualityChangeControlInfo?.graphData}
                                {...qualityChangeControlInfo?.graphParams}
                                {...qualityChangeControlInfo?.cardProps}
                                className={metricsCss.customSubCard}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) =>
                                    setActiveKeyDeepDiveTab?.(key)
                                }
                                actionKey="changecontrol"
                                tierLabel={tierLabel}
                            />
                        );
                    }
                    if (label.toLowerCase() === metricNames?.agingWorkOrders?.toLowerCase()) {
                        return (
                            <CustomMetricCard
                                key={key}
                                title={label}
                                isAlert
                                {...agingWorkorderInfo?.cardProps}
                                isGrid
                                data={agingWorkorderInfo?.cardData}
                                {...agingWorkorderInfo?.graphParams}
                                graphData={agingWorkorderInfo?.graphData}
                                className={metricsCss.customSubCard}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) =>
                                    setActiveKeyDeepDiveTab?.(key)
                                }
                                id={`delivery-${label.toLowerCase().replace(/ /g, "-")}`}
                                actionKey="agingworkorder"
                                tierLabel={tierLabel}
                            />
                        );
                    }
                    if (label.toLowerCase() === metricNames?.pmAdherence?.toLowerCase()) {
                        return (
                            <CustomMetricCard
                                key={key}
                                title={label}
                                isAlert
                                {...pmAdherenceInfo?.cardProps}
                                isGrid
                                data={pmAdherenceInfo?.cardData}
                                {...pmAdherenceInfo?.graphParams}
                                graphData={pmAdherenceInfo?.graphData}
                                progressBarType={pmAdherenceInfo?.progressBarType}
                                pieChartType={pmAdherenceInfo?.pieChartType}
                                className={metricsCss.customSubCard}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) =>
                                    setActiveKeyDeepDiveTab?.(key)
                                }
                                id={`delivery-${label.toLowerCase().replace(/ /g, "-")}`}
                                actionKey="pmadherence"
                                tierLabel={tierLabel}
                            />
                        );
                    }
                    if (label.toLowerCase() === metricNames?.calClosureAdherence?.toLowerCase()) {
                        return <CustomMetricCard
                            key={key}
                            title={label}
                            isAlert
                            // alertType={isCalClosureOverdue}
                            isGrid
                            {...deliveryCalibrationInfo?.graphParams}
                            data={deliveryCalibrationInfo?.cardData}
                            graphData={deliveryCalibrationInfo?.trendGraphData}
                            horizontalgraphData={deliveryCalibrationInfo?.horizontalChart}
                            className={metricsCss?.customSubCard}
                            setIsOpenDeepDive={setIsOpenDeepDive}
                            setActiveKeyDeepDiveTab={(key: string) =>
                                setActiveKeyDeepDiveTab?.(key)
                            }
                            id={`delivery-${label.toLowerCase().replace(/ /g, "-")}`}
                            actionKey="calibrationClosure"
                            tierLabel={tierLabel}
                        />
                    }
                    if (
                        label.toLowerCase() ===
                        metricNames?.batchDisposition?.toLowerCase()) {
                        return (
                            <CustomMetricCard
                                key={key}
                                title={label}
                                isAlert
                                alertType={batchDispoitionInfo?.cardProps.alertType}
                                isGraph={false}
                                graphData={batchDispoitionInfo?.trendGraphData}
                                isGrid
                                {...batchDispoitionInfo?.graphParams}
                                data={batchDispoitionInfo?.cardData}
                                className={metricsCss?.customSubCard}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) =>
                                    setActiveKeyDeepDiveTab?.(key)
                                }
                                id={`delivery-${label.toLowerCase().replace(/ /g, "-")}`}
                                actionKey="batchDisposition"
                                tierLabel={tierLabel}
                            />
                        );
                    }
                    if (
                        label.toLowerCase() ===
                        metricNames?.onTimeScheduleAdherence?.toLowerCase()) {
                        return (
                            <CustomMetricCard
                                key={key}
                                title={label}
                                isAlert
                                {...OnTimeScheduleAdherenceBVInfo?.dataParams}
                                data={OnTimeScheduleAdherenceBVInfo}
                                isGrid
                                newInterface
                                className={metricsCss.customSubCard}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) =>
                                    setActiveKeyDeepDiveTab?.(key)
                                }
                                id={`delivery-${label.toLowerCase().replace(/ /g, "-")}`}
                                actionKey="onTimeScheduleAdherence"
                                tierLabel={tierLabel}
                            />
                        );
                    }
                    if (label.toLowerCase() === metricNames?.inventoryTurnOver?.toLowerCase()) {
                        return (
                            <CustomMetricCard
                                key={key}
                                title={label}
                                isAlert
                                isGraph={false}
                                {...turnOverInfo?.cardProps}
                                isGrid
                                {...turnOverInfo?.graphParams}
                                graphData={turnOverInfo?.graphData}
                                className={metricsCss.customSubCard}
                                setIsOpenDeepDive={label !== "Inventory Turnovers" ? setIsOpenDeepDive : undefined}
                                setActiveKeyDeepDiveTab={label !== "Inventory Turnovers" ? (key: string) => setActiveKeyDeepDiveTab?.(key) : undefined}
                                id={`inventory-${label.toLowerCase().replace(/ /g, "-")}`}
                                actionKey="turnOver"
                                tierLabel={tierLabel}
                            />
                        );
                    }
                    if (label.toLowerCase() === metricNames?.kanbanHealth?.toLowerCase()) {
                        return (
                            <CustomMetricCard
                                key={'i-kanbanHealth'}
                                title={label}
                                isAlert
                                {...kanbanHealthBVInfo?.dataParams}
                                data={kanbanHealthBVInfo}
                                isGrid
                                newInterface
                                className={metricsCss.customSubCard}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) =>
                                    setActiveKeyDeepDiveTab?.(key)
                                }
                                id={`inventory-${label.toLowerCase().replace(/ /g, "-")}`}
                                actionKey="kanbanHealth"
                                tierLabel={tierLabel}
                            />
                        );
                    }

                    if (label.toLowerCase() === metricNames?.criticalMfc?.toLowerCase()) {
                        return (
                            <CustomMetricCard
                                key={key}
                                title={label}
                                isAlert
                                {...criticalMfcInfo?.cardProps}
                                isGrid
                                {...criticalMfcInfo?.graphParams}
                                data={criticalMfcInfo?.cardData}
                                graphData={criticalMfcInfo?.graphData}
                                className={metricsCss.customSubCard}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) =>
                                    setActiveKeyDeepDiveTab?.(key)
                                }
                                id={`inventory-${label.toLowerCase().replace(/ /g, "-")}`}
                                actionKey="criticalMfc"
                                tierLabel={tierLabel}
                            />
                        );
                    }
                    return null
                })}
        </div>
    )
}

export default MetricsContainer;