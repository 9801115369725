import { barSeriesConfig, lineSeriesConfig, dashedLineSeriesConfig, verticalBarBaseViewConfig } from './chartConfig';
import { ChartConfigProps, COLORS, DataItem } from './constants'; // Adjust the import path as needed

export const getSeriesData = (chartType: string, data: DataItem[], chartConfig?: ChartConfigProps[]) => {
    if (!data) return [];
    if (chartType === "global") {
        const seriesData = chartConfig?.map(({ nameKey, dataKey, statusValue, circleColor }) => {
            return {
                name: statusValue,
                data: data.filter((d: any) => d[nameKey] === statusValue).map((d: any) => d[dataKey] ?? 0),
                ...barSeriesConfig,
                ...(circleColor ? { itemStyle: { color: circleColor } } : {}),
            }
        }) ?? [];

        return seriesData;
    } else if (chartType === "rft") {
        return [{
            name: "",
            data: data?.map((d: any) => d.value ?? 0),
            smooth: true,
            showSymbol: false,
            ...dashedLineSeriesConfig,
        }]
    }
    if (chartType === "see-cards") {
        return [
            {
                name: "Serious",
                data: data.map((d) => d.serious ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.serious },
            },

            {
                name: "Potentially Serious",
                data: data.map((d) => d.potentiallySerious ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.potentiallySerious },
            },

            {
                name: "Major",
                data: data.map((d) => d.major ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.major },
            },

            {
                name: "Minor",
                data: data.map((d) => d.minor ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.minor },
            },

            {
                name: "Non EHS Issue",
                data: data.map((d) => d.nonEHSIssue ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.nonEHSIssue },
            },
        ];
    } else if (chartType === "minor-deviation-adherence-mtd") {
        return [
            {
                name: "On-Time Minor Deviations",
                data: data?.map((d) => d.on_time_review_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
            },
            {
                name: "Not On-Time Minor Deviations",
                data: data?.map((d) => d.not_on_time_review_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
            },
            {
                name: "% of Minor Deviation Adherence",
                data: data?.map((d) => d.deviation_percentage_mtd ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "minor-deviation-adherence-6m") {
        return [
            {
                name: "On-Time Minor Deviations",
                data: data?.map((d) => d.on_time_review_6m ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
            },
            {
                name: "Not On-Time Minor Deviations",
                data: data?.map((d) => d.not_on_time_review_6m ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
            },
            {
                name: "% of Minor Deviation Adherence",
                data: data?.map((d) => d.deviation_percentage_6m ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "minor-deviation-aging-mtd") {
        return [
            {
                name: "Non-Aging Minor Deviations",
                data: data?.map((d) => d.open_count_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
            },
            {
                name: "Aging Minor Deviations",
                data: data?.map((d) => d.open_aging_count_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
            },
            {
                name: "% of Aging Minor Deviations",
                data: data?.map((d) => d.aging_open_percentage_mtd ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "minor-deviation-aging-6m") {
        return [
            {
                name: "Non-Aging Minor Deviations",
                data: data?.map((d) => d.open_count_6m ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
                yAxisIndex: 1
            },
            {
                name: "Aging Minor Deviations",
                data: data?.map((d) => d.open_aging_count_6m ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
                yAxisIndex: 1
            },
            {
                name: "% of Aging Minor Deviations",
                data: data?.map((d) => d.aging_open_percentage_6m ?? 0),
                ...lineSeriesConfig,
            },
        ];
    } else if (chartType === "minor-deviation") {
        return [
            {
                name: "Non-Aging Minor Deviations",
                data: data?.map((d) => d.open_count_7d ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
            },
            {
                name: "Aging Minor Deviations",
                data: data?.map((d) => d.open_aging_count_7d ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
            },
            {
                name: "Trend of daily Coming Due Minor Deviations",
                data: data?.map((d) => d.coming_due_last_7d_count ?? 0),
                barWidth: 16,
                ...lineSeriesConfig,
                stack: "total",
            },
        ];
    } else if (chartType === "major-deviation-adherence-mtd") {
        return [
            {
                name: "On-Time Major Deviations",
                data: data?.map((d) => d.on_time_review_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
            },
            {
                name: "Not On-Time Major Deviations",
                data: data?.map((d) => d.not_on_time_review_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
            },
            {
                name: "% of Major Deviation Adherence",
                data: data?.map((d) => d.deviation_percentage_mtd ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "major-deviation-adherence-6m") {
        return [
            {
                name: "On-Time Major Deviations",
                data: data?.map((d) => d.on_time_review_6m ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
            },
            {
                name: "Not On-Time Major Deviations",
                data: data?.map((d) => d.not_on_time_review_6m ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
            },
            {
                name: "% of Major Deviation Adherence",
                data: data?.map((d) => d.deviation_percentage_6m ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "major-deviation-aging-mtd") {
        return [
            {
                name: "Non-Aging Major Deviations",
                data: data?.map((d) => d.open_count_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
            },
            {
                name: "Aging Major Deviations",
                data: data?.map((d) => d.open_aging_count_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
            },
            {
                name: "% of Aging Major Deviations",
                data: data?.map((d) => d.aging_open_percentage_mtd ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "major-deviation-aging-6m") {
        return [
            {
                name: "Non-Aging Major Deviations",
                data: data?.map((d) => d.open_count_6m ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
            },
            {
                name: "Aging Major Deviations",
                data: data?.map((d) => d.open_aging_count_6m ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
            },
            {
                name: "% of Aging Major Deviations",
                data: data?.map((d) => d.aging_open_percentage_6m ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "major-deviation") {
        return [
            {
                name: "Non-Aging Major Deviations",
                data: data?.map((d) => d.open_count_7d ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
            },
            {
                name: "Aging Major Deviations",
                data: data?.map((d) => d.open_aging_count_7d ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
            },
            {
                name: "Trend of daily Coming Due Major Deviations",
                data: data?.map((d) => d.coming_due_last_7d_count ?? 0),
                barWidth: 16,
                ...lineSeriesConfig,
                stack: "total",
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "change-control-status") {
        return [
            {
                name: "Non-Aging Change Controls",
                data: data?.map((d) => d.normal_last_7days ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
            },
            {
                name: "Aging Change Controls",
                data: data?.map((d) => d.aging_last_7days ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
            },
            {
                name: "Trend of daily Coming Due Change Controls",
                data: data?.map((d) => d.coming_due_last_7d_count ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "change-control-adherence-mtd" || chartType === "change-control-adherence-6m") {
        return [
            {
                name: "On-Time Change Controls",
                data: data?.map((d) => d.on_time_cc ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
            },
            {
                name: "Not On-Time Change Controls",
                data: data?.map((d) => d.not_on_time_cc ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
            },
            {
                name: "% of Change Control Adherence",
                data: data?.map((d) => d.compliance_rate ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "change-control-aging-mtd" || chartType === "change-control-aging-6m") {
        return [
            {
                name: "Non-Aging Change Controls",
                data: data?.map((d) => d.non_aging_cc ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
            },
            {
                name: "Aging Change Controls",
                data: data?.map((d) => d.aging_cc ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
            },
            {
                name: "% of Aging Change Controls",
                data: data?.map((d) => d.compliance_rate ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "capa-status") {
        return [
            {
                name: "Non-Aging CAPAs",
                data: data?.map((d) => d.non_aging_flag ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
            {
                name: "Aging CAPAs",
                data: data?.map((d) => d.aging_flag ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.potentiallySerious },
            },
            {
                name: "Trend of daily Coming CAPAs",
                data: data?.map((d) => d.coming_due ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "capa-status-mtd" || chartType === "capa-status-adherence-6m") {
        return [
            {
                name: "On time CAPAs",
                data: data?.map((d) => d.on_time ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
            {
                name: "Not on time CAPAs",
                data: data?.map((d) => d.not_on_time ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskCondition },
            },
            {
                name: "% of CAPA Adherence",
                data: data?.map((d) => d.aging_percentage ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "capa-status-aging-mtd" || chartType === "capa-status-aging-6m") {
        return [
            {
                name: "Non Aging CAPAs",
                data: data?.map((d) => d.non_aging ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
            {
                name: "Aging CAPAs",
                data: data?.map((d) => d.aging ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.potentiallySerious },
            },
            {
                name: "% of Aging CAPAs",
                data: data?.map((d) => d.aging_percentage ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "ratio-pm" || chartType === "ratio-pm-vs") {
        return [
            {
                name: "Pm vs (CM+GM) ratio",
                data: data?.map((d) => d.pm_vs_cm_gm_ratio ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
        ];
    } else if (chartType === "percent-closure-pm") {
        return [
            {
                name: "Percent closure of PM",
                data: data?.map((d) => d.pm_percentage_6m ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
        ];
    } else if (chartType === "aging-work-orders") {
        return [
            {
                name: 'Open Work Orders',
                data: data?.map((d) => d.openWorkOrders ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
            {
                name: "Closed Work Orders",
                data: data?.map((d) => d.closedWorkOrders ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.potentiallySerious },
            },
        ];
    } else if (chartType === "wo-closure-adherence") {
        return [
            {
                name: "% of Minor Deviation Adherence",
                data: data?.map((d) => d.woClosureAdherence ?? 0),
                ...lineSeriesConfig,
            },
        ];
    } else if (chartType === "wo-calibration-dis") {
        return [
            {
                name: "PMCAL",
                data: data?.map((d) => d.PMCAL ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
            {
                name: "GMCAL (MTD)",
                data: data?.map((d) => d.GMCAL ?? 0),
                type: "bar",
                stack: "total",
                itemStyle: { color: COLORS.safeBehavior },
            },
            {
                name: "CMCAL",
                data: data?.map((d) => d.CMCAL ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.potentiallySerious },
            },
        ];
    } else if (chartType === "per-calibration-closure") {
        return [
            {
                name: "Total Workders",
                data: data?.map((d) => d.total_workorders ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
            {
                name: "Closure Adherence Percentage",
                data: data?.map((d) => d.closure_adherence_percentage ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.potentiallySerious },
            },
            {
                name: "Closed Workorders",
                data: data?.map((d) => d.closed_workorders ?? 0),
                type: "bar",
                stack: "total",
                itemStyle: { color: COLORS.safeBehavior },
            },
        ];
    } else if (chartType === "on-time-schedule-bv") {
        return [
            {
                name: "Quaterly",
                data: data?.map((d) => d.quarterly_actual ?? 0),
                ...verticalBarBaseViewConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
        ];
    } else if (chartType === "brrReviewTarget") {
        return [
            {
                name: "On Time Reviewed BRR",
                data: data?.map((d) => d.on_time_batches_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
            {
                name: "Not On Time Reviewed BRR",
                data: data?.map((d) => d.not_on_time_batches_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.potentiallySerious },
            },
            {
                name: "% of BRR Reviewed Within Target",
                data: data?.map((d) => d.percent_brr_completed_within_target ?? 0),
                // type: "dashed",
                ...lineSeriesConfig,
                itemStyle: { color: COLORS.safeBehavior },
            },
        ];
    } else if (chartType === "avgBatchDltGraph") {
        return [
            {
                name: "Total Dispositioned Batches",
                data: data?.map((d) => d.total_completed ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
            {
                name: "AVG. DLT Per Batches",
                data: data?.map((d) => d.avg_dlt_per_batch ?? 0),
                // type: "dashed",
                ...lineSeriesConfig,
                itemStyle: { color: COLORS.safeBehavior },
            },
        ];
    } else if (chartType === "avgTatWrtTesting") {
        return [
            {
                data: data?.map((d) => d.avg_turn_around_time ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
        ];
    } else if (chartType === "brrCompletedWithinTarget") {
        return [
            {
                name: "On Time BRR",
                data: data?.map((d) => d.on_time_batches_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
            {
                name: "Not On Time BRR",
                data: data?.map((d) => d.not_on_time_batches_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.potentiallySerious },
            },
            {
                name: "% of BRR Completed Within Target",
                data: data?.map((d) => d.percent_brr_completed_within_target ?? 0),
                // type: "dashed",
                ...lineSeriesConfig,
                itemStyle: { color: COLORS.safeBehavior },
            },
        ];
    } else if (chartType === "brrList") {
        return [
            {
                name: "On Time BRR",
                data: data?.map((d) => d.on_time_batches_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
            {
                name: "Not On Time BRR",
                data: data?.map((d) => d.not_on_time_batches_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.potentiallySerious },
            },
            {
                name: "% of BRR Completed Within Target",
                data: data?.map((d) => d.percent_brr_completed_within_target ?? 0),
                // type: "dashed",
                ...lineSeriesConfig,
                itemStyle: { color: COLORS.safeBehavior },
            },
        ];
        
    }  else if (chartType === "batchefordispositionWithTarget") {
        return [
            {
                name: "On Time Batches Dispositioned",
                data: data?.map((d) => d.on_time_disposition_batches ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
            {
                name: "Not On Time Batch Dispositioned",
                data: data?.map((d) => d.not_on_time_disposition_batches ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.potentiallySerious },
            },
            {
                name: "% of Batche Disposition Within Target (MTD)",
                data: data?.map((d) => d.percent_batch_disposition_within_target ?? 0),
                // type: "dashed",
                ...lineSeriesConfig,
                itemStyle: { color: COLORS.safeBehavior },
            },
        ];
    } else {
        return [
            {
                name: "At-Risk Behavior",
                data: data.map((d) => d.atRiskBehavior ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },

            {
                name: "At-Risk Condition",
                data: data.map((d) => d.atRiskCondition ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskCondition },
            },

            {
                name: "Safe Behavior",
                data: data.map((d) => d.safeBehavior ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.safeBehavior },
            },
        ];
    }
};