import { ConfigProvider, Radio } from 'antd';

interface RadioFilter {
  label: string,
  value: string
}

interface RadioFiltersProps {
  filterLabel: string
  selectedFilter?: string;
  radioFilter: RadioFilter[];
  setRadioFilter: (value: string) => void;
}

const RadioFiltersV2: React.FC<RadioFiltersProps> = ({
  filterLabel,
  radioFilter,
  selectedFilter,
  setRadioFilter
}) => {

  return (
    <div className="d-flex" style={{ marginBottom: "10px" }}>
      <div className="ops-fs-5 ops-fw-bold" style={{ marginRight: "15px" }}>
        {filterLabel}:
      </div>
        <Radio.Group options={radioFilter} defaultValue={radioFilter?.[0]?.value}
          onChange={(e) => setRadioFilter(e.target.value)} />
    </div>
  );
};

export default RadioFiltersV2