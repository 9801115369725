import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { APIResponseDataType, FetchDataParams } from 'types/wctTypes';
import { filterParams, getOktaToken } from 'utils/common-methods';
import { BATCH_DISPOSIOTION_DEEP_DIVE_URL } from 'utils/constants';
import { StringKeyAnyDataProps } from 'utils/data-types';

interface BatchDispositionDeepDiveStateProps {
    batchDispositionDeepDiveInfo: APIResponseDataType[] | null;
    batchDispositionFilterData: StringKeyAnyDataProps[] | null;
    loading: boolean;
    error: boolean;
}

const initialState: BatchDispositionDeepDiveStateProps = {
    batchDispositionDeepDiveInfo: null,
    batchDispositionFilterData: null,
    loading: false,
    error: false
};

const fetchBatchDispositionDeepDive = createAsyncThunk<APIResponseDataType[], FetchDataParams>(
    BATCH_DISPOSIOTION_DEEP_DIVE_URL,
    async (params) => {
        const filteredParams = filterParams(params);
        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}${BATCH_DISPOSIOTION_DEEP_DIVE_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch PM Adherence');
        }
        return await response.json();
    }
);

const fetchBatchDispositionFilterData = createAsyncThunk<APIResponseDataType[], FetchDataParams>(
    `${BATCH_DISPOSIOTION_DEEP_DIVE_URL}filter`,
    async (params) => {
        const filteredParams = filterParams(params);
        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}${BATCH_DISPOSIOTION_DEEP_DIVE_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch radio filter data');
        }
        return await response.json();
    }
);

const handleBatchDispositionFilterData = (oldState: { batchDispositionDeepDiveInfo: any[]; }, filerData: { filterType: any; data: any; }) => {
    const state = oldState.batchDispositionDeepDiveInfo
    state?.map((item: { filterType: any; data: any; }) => {
        if (filerData?.filterType === item.filterType) {
            item.data = filerData.data
        }
        return item
    });
    return state

}

const batchDispositionDeepDiveReducer = createSlice({
    name: 'batchDispositionDeepDive',
    initialState,
    reducers: {
        clearBatchDispositionDeepDiveData(state) {
            state.batchDispositionDeepDiveInfo = null;
            state.batchDispositionFilterData = null;
        },

        clearBatchDispositionFilterData(state) {
            state.batchDispositionFilterData = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBatchDispositionDeepDive.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchBatchDispositionDeepDive.fulfilled, (state, action) => {
                state.loading = false;
                state.batchDispositionDeepDiveInfo = action.payload;
            })
            .addCase(fetchBatchDispositionDeepDive.rejected, (state) => {
                state.loading = false;
                state.error = true;
            })
            .addCase(fetchBatchDispositionFilterData.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchBatchDispositionFilterData.fulfilled, (state, action) => {
                state.loading = false;
                state.batchDispositionDeepDiveInfo = handleBatchDispositionFilterData(state as any, action.payload as any);
            })
            .addCase(fetchBatchDispositionFilterData.rejected, (state) => {
                state.loading = false;
                state.error = true;
            });
    }
});

export const { clearBatchDispositionDeepDiveData } = batchDispositionDeepDiveReducer.actions;
export { fetchBatchDispositionDeepDive, fetchBatchDispositionFilterData };
export default batchDispositionDeepDiveReducer.reducer;
