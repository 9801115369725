import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FetchDataParams } from "types/wctTypes";
import { filterParams, getOktaToken } from "utils/common-methods";

const fetchDeepdiveSummary = createAsyncThunk<any, FetchDataParams>(
  "/work-center-team/deepdive-view/summary",
  async (params: FetchDataParams) => {
    const filteredParams = filterParams(params)
    const token: string = getOktaToken();
    const response = await fetch(
      `${process.env.REACT_APP_GENAI_API_URL}/work-center-team/deepdive-view/summary?${new URLSearchParams(filteredParams).toString()}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch deep dive summary");
    }
    return await response.json();
  }
);

const deepdiveSummaryReducer = createSlice({
  name: "deepdiveSummary",
  initialState: {
    deepdiveSummaryInfo: { data: {} as any },
    loading: false,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDeepdiveSummary.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchDeepdiveSummary.fulfilled, (state, action) => {
      state.deepdiveSummaryInfo = action.payload;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(fetchDeepdiveSummary.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export { fetchDeepdiveSummary };
export default deepdiveSummaryReducer.reducer;
