import {
  Drawer,
  Timeline,
  Tag,
  Empty,
  Modal,
  Space,
  Input,
  Button as AntButton,
  message,
} from "antd";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { useEffect, useState } from "react";
import { fetchOverallSummaryDeepdiveDeepdive } from "reducers/sqdipSummaryDeepdiveReducer";
import { fetchWctNotes } from "reducers/wctNotesReducer";
import { postWctNotes } from "reducers/addWctNotesReducer";
import { Button, Loader } from "@gitlab-rtsensing/component-library";
import AIGeneratedIcon from "assets/icons/AIGeneratedIcon";
import wctCss from "./../../../page/workCenterTeam.module.css";

interface DeepdiveDrawerProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (isDrawerOpen: boolean) => void;
  deepDiveAlert: string;
  tier: any;
  metrics: string;
  id: string;
}

function DeepdiveDrawer(props: DeepdiveDrawerProps) {
  const { isDrawerOpen, setIsDrawerOpen, deepDiveAlert, tier, metrics, id } =
    props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [uniqueRecordId, setUniqueRecordId] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const { overallSummaryDeepdiveInfo, isBaseSummaryDeepdiveLoading } =
    useSelector((state: RootState) => state.overallSummaryDeepdive);
  const { wctNotesInfo, isWctNotesLoading } = useSelector(
    (state: RootState) => state.wctNotes
  );
  const { isNotePostLoading } = useSelector(
    (state: RootState) => state.addWctNotes
  );

  useEffect(() => {
    const params = {
      site: "aoh",
      tierNumber: tier,
      metrics: metrics?.toLowerCase(),
      id,
    };
    dispatch(fetchOverallSummaryDeepdiveDeepdive(params));
  }, [isDrawerOpen]);

  const onWctNotesButtonClick = (uniqueId: string) => {
    setIsModalOpen(true);
    setUniqueRecordId(uniqueId);
    const params = {
      site: "aoh",
      tierNumber: tier,
      metrics: metrics?.toLowerCase(),
      id,
      uniqueId,
    };
    dispatch(fetchWctNotes(params));
  };

  const addWctNotes = () => {
    const params = {
      site: "aoh",
      tierNumber: tier,
      metrics: metrics?.toLowerCase(),
      id,
      uniqueId: uniqueRecordId,
      note: noteText,
    };
    dispatch(postWctNotes(params));
    setNoteText("");
    !isNotePostLoading &&
      message
        .loading("Adding note...", 0.7)
        .then(() => onWctNotesButtonClick(uniqueRecordId))
        .then(() => message.success("Note added successfully", 2.5));
  };

  const modalContent = () => {
    return (
      <div>
        <div style={{ maxHeight: "300px", overflowY: "auto" }}>
          {wctNotesInfo && wctNotesInfo?.length === 0 ? (
            <div>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          ) : (
            wctNotesInfo?.map((data: any, index: number) => {
              return (
                <div
                  key={index}
                  className="aoh-mb-3"
                  style={{ fontSize: "12px" }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <strong>{data?.username}</strong>
                    </div>
                    <div>
                      {new Date(data?.datetime)?.toLocaleDateString("en-US")}
                    </div>
                  </div>
                  <div>{data?.note}</div>
                </div>
              );
            })
          )}
        </div>
        <div className="aoh-mt-3">
          <Space.Compact style={{ width: "100%" }}>
            <Input
              placeholder="Add notes..."
              value={noteText}
              onChange={(event) => {
                setNoteText(event.target.value);
              }}
            />
            <AntButton
              type="primary"
              onClick={() => addWctNotes()}
              disabled={noteText === ""}
            >
              Add
            </AntButton>
          </Space.Compact>
        </div>
      </div>
    );
  };

  const renderTimelineItems = () => {
    return Array.isArray(overallSummaryDeepdiveInfo)
      ? overallSummaryDeepdiveInfo.map((data: any, index) => {
          return (
            <Timeline.Item key={index}>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#fafafa",
                  borderRadius: "8px",
                  padding: "16px 16px 10px 16px",
                  border: "1px solid #f0f0f0",
                }}
              >
                {metrics === "safety" && (
                  <div className="d-flex justify-content-end">
                    <Button
                      label="WCT Notes"
                      type="secondary"
                      onClick={() => onWctNotesButtonClick(data?.Id)}
                      className="aoh-mb-2"
                    />
                  </div>
                )}
                {Object.keys(data).map((key) => {
                  return (
                    key !== "Id" && (
                      <div
                        className="d-flex"
                        style={{
                          paddingBottom: "5px",
                        }}
                        key={key}
                      >
                        <div
                          style={{
                            fontSize: "12px",
                            color: "#4a4b4c",
                            fontWeight: 700,
                            width: "30%",
                            marginRight: "12px",
                          }}
                        >
                          {`${key}:`}
                          {key === "Summary" && (
                            <AIGeneratedIcon className={wctCss.aiSVGIcon} />
                          )}
                        </div>
                        <div
                          style={{
                            fontSize: "12px",
                            color: "#262626",
                            fontWeight: 400,
                            width: "70%",
                          }}
                        >
                          {data[key]}
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </Timeline.Item>
          );
        })
      : null;
  };

  return (
    <>
      <Modal
        title="WCT Notes"
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          setNoteText("");
        }}
        onClose={() => {
          setIsModalOpen(false);
          setNoteText("");
        }}
        footer={null}
        width={700}
      >
        {isWctNotesLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Loader />
          </div>
        ) : (
          <div>{modalContent()}</div>
        )}
      </Modal>
      <Drawer
        title={<h3>Deep Dive Alert Details</h3>}
        width={600}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        className="deepdive-drawer"
      >
        <Tag color="blue" style={{ width: "100%", whiteSpace: "break-spaces" }}>
          <div dangerouslySetInnerHTML={{ __html: deepDiveAlert }}></div>
        </Tag>
        <br />
        <br />
        <div>
          <div>
            {isBaseSummaryDeepdiveLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader />
              </div>
            ) : Array.isArray(overallSummaryDeepdiveInfo) &&
              overallSummaryDeepdiveInfo.length === 0 ? (
              <div
                style={{
                  textAlign: "center",
                  padding: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            ) : (
              <Timeline>{renderTimelineItems()}</Timeline>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default DeepdiveDrawer;
