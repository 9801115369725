import { TableColumnsType } from "antd";
import { CapaEVColumnType, CapaStatusColumnType, ChangeControlColumnType, majorDeviationColumnType, minorDeviationColumnType, PMAdherenceTableType, CalClosureTableType, rftColumnType, CalClosureAdherenceColumnType, cirticalMfcColumsType } from "types/wctTypes";
import { ascendDescend } from "./common-methods";
import TooltipWrapper from "./TooltipWrapper";

export enum DEEP_DIVE_UI_CONSTANTS {
    SUMMARY = "Summary",
    VIEW_BY = "VIEW BY",
    TIME_PERIOD = "TIME PERIOD",
    LINE = "line"
}

export enum DataTypeKey {
    IsCard = "isCard",
    IsSummary = "isSummary",
    IsTable = "isTable",
    IsHorizontalChart = "isHorizontalChart",
    IsHorizontalBarChart = "isHorizontalBarChart",
    IsPieChart = "isPieChart",
    IsStackedBarChart = "isStackedBarChart",
    IsStackedLineChart = "isStackedLineChart"
}

export const pmAdherenceDeepDiveFilter = [
    {
        label: "MFG",
        value: "mfg"
    },
    {
        label: "Non-MFG",
        value: "non-mfg"
    }
]

export const summaryCardProps = [
    { key: "deviation_over_the_target", label: "Batch with deviation over the target" },
    { key: "pending_exception_over_the_target", label: "Lines with pending exception over the target" },
    { key: "%_batch_without_deviation_(mtd)", label: "% Batch Without Deviation (MTD)" },
    { key: "avg_deviations_per_closed_batch_(mtd)", label: "Avg deviations per closed batch (mtd)" },
    { key: "avg_exceptions_generated_for_all_closed_batches_(mtd)", label: "Avg exceptions generated for all closed batches (mtd)" },
    { key: "issued_last_24_hours", label: "Safety Critical Work Orders in Last 24 Hours" },
    { key: "overdues", label: "Overdue" },
    { key: "incidents_last_24_hours", label: "Incidents Last 24 Hours" },
    { key: "coming_due_in_5days", label: "Coming Due Next 5 Days" },
    { key: "total_due_in_5_days", label: "Upcoming Due in 5 Days" },
    { key: "total_due_in_2_days", label: "Upcoming Due in 2 Days" },
    { key: "site_compliance_percentage", label: "Site Compliance Percentage" },
    { key: "see_cards_in_last_24_hours", label: "See Cards in Last 24 Hours" },
    { key: "total_entered_last_24hours", label: "Incidents in last 24 Hours" },
    { key: "total_overdue", label: "Overdue" },
    { key: "total_coming_due_in_5days", label: "Coming Due in 5 Days" },
    { key: "total_coming_due_in_2days", label: "Coming Due in 2 Days" },
    { key: "upcoming_due_in_5_days", label: "Upcoming Due in 5 Days" },
    { key: "total_see_cards_today", label: "See Cards Today" },
    { key: "coming_due_in_2days", label: "Coming Due in 2 Days" },
    { key: "total_entered_last_5day", label: "Incidents" },
    { key: "compliance_%_for_line", label: "Compliance % for line" },
    { key: "open_deviation_last_24h", label: "Open Minor Deviations in Last 24 Hours" },
    { key: "unclosed_deviations", label: "Open Minor Deviations" },
    { key: "avg_ageing", label: "Avg. age of unclosed minor deviation" },
    { key: "incidents_in_last_24_hours", label: "Incidents in last 24 Hours" },
    { key: "incidents", label: "Incidents" },
    { key: "overdue", label: "Overdue" },
    { key: "coming_due_in_next_5_days", label: "Coming Due in next 5 Days" },
    { key: "coming_due_in_next_2_days", label: "Coming Due in next 2 Days" },
    { key: "unclosed", label: "unclosed" },
    { key: "mtd_deviation_ad_percentage", label: "% Minor Deviations closed meeting target due date(MTD)" },
    { key: "mtd_aging_deviation_percentage", label: "% Aging open Minor Deviation Target" },
    { key: "overdue_change_control", label: "Overdue Change Control" },
    { key: "overdue_major_deviation", label: "Overdue Major Deviation" },
    { key: "overdue_minor_deviation", label: "Overdue Minor Deviation" },
    { key: "coming_due_major_deviation_in_7_days", label: "Coming Due Major Deviation in 7 Days" },
    { key: "%_aging_major_deviation", label: "% Aging Major Deviation" },
    { key: "%_aging_minor_deviation", label: "% Aging Minor Deviation" },
    { key: "open_major_deviation", label: "Open Major Deviation" },
    { key: "open_minor_deviation", label: "Open Minor Deviation" },
    { key: "%_major_deviations_adherence_(mtd)", label: "% Major Deviations Adherence (MTD)" },
    { key: "%_aging_major_deviations_(mtd)", label: "% Aging Major Deviations (MTD)" },
    { key: "coming_due_in_7days", label: "coming due in 7 days" },
    { key: "coming_due_in_5_days", label: "Coming due in 5 days" },
    { key: "coming_due_in_2_days", label: "Coming due GXP Training in 2 days" },
    { key: "%_compliance", label: "% compliance" },
    {
        key: "coming_due_change_control_in_7_days",
        label: "Coming Due Change Control in 7 Days",
    },
    { key: "open_change_control", label: "Open Change Control" },
    { key: "aging_change_control", label: "% Aging Change Control" },
    { key: "perc_aging_cc", label: "% Aging Change Control" },
    { key: "open_cc", label: "Open Change Control" },
    { key: "coming_due_cc", label: "Coming Due Change Control in 7 Days" },
    { key: "perc_adherence_mtd", label: "% Change Control Adherence (MTD)" },
    { key: "perc_aging_mtd", label: "% Aging Change Control (MTD)" },
    { key: "number_of_overdues", label: "Overdue CAPAs" },
    { key: "coming_due_in_7_days", label: "Coming Due CAPAs in 7 Days" },
    { key: "open_capas", label: "Open CAPAs" },
    { key: "aging_percentage", label: "% Aging CAPAs" },
    { key: "capa_adherence_percentage_mtd", label: "% of CAPA Adherence(MTD)" },
    { key: "capa_aging_percentage_mtd", label: "% of Aging CAPA(MTD)" },
    { key: "capa_ev_percentage_mtd", label: "% CAPA EV(MTD)" },
    { key: "target", label: "Target" },
    { key: "upcoming_pm_wo_for_month", label: "Upcoming PM WO For Month" },
    { key: "completed_pm_for_month", label: "Completed PM For Month" },
    { key: "unscheduled_cms", label: "Unscheduled CMs" },
    { key: "scheduled_cms", label: "Scheduled CMs" },
    { key: "upcoming_cals_for_the_month", label: "Upcoming CAL's For The Month" },
    { key: "PMCAL_For_The_Month", label: "PMCAL For The Month" },
    { key: "CMCAL_For_The_Month", label: "CMCAL For The Month" },
    { key: "GMCAL_For_The_Month", label: "GMCAL For The Month" },
    { key: "Overude BRR", label: "Overdue BRR" },
    { key: "Comming due BRR Within_Next_24_HRS", label: "Comming due BRR Within Next 24 HRS" },
    { key: "Avg_TAT_for_ID_Testing", label: "Avg TAT for ID Testing", },
    { key: "overdue_batches_for_disposition", label: "Overdue Batches For Disposition", },
    { key: "%_batch_dispositioned_within_target(in_last_7_days)", label: "% Batch Dispositioned Within Target (last 7 days)", },
    { key: "Open_Batches_For_Disposition", label: "Open Batches For Disposition", },
    { key: "Avg._DLT_per_batch(Last 7 Days)", label: "Avg. DLT Per Batch (Last 7 Days)", },
    { key: "Batches_With_Failed_ID_Testing(in_last_7_days)", label: "Batches With Failed ID Testing (last 7 days)", },
    { key: "BRR_compleated_Within_target", label: "% BRR Completed Within Target (MTD)", },
    { key: "avg_tat_for_id_testing", label: "Avg TAT For ID Testing (MTD)", },
    { key: "avg_tat_for_wrt_testing", label: "Avg TAT w.r.t. Type of Testing (RAMAN, ELISA, IMMUNO, HPCL)", },
    { key: "%_batch_dispositioned_within_target(MTD)", label: "% Batch Dispositioned Within Target (MTD)", },
    { key: "Avg._DLT_per_batch(MTD)", label: "Avg. DLT Per Batch (MTD)", },
]

export const GXPTableColumnsType: TableColumnsType<any> = [
    {
        title: "DUE DATE",
        dataIndex: "due_date",
        sorter: (a, b) => {
            return ascendDescend(a.due_date, b.due_date);
        },
    },
    {
        title: "NAME OF TASK",
        dataIndex: "name_of_task",
    },
    {
        title: "TASK CODE",
        dataIndex: "task_code",
    },
];

export const DeliveryAgingTableColumnsType: TableColumnsType<any> = [
    {
        title: "WO NO",
        dataIndex: "wo_no",
        sorter: (a, b) => {
            return ascendDescend(a.wo_no, b.wo_no);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />
    },
    {
        title: "WO TYPE",
        dataIndex: "wo_type",
        sorter: (a, b) => {
            return ascendDescend(a.wo_type, b.wo_type);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "ASSET NUMBER",
        dataIndex: "asset_number",
        sorter: (a, b) => {
            return ascendDescend(a.asset_number, b.asset_number);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "STATUS",
        dataIndex: "status",
        sorter: (a, b) => {
            return ascendDescend(a.status, b.status);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "CLASSIFICATION",
        dataIndex: "classification",
        sorter: (a, b) => {
            return ascendDescend(a.classification, b.classification);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "FAILURE CODE",
        dataIndex: "failure_code",
        sorter: (a, b) => {
            return ascendDescend(a.failure_code, b.failure_code);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "ESTIMATE DURATION",
        dataIndex: "estimate_duration",
        sorter: (a, b) => {
            return ascendDescend(a.estimate_duration, b.estimate_duration);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "TARGET START DATE",
        dataIndex: "target_start_date",
        sorter: (a, b) => {
            return ascendDescend(a.target_start_date, b.target_start_date);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "TARGET COMPLETION DATE",
        dataIndex: "target_completion_date",
        sorter: (a, b) => {
            return ascendDescend(a.target_completion_date, b.target_completion_date);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "DESCRIPTION",
        dataIndex: "description",
        sorter: (a, b) => {
            return ascendDescend(a.description, b.description);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
];
export const minorDeviationColumns: TableColumnsType<minorDeviationColumnType> = [
    {
        title: "INITIATED DATE",
        dataIndex: "initiated_date",
        sorter: (a, b) => {
            return ascendDescend(a.initiated_date, b.initiated_date);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "DEVIATION ID",
        dataIndex: "deviation_id",
        sorter: (a, b) => {
            return ascendDescend(a.deviation_id, b.deviation_id);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "STATUS",
        dataIndex: "status",
        sorter: (a, b) => {
            return ascendDescend(a.status, b.status);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "AREA",
        dataIndex: "area",
        sorter: (a, b) => {
            return ascendDescend(a.area, b.area);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "TYPE",
        dataIndex: "nc_type",
        sorter: (a, b) => {
            return ascendDescend(a.nc_type, b.nc_type);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "AGE",
        dataIndex: "age",
        sorter: (a, b) => {
            return ascendDescend(a.age, b.age);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "DEVIATION OWNER",
        dataIndex: "deviation_owner",
        sorter: (a, b) => {
            return ascendDescend(a.deviation_owner, b.deviation_owner);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "QA CONTACT",
        dataIndex: "qa_contact",
        sorter: (a, b) => {
            return ascendDescend(a.qa_contact, b.qa_contact);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "SHORT DESCRIPTION",
        dataIndex: "short_desc",
        sorter: (a, b) => {
            return ascendDescend(a.short_desc, b.short_desc);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
]

export const ChangeControlColumns: TableColumnsType<ChangeControlColumnType> = [
    {
        title: "CC ID",
        dataIndex: "cc_id",
        sorter: (a, b) => {
            return ascendDescend(a.cc_id, b.cc_id);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "STATUS",
        dataIndex: "status",
        sorter: (a, b) => {
            return ascendDescend(a.status, b.status);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "INITIATED DATE",
        dataIndex: "initiated_date",
        sorter: (a, b) => {
            return ascendDescend(a.initiated_date, b.initiated_date);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "CATEGORY TYPE",
        dataIndex: "category_type",
        sorter: (a, b) => {
            return ascendDescend(a.category_type, b.category_type);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "QA OWNER",
        dataIndex: "qa_owner",
        sorter: (a, b) => {
            return ascendDescend(a.qa_owner, b.qa_owner);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "CC OWNER",
        dataIndex: "cc_owner",
        sorter: (a, b) => {
            return ascendDescend(a.cc_owner, b.cc_owner);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "SHORT DESCRIPTION",
        dataIndex: "short_description",
        sorter: (a, b) => {
            return ascendDescend(a.short_description, b.short_description);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "TARGET DUE DATE",
        dataIndex: "target_due_date",
        sorter: (a, b) => {
            return ascendDescend(a.target_due_date, b.target_due_date);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "CLOSURE DATE",
        dataIndex: "closure_date",
        sorter: (a, b) => {
            return ascendDescend(a.closure_date, b.closure_date);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "FUNCTION",
        dataIndex: "function",
        sorter: (a, b) => {
            return ascendDescend(a.function, b.function);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    }
]
  
  export const rftColumnsTier1: TableColumnsType<rftColumnType> = [
    {
      title: "CREATED DATE",
      dataIndex: "created_date",
      sorter: (a, b) => {
        return ascendDescend(a.created_date, b.created_date);
      },
      render: (text: string) => {
        const date = text ? new Date(text) : null;
        if (date) {
          return <TooltipWrapper text={date.toLocaleDateString()} maxWidth={200} />;
        }
  
        return <TooltipWrapper text={''} maxWidth={200} />
      },
    },
    {
      title: "LINE",
      dataIndex: "line",
      sorter: (a, b) => {
        return ascendDescend(a.line, b.line);
      },
      render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
      title: "BATCH",
      dataIndex: "batch",
      sorter: (a, b) => {
        return ascendDescend(a.batch, b.batch);
      },
      render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
      title: "EXCEPTION TYPE",
      dataIndex: "exception_type",
      sorter: (a, b) => {
        return ascendDescend(a.exception_type, b.exception_type);
      },
      render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
      title: "SHORT DESCRIPTION",
      dataIndex: "short_description",
      sorter: (a, b) => {
        return ascendDescend(a.short_desc, b.short_desc);
      },
      render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
  ]
  
  export const rftColumnsTier2: TableColumnsType<rftColumnType> = [
    {
      title: "CREATED DATE",
      dataIndex: "created_date",
      sorter: (a, b) => {
        return ascendDescend(a.created_date, b.created_date);
      },
      render: (text: string) => {
        const date = text ? new Date(text) : null;
        if (date) {
          return <TooltipWrapper text={date.toLocaleDateString()} maxWidth={200} />;
        }
  
        return <TooltipWrapper text={''} maxWidth={200} />
      },
    },
    {
      title: "LINE",
      dataIndex: "line",
      sorter: (a, b) => {
        return ascendDescend(a.line, b.line);
      },
      render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
      title: "BATCH",
      dataIndex: "batch",
      sorter: (a, b) => {
        return ascendDescend(a.batch, b.batch);
      },
      render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
      title: "CRITICALITY_DESC",
      dataIndex: "criticality_desc",
      sorter: (a, b) => {
        return ascendDescend(a.criticality_desc, b.criticality_desc);
      },
      render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
      title: "TYPES",
      dataIndex: "types",
      sorter: (a, b) => {
        return ascendDescend(a.types, b.types);
      },
      render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
      title: "SHORT DESCRIPTION",
      dataIndex: "short_description",
      sorter: (a, b) => {
        return ascendDescend(a.short_desc, b.short_desc);
      },
      render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    }
  ]

export const majorDeviationColumns: TableColumnsType<majorDeviationColumnType> = [
    {
        title: "INITIATED DATE",
        dataIndex: "initiated_date",
        sorter: (a, b) => {
            return ascendDescend(a.initiated_date, b.initiated_date);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "DEVIATION ID",
        dataIndex: "deviation_id",
        sorter: (a, b) => {
            return ascendDescend(a.deviation_id, b.deviation_id);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "STATUS",
        dataIndex: "status",
        sorter: (a, b) => {
            return ascendDescend(a.status, b.status);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "AREA",
        dataIndex: "area",
        sorter: (a, b) => {
            return ascendDescend(a.area, b.area);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "TYPE",
        dataIndex: "nc_type",
        sorter: (a, b) => {
            return ascendDescend(a.nc_type, b.nc_type);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "EXTENSION TIMES",
        dataIndex: "extension_times",
        sorter: (a, b) => {
            return ascendDescend(a.extension_times, b.extension_times);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "DEVIATION OWNER",
        dataIndex: "deviation_owner",
        sorter: (a, b) => {
            return ascendDescend(a.deviation_owner, b.deviation_owner);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "QA CONTACT",
        dataIndex: "qa_contact",
        sorter: (a, b) => {
            return ascendDescend(a.qa_contact, b.qa_contact);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "SHORT DESCRIPTION",
        dataIndex: "short_desc",
        sorter: (a, b) => {
            return ascendDescend(a.short_desc, b.short_desc);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
]

export const CapaStatusColumns: TableColumnsType<CapaStatusColumnType> = [
    {
        title: "CAPA ID",
        dataIndex: "capa_id",
        sorter: (a, b) => {
            return ascendDescend(a.capa_id, b.capa_id);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "STATUS",
        dataIndex: "status",
        sorter: (a, b) => {
            return ascendDescend(a.status, b.status);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "TYPE",
        dataIndex: "type",
        sorter: (a, b) => {
            return ascendDescend(a.type, b.type);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "AREA",
        dataIndex: "area",
        sorter: (a, b) => {
            return ascendDescend(a.area, b.area);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "QA CONTACT",
        dataIndex: "qa_contact",
        sorter: (a, b) => {
            return ascendDescend(a.qa_contact, b.qa_contact);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "CAPA OWNER",
        dataIndex: "capa_owner",
        sorter: (a, b) => {
            return ascendDescend(a.capa_owner, b.capa_owner);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "ORIGINAL TARGET DUE DATE",
        dataIndex: "original_target_due_date",
        sorter: (a, b) => {
            return ascendDescend(a.original_target_due_date, b.original_target_due_date);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "LATEST TARGET DUE DATE",
        dataIndex: "latest_target_due_date",
        sorter: (a, b) => {
            return ascendDescend(a.latest_target_due_date, b.latest_target_due_date);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "CLOSURE DATE",
        dataIndex: "closure_date",
        sorter: (a, b) => {
            return ascendDescend(a.closure_date, b.closure_date);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "SHORT DESCRIPTION",
        dataIndex: "short_description",
        sorter: (a, b) => {
            return ascendDescend(a.short_description, b.short_description);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
]

export const CapaEVColumns: TableColumnsType<CapaEVColumnType> = [
    {
        title: "EV ID",
        dataIndex: "ev_id",
        sorter: (a, b) => {
            return ascendDescend(a.ev_id, b.ev_id);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "STATUS",
        dataIndex: "status",
        sorter: (a, b) => {
            return ascendDescend(a.status, b.status);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "CLINICAL_COMMERCIALS",
        dataIndex: "clinical_commercial",
        sorter: (a, b) => {
            return ascendDescend(a.clinical_commercial, b.clinical_commercial);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "EFFECTIVE",
        dataIndex: "effective",
        sorter: (a, b) => {
            return ascendDescend(a.effective, b.effective);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "QA CONTACT",
        dataIndex: "qa_contact",
        sorter: (a, b) => {
            return ascendDescend(a.qa_contact, b.qa_contact);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "EV OWNER",
        dataIndex: "ev_owner",
        sorter: (a, b) => {
            return ascendDescend(a.ev_owner, b.ev_owner);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "ORIGINAL TARGET DUE DATE",
        dataIndex: "original_target_due_date",
        sorter: (a, b) => {
            return ascendDescend(a.original_target_due_date, b.original_target_due_date);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "LATEST TARGET DUE DATE",
        dataIndex: "latest_target_due_date",
        sorter: (a, b) => {
            return ascendDescend(a.latest_target_due_date, b.latest_target_due_date);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "CLOSURE DATE",
        dataIndex: "closure_date",
        sorter: (a, b) => {
            return ascendDescend(a.closure_date, b.closure_date);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "SHORT DESCRIPTION",
        dataIndex: "short_description",
        sorter: (a, b) => {
            return ascendDescend(a.short_description, b.short_description);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
]

// delivery deep dive

export const pmAdherenceColumns: TableColumnsType<PMAdherenceTableType> = [
    {
        title: "WO NUMBER",
        dataIndex: "wo_number",
        sorter: (a, b) => {
            return ascendDescend(a.wo_number, b.wo_number);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "ASSET NUMBER",
        dataIndex: "assest_number",
        sorter: (a, b) => {
            return ascendDescend(a.assest_number, b.assest_number);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "STATUS",
        dataIndex: "status",
        sorter: (a, b) => {
            return ascendDescend(a.status, b.status);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "CLASSIFICATION",
        dataIndex: "classification",
        sorter: (a, b) => {
            return ascendDescend(a.classification, b.classification);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "FAILURE CODE",
        dataIndex: "failure_code",
        sorter: (a, b) => {
            return ascendDescend(a.failure_code, b.failure_code);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "ESTIMATE DURATION",
        dataIndex: "estmiate_duration",
        sorter: (a, b) => {
            return ascendDescend(a.estmiate_duration, b.estmiate_duration);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "TARGET START DATE",
        dataIndex: "target_startdate",
        sorter: (a, b) => {
            return ascendDescend(a.target_startdate, b.target_startdate);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "TARGET COMPLETION DATE",
        dataIndex: "target_completion_date",
        sorter: (a, b) => {
            return ascendDescend(a.target_completion_date, b.target_completion_date);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "DESCRIPTION",
        dataIndex: "description",
        sorter: (a, b) => {
            return ascendDescend(a.description, b.description);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "TABLE HEADER",
        dataIndex: "table_header",
        sorter: (a, b) => {
            return ascendDescend(a.table_header, b.table_header);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    }
]

export const calClosureAdherenceColumns: TableColumnsType<CalClosureTableType> = [
    {
        title: "WO NO",
        dataIndex: "wo_no",
        sorter: (a, b) => {
            return ascendDescend(a.wo_no, b.wo_no);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "ASSET NO",
        dataIndex: "asset_no",
        sorter: (a, b) => {
            return ascendDescend(a.asset_no, b.asset_no);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "LEVEL",
        dataIndex: "level",
        sorter: (a, b) => {
            return ascendDescend(a.level, b.level);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "LINE",
        dataIndex: "line",
        sorter: (a, b) => {
            return ascendDescend(a.line, b.line);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "ISSUE",
        dataIndex: "issue",
        sorter: (a, b) => {
            return ascendDescend(a.issue, b.issue);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "EV OWNER",
        dataIndex: "ev_owner",
        sorter: (a: any, b: any) => {
            return ascendDescend(a.ev_owner, b.ev_owner);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "ORIGINAL TARGET DUE DATE",
        dataIndex: "original_target_due_date",
        sorter: (a: any, b: any) => {
            return ascendDescend(a.original_target_due_date, b.original_target_due_date);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "LATEST TARGET DUE DATE",
        dataIndex: "latest_target_due_date",
        sorter: (a: any, b: any) => {
            return ascendDescend(a.latest_target_due_date, b.latest_target_due_date);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "CLOSURE DATE",
        dataIndex: "closure_date",
        sorter: (a: any, b: any) => {
            return ascendDescend(a.closure_date, b.closure_date);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "SHORT DESCRIPTION",
        dataIndex: "short_description",
        sorter: (a: any, b: any) => {
            return ascendDescend(a.short_description, b.short_description);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
];

export const BatchDispositionBRRColumns: TableColumnsType<CalClosureAdherenceColumnType> = [
    {
        title: "CRETAED DATE",
        dataIndex: "created_date",
        sorter: (a, b) => {
            return ascendDescend(a.ev_id, b.ev_id);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "AGE",
        dataIndex: "status",
        sorter: (a, b) => {
            return ascendDescend(a.status, b.status);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "LINE",
        dataIndex: "clinical_commercial",
        sorter: (a, b) => {
            return ascendDescend(a.clinical_commercial, b.clinical_commercial);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
    {
        title: "BATCH NUMBER",
        dataIndex: "effective",
        sorter: (a, b) => {
            return ascendDescend(a.effective, b.effective);
        },
        render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
    },
];

export const batchDispositionDeepDiveFilter = {
    brrReviewTarget: [
        {
            label: "Last 4 Weeks",
            value: "last_4w"
        },
        {
            label: "Last 6 Months",
            value: "last_6m"
        }
    ],
    idTestingBatches: [{
        label: "All",
        value: "all"
    },
    {
        label: "Overdue",
        value: "overdue"
    },
    {
        label: "Coming Due",
        value: "comming_due"
    },
    {
        label: "Open",
        value: "open"
    },
    {
        label: "Close",
        value: "close"
    }
    ],
    brrList: [{
        label: "All",
        value: "all"
    },
    {
        label: "Overdue",
        value: "overdue"
    },
    {
        label: "Coming Due",
        value: "comming_due"
    },
    {
        label: "Open",
        value: "open"
    },
    {
        label: "Close",
        value: "close"
    }
    ],
    batchefordisposition: [
        {
            label: "All",
            value: "all"
        },
        {
            label: "Dispositioned",
            value: "dispositioned"
        },
        {
            label: "Pending",
            value: "pending"
        },
    ],
    brrCompletedWithinTarget: [
        {
            label: "MTD",
            value: "mtd"
        },
        {
            label: "Last 6 Months",
            value: "last_6m"
        }
    ],
    batchefordispositionWithTarget: [
        {
            label: "MTD",
            value: "mtd"
        },
        {
            label: "Last 6 Months",
            value: "last_6m"
        }
    ],
    avgDLTPerBatch: [
        {
            label: "MTD",
            value: "mtd"
        },
        {
            label: "Last 6 Months",
            value: "last_6m"
        }
    ],
    batchefordispositionTier1: [{
        label: "All",
        value: "all"
    },
    {
        label: "Overdue",
        value: "overdue"
    },
    {
        label: "Coming Due",
        value: "comming_due"
    },
    {
        label: "Open",
        value: "open"
    },
    {
        label: "Close",
        value: "close"
    }
    ],
}

export const batchDispositionDeepDiveTableColumns = {
    brrReviewTarget: [],
    idTestingBatches: [
        {
            title: "BATCH NUMBER",
            dataIndex: "batch_number",
            sorter: (a: { batch_number: string | number; }, b: { batch_number: string | number; }) => {
                return ascendDescend(a.batch_number, b.batch_number);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "TOT",
            dataIndex: "tot",
            sorter: (a: { tot: string | number; }, b: { tot: string | number; }) => {
                return ascendDescend(a.tot, b.tot);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "STATUS",
            dataIndex: "status",
            sorter: (a: { status: string | number; }, b: { status: string | number; }) => {
                return ascendDescend(a.status, b.status);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "TYPE OF TEST",
            dataIndex: "type_of_test",
            sorter: (a: { type_of_test: string | number; }, b: { type_of_test: string | number; }) => {
                return ascendDescend(a.type_of_test, b.type_of_test);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "PRODUCT NAME",
            dataIndex: "product_name",
            sorter: (a: { product_name: string | number; }, b: { product_name: string | number; }) => {
                return ascendDescend(a.product_name, b.product_name);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "WITHIN TARGET OR NOT",
            dataIndex: "within_target_or_not",
            sorter: (a: { within_target_or_not: string | number; }, b: { within_target_or_not: string | number; }) => {
                return ascendDescend(a.within_target_or_not, b.within_target_or_not);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },

    ],
    brrList: [
        {
            title: "CREATED DATE",
            dataIndex: "created_date",
            sorter: (a: { created_date: string | number; }, b: { created_date: string | number; }) => {
                return ascendDescend(a.created_date, b.created_date);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "AGE",
            dataIndex: "age",
            sorter: (a: { age: string | number; }, b: { age: string | number; }) => {
                return ascendDescend(a.age, b.age);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "LINE",
            dataIndex: "line",
            sorter: (a: { line: string | number; }, b: { line: string | number; }) => {
                return ascendDescend(a.line, b.line);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "BATCH NUMBER",
            dataIndex: "batch_number",
            sorter: (a: { batch_number: string | number; }, b: { batch_number: string | number; }) => {
                return ascendDescend(a.batch_number, b.batch_number);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        }
    ],
    batchefordisposition: [
        {
            title: "BATCH NUMBER",
            dataIndex: "batch_number",
            sorter: (a: { batch_number: string | number; }, b: { batch_number: string | number; }) => {
                return ascendDescend(a.batch_number, b.batch_number);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "ON TIME/LAST",
            dataIndex: "on_time_last",
            sorter: (a: { on_time_last: string | number; }, b: { on_time_last: string | number; }) => {
                return ascendDescend(a.on_time_last, b.on_time_last);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "DLT TIME/AGE",
            dataIndex: "dlt_time_age",
            sorter: (a: { dlt_time_age: string | number; }, b: { dlt_time_age: string | number; }) => {
                return ascendDescend(a.dlt_time_age, b.dlt_time_age);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "DLT START DATE",
            dataIndex: "dlt_start_date",
            sorter: (a: { dlt_start_date: string | number; }, b: { dlt_start_date: string | number; }) => {
                return ascendDescend(a.dlt_start_date, b.dlt_start_date);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "DISPOSITION UD CODE",
            dataIndex: "disposition_ud_code",
            sorter: (a: { disposition_ud_code: string | number; }, b: { disposition_ud_code: string | number; }) => {
                return ascendDescend(a.disposition_ud_code, b.disposition_ud_code);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "REGULATORY LEVEL",
            dataIndex: "regulatory_level",
            sorter: (a: { regulatory_level: string | number; }, b: { regulatory_level: string | number; }) => {
                return ascendDescend(a.regulatory_level, b.regulatory_level);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "DLT EXCLUSION",
            dataIndex: "dlt_exclusion",
            sorter: (a: { dlt_exclusion: string | number; }, b: { dlt_exclusion: string | number; }) => {
                return ascendDescend(a.dlt_exclusion, b.dlt_exclusion);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "EXCLUSION REASON",
            dataIndex: "exclusion_reason",
            sorter: (a: { exclusion_reason: string | number; }, b: { exclusion_reason: string | number; }) => {
                return ascendDescend(a.exclusion_reason, b.exclusion_reason);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        }
    ],
    batchefordispositionTier1: [
        {
            title: "BATCH NUMBER",
            dataIndex: "batch_number",
            sorter: (a: { batch_number: string | number; }, b: { batch_number: string | number; }) => {
                return ascendDescend(a.batch_number, b.batch_number);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "ON TIME/LAST",
            dataIndex: "on_time_last",
            sorter: (a: { on_time_last: string | number; }, b: { on_time_last: string | number; }) => {
                return ascendDescend(a.on_time_last, b.on_time_last);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "DLT TIME/AGE",
            dataIndex: "dlt_time_age",
            sorter: (a: { dlt_time_age: string | number; }, b: { dlt_time_age: string | number; }) => {
                return ascendDescend(a.dlt_time_age, b.dlt_time_age);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "DLT START DATE",
            dataIndex: "dlt_start_date",
            sorter: (a: { dlt_start_date: string | number; }, b: { dlt_start_date: string | number; }) => {
                return ascendDescend(a.dlt_start_date, b.dlt_start_date);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "DISPOSITION UD CODE",
            dataIndex: "disposition_ud_code",
            sorter: (a: { disposition_ud_code: string | number; }, b: { disposition_ud_code: string | number; }) => {
                return ascendDescend(a.disposition_ud_code, b.disposition_ud_code);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "REGULATORY LEVEL",
            dataIndex: "regulatory_level",
            sorter: (a: { regulatory_level: string | number; }, b: { regulatory_level: string | number; }) => {
                return ascendDescend(a.regulatory_level, b.regulatory_level);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "DLT EXCLUSION",
            dataIndex: "dlt_exclusion",
            sorter: (a: { dlt_exclusion: string | number; }, b: { dlt_exclusion: string | number; }) => {
                return ascendDescend(a.dlt_exclusion, b.dlt_exclusion);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "EXCLUSION REASON",
            dataIndex: "exclusion_reason",
            sorter: (a: { exclusion_reason: string | number; }, b: { exclusion_reason: string | number; }) => {
                return ascendDescend(a.exclusion_reason, b.exclusion_reason);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        }
    ]
}
// inventory deep dive

export const cirticalMfcColums = {
    fdpUnderMfcTarget: [
        {
            title: "DATA DATE",
            dataIndex: "data_date",
            sorter: (a: any, b: any) => {
                return ascendDescend(a.data_date, b.data_date);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "MATERIAL GROUP",
            dataIndex: "material_group",
            sorter: (a: any, b: any) => {
                return ascendDescend(a.material_group, b.material_group);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "PART",
            dataIndex: "part",
            sorter: (a: any, b: any) => {
                return ascendDescend(a.part, b.part);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "DESCRIPTION",
            dataIndex: "description",
            sorter: (a: any, b: any) => {
                return ascendDescend(a.description, b.description);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "MFC DATE",
            dataIndex: "mfc_date",
            sorter: (a: any, b: any) => {
                return ascendDescend(a.mfc_date, b.mfc_date);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "STATUS",
            dataIndex: "status",
            sorter: (a: any, b: any) => {
                return ascendDescend(a.status, b.status);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "BACK TO TARGET DATE",
            dataIndex: "back_to_target_date",
            sorter: (a: any, b: any) => {
                return ascendDescend(a.back_to_target_date, b.back_to_target_date);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "REASON CODE",
            dataIndex: "reason_code",
            sorter: (a: any, b: any) => {
                return ascendDescend(a.reason_code, b.reason_code);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        },
        {
            title: "COMMENTS",
            dataIndex: "comments",
            sorter: (a: any, b: any) => {
                return ascendDescend(a.comments, b.comments);
            },
            render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
        }
    ]
}
