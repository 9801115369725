import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { APIResponseDataType, DataType, FetchDataParams } from 'types/wctTypes'
import { filterParams, getOktaToken } from 'utils/common-methods'
import { PM_ADHERENCE_DEEP_DIVE_URL } from 'utils/constants'

interface PMAdherenceDeepDiveStateProps {
    pmAdherenceDeepDiveInfo: APIResponseDataType[] | null;
    loading: boolean;
    error: boolean;
}

const initialState: PMAdherenceDeepDiveStateProps = {
    pmAdherenceDeepDiveInfo: null,
    loading: false,
    error: false
}

const fetchPmAdherenceDeepDive = createAsyncThunk<APIResponseDataType[], FetchDataParams>(
    PM_ADHERENCE_DEEP_DIVE_URL,
    async (params) => {
        const filteredParams = filterParams(params)
        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}${PM_ADHERENCE_DEEP_DIVE_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })
        if (!response.ok) {
            throw new Error('Failed to fetch PM Adherence')
        }
        return await response.json()
    }
)

const pmAdherenceDeepDiveReducer = createSlice({
    name: 'pmAdherenceDeepDive',
    initialState,
    reducers: {
        clearPmAdherenceDeepDiveData(state) {
            state.pmAdherenceDeepDiveInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPmAdherenceDeepDive.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchPmAdherenceDeepDive.fulfilled, (state, action) => {
                state.loading = false;
                state.pmAdherenceDeepDiveInfo = action.payload
            })
            .addCase(fetchPmAdherenceDeepDive.rejected, (state) => {
                state.loading = false;
                state.error = true;
            })
    }
})

export { fetchPmAdherenceDeepDive }

export const { clearPmAdherenceDeepDiveData } = pmAdherenceDeepDiveReducer.actions

export default pmAdherenceDeepDiveReducer.reducer
