import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { APIResponseDataType, FetchDataParams } from 'types/wctTypes';
import { filterParams, getOktaToken } from 'utils/common-methods';
import { KANBAN_HEALTH_URL } from 'utils/constants'

interface KanbanHealthInfoState {
    kanbanHealthBVInfo: APIResponseDataType[] | null;
    loading: boolean;
    error: boolean;
}

const initialState: KanbanHealthInfoState = {
    kanbanHealthBVInfo: null,
    loading: false,
    error: false
}

const fetchKanBanHealthBV = createAsyncThunk<APIResponseDataType[], FetchDataParams>(
    KANBAN_HEALTH_URL,
    async (params) => {
        const filteredParams = filterParams(params)
        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}${KANBAN_HEALTH_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })
        if (!response.ok) {
            throw new Error('Failed to fetch Turn Over')
        }
        return await response.json()
    }
)

const kanbanHealthBVReducer = createSlice({
    name: 'kanbanHealthBV',
    initialState,
    reducers: {
        clearKanBanHealthBVData(state) {
            state.kanbanHealthBVInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchKanBanHealthBV.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchKanBanHealthBV.fulfilled, (state, action) => {
                state.loading = false;
                state.kanbanHealthBVInfo = action.payload;
            })
            .addCase(fetchKanBanHealthBV.rejected, (state) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export { fetchKanBanHealthBV };

export const { clearKanBanHealthBVData } = kanbanHealthBVReducer.actions

export default kanbanHealthBVReducer.reducer;