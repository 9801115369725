import React, { useEffect, useState, useMemo } from "react";
import ReactECharts from "echarts-for-react";
import { formatNumber } from "utils/common-methods";

interface Data {
  name?: string;
  value?: number;
}

interface HorizontalBarChartProps {
  BehaviorObservationData: Data[];
  chartType: string;
}

const HorizontalBarChart: React.FC<HorizontalBarChartProps> = ({
  BehaviorObservationData,
  chartType,
}) => {
  const chartOption = useMemo(() => {
    if (chartType === "calibration") {
      return {
        xAxis: {
          max: (value: any) => {
            const maxValue = value.max;
            return maxValue === 0 ? 2 : maxValue * 2
          },
          axisLabel: {
            fontSize: 10,
            fontFamily: "Inter",
            color: "#595959",
            fontWeight: 700,
            hideOverlap: true,
            formatter: (value: number) => formatNumber(value),
          },
        },
        grid: {
          left: "1%",
          right: '1%',
          containLabel: true,
        },
        yAxis: {
          type: 'category',
          data: BehaviorObservationData?.map((item) => item.name),
          axisLabel: {
            fontSize: 10,
            fontFamily: "Inter",
            color: "#595959",
            fontWeight: 700,
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
        },
        series: [
          {
            type: 'bar',
            data: BehaviorObservationData?.map((item) => item.value),
            // barWidth: "50%",
            show: false,
          },
        ],
      };
    }
    return {};
  }, [BehaviorObservationData, chartType]);

  return (
    (chartType === 'calibration' || chartType === 'Aging') ? (
      <div style={{ height: "100px", width: "100%" }}>
        {BehaviorObservationData?.length > 0 ? (
          <ReactECharts option={chartOption} style={{ height: "100%", width: "100%" }} />
        ) : (
          <p>No data available</p>
        )}
      </div>
    ) : <></>
  );
};

export default HorizontalBarChart;
