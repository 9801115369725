import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { APIResponseDataType, DataType, FetchDataParams } from 'types/wctTypes'
import { filterParams, getOktaToken } from 'utils/common-methods'
import { CAL_CLOSURE_ADHERENCE_DEEP_DIVE_URL } from 'utils/constants'

interface CalClosureAdherenceDeepDiveStateProps {
    CalClosureAdherenceDeepDiveInfo: APIResponseDataType[] | null;
    loading: boolean;
    error: boolean;
}

const initialState: CalClosureAdherenceDeepDiveStateProps = {
    CalClosureAdherenceDeepDiveInfo: null,
    loading: false,
    error: false
}

const fetchCalClosureAdherenceDeepDive = createAsyncThunk<APIResponseDataType[], FetchDataParams>(
    CAL_CLOSURE_ADHERENCE_DEEP_DIVE_URL,
    async (params) => {
        const filteredParams = filterParams(params)
        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}${CAL_CLOSURE_ADHERENCE_DEEP_DIVE_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })
        if (!response.ok) {
            throw new Error('Failed to fetch Cal Closure Adherence')
        }
        return await response.json()
    }
)

const CalClosureAdherenceDeepDiveReducer = createSlice({
    name: 'CalClosureAdherenceDeepDive',
    initialState,
    reducers: {
        clearCalClosureAdherenceDeepDiveData(state) {
            state.CalClosureAdherenceDeepDiveInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCalClosureAdherenceDeepDive.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchCalClosureAdherenceDeepDive.fulfilled, (state, action) => {
                state.loading = false;
                state.CalClosureAdherenceDeepDiveInfo = action.payload
            })
            .addCase(fetchCalClosureAdherenceDeepDive.rejected, (state) => {
                state.loading = false;
                state.error = true;
            })
    }
})

export { fetchCalClosureAdherenceDeepDive }

export const { clearCalClosureAdherenceDeepDiveData } = CalClosureAdherenceDeepDiveReducer.actions

export default CalClosureAdherenceDeepDiveReducer.reducer
