import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOktaToken } from "utils/common-methods";

interface FetchDataParams {
  site?: string | undefined;
  time?: string | undefined;
  sqdip?: any | [];
  line?: string | undefined;
  shift?: string | undefined;
  building?: string | undefined;
}

const fetchOneClickReport = createAsyncThunk<any, FetchDataParams>(
  "/work-center-team/wctReport",
  async (params: FetchDataParams) => {
    const { site, line, time, sqdip, shift, building } = params;
    const token: string = getOktaToken();
    const response = await fetch(
      `${process.env.REACT_APP_GENAI_API_URL}/work-center-team/wctReport?time=${time}&site=${site}&sqdip=${sqdip}&line=${line}&shift=${shift}&building=${building}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch one click report data");
    }
    return await response.json();
  }
);

const oneClickReportReducer = createSlice({
  name: "oneClickReport",
  initialState: {
    oneClickReportInfo: { data: [] as any, summary: "" },
    isOneClickLoading: false,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOneClickReport.pending, (state) => {
      state.isOneClickLoading = true;
      state.error = false;
    });
    builder.addCase(fetchOneClickReport.fulfilled, (state, action) => {
      state.oneClickReportInfo = action.payload;
      state.isOneClickLoading = false;
      state.error = false;
    });
    builder.addCase(fetchOneClickReport.rejected, (state) => {
      state.isOneClickLoading = false;
      state.error = true;
    });
  },
});

export { fetchOneClickReport };
export default oneClickReportReducer.reducer;
