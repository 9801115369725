import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { BaseViewCardPropsType, CardPropsType, FetchDataParams } from 'types/wctTypes'
import { filterParams, getMonthName, getOktaToken, setData } from 'utils/common-methods'
import { QUALITY_CHANGE_CONTROL_URL } from 'utils/constants'
import { StringKeyDataProps } from 'utils/data-types'

interface ChangeControlGraphDataType {
    date: string
    compliance_rate: number | string
}

interface ChangeControlType {
    data: StringKeyDataProps
    graph_data?: ChangeControlGraphDataType[]
    graph_params?: StringKeyDataProps
}

interface ChangeControlInfoStateType {
    qualityChangeControlInfo: BaseViewCardPropsType | null
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    error: boolean
}

const initialState: ChangeControlInfoStateType = {
    qualityChangeControlInfo: null,
    status: 'idle',
    error: false,
}

const fetchQualityChangeControl = createAsyncThunk<ChangeControlType, FetchDataParams>(
    QUALITY_CHANGE_CONTROL_URL,
    async (params) => {
        const filteredParams = filterParams(params)

        const token: string = getOktaToken()
        const response = await fetch(`${process.env.REACT_APP_API_URL}${QUALITY_CHANGE_CONTROL_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })

        if (!response.ok) {
            throw new Error('Failed to fetch capa')
        }
        return await response.json()
    }
)

const transformData = (changeControl: ChangeControlType): BaseViewCardPropsType => {
    const changeControlData: BaseViewCardPropsType = {
        cardData: {},
        graphData: [],
        graphParams: {},
        cardProps: {
            alertType: 'success'
        }
    }

    const { data: { overdue } } = changeControl
// code commentde don't remove to further uses

    // if (changeControl?.data && overdue && overdue as number > 0) {
    //     changeControlData.cardProps.alertType = 'error'
    // }

    changeControlData.cardData = setData(changeControl.data)
    changeControlData.graphData = changeControl?.graph_data?.map((item: ChangeControlGraphDataType) => {
        const [year, month] = item.date.split("-").map(val => parseInt(val))
        const monthName = getMonthName(month)

        return {
            name: monthName,
            value: item.compliance_rate as number
        }
    }) ?? []

    changeControlData.graphParams = changeControl.graph_params ?? {}

    return changeControlData
}

const qualityChangeControlSlice = createSlice({
    name: 'qualityChangeControl',
    initialState,
    reducers: {
        clearQualityChangeControlData(state) {
            state.qualityChangeControlInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchQualityChangeControl.pending, (state) => {
                state.status = 'loading'
                state.error = false
            })
            .addCase(fetchQualityChangeControl.fulfilled, (state, action: PayloadAction<ChangeControlType>) => {
                state.status = 'succeeded'
                state.qualityChangeControlInfo = transformData(action.payload)
            })
            .addCase(fetchQualityChangeControl.rejected, (state) => {
                state.status = 'failed'
                state.error = true
            })
    }
})

export { fetchQualityChangeControl }

export const { clearQualityChangeControlData } = qualityChangeControlSlice.actions

export default qualityChangeControlSlice.reducer