// import "./Inventory.scss";
import { cirticalMfcColums } from "utils/wctConstants";
import RenderTierWiseComponent from "../delivery-tierwise/RenderTierWiseComponent";

interface InventoryTier2Props {
  data: any;
  tierLabel: string;
  activeKeyDeepDiveTab: string;
}

const InventoryTier2: React.FC<InventoryTier2Props> = ({
  data,
  tierLabel,
  activeKeyDeepDiveTab,
}) => {
  return (<div className="delivery-tier-main">
    {data?.map((
        item: any, index: number | null | undefined) => {
        return <RenderTierWiseComponent activeKeyDeepDiveTab={activeKeyDeepDiveTab} tierLabel={tierLabel} key={index} data={item} deepDiveTableColumns={cirticalMfcColums} />
    })}
</div>)
};
export default InventoryTier2;
