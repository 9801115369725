import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ExternalLinkIcon } from '@gitlab-rtsensing/component-library';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import './index.scss';

interface SectionLinkProps {
  className?: string;
  children: React.ReactNode;
  componentKey?: string;
  url: string;
  disabled?: boolean;
  type: 'internal' | 'external' | 'internalSection';
}

interface CustomEventDetail {
  tabKey: string;
}

const handleSideNavClick = (tabKey: string) => {
  const event = new CustomEvent<CustomEventDetail>('sideNavClick', { detail: { tabKey } })
  document.dispatchEvent(event)
}

const SectionLink = (props: SectionLinkProps) => {
  const { className, disabled = false, children, url, type, componentKey } = props;
  const navigate = useNavigate();
  const joinedClass = `nav-sub-section-link nav-sub-section-link${disabled ? '--disabled' : '--enabled'
    } ${className ?? ''}`;

  return (
    <>
      {type === 'external' && (
        <a className={joinedClass} href={url} target="_blank" rel="noreferrer">
          {children}
          <ExternalLinkIcon
            className="external-link-icon"
            width={14}
            height={14}
          />
        </a>
      )}

      {type === 'internal' && componentKey && (
        <Link className={joinedClass} to={url} onClick={e => handleSideNavClick(componentKey ?? "")}>
          {children}
        </Link>
      )}

      {type === 'internal' && !componentKey && (
        <Link className={joinedClass} to={url} >
          {children}
        </Link>
      )}

      {type === 'internalSection' && (
        // <AnchorLink
        //   offset={() => 70}
        //   className={joinedClass}
        //   href={url}
        //   rel="noreferrer"
        // >
        { children }
        // </AnchorLink>
      )}
    </>
  );
};

export default SectionLink;
