// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BlockRadioFilters_customBlockFilter__zwb9M .ant-radio-button-wrapper {
    font-size: 12px;
    height: 28px;
    line-height: 25px;
}`, "",{"version":3,"sources":["webpack://./src/components/resuable/radio-filters/BlockRadioFilters.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".customBlockFilter :global(.ant-radio-button-wrapper) {\n    font-size: 12px;\n    height: 28px;\n    line-height: 25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customBlockFilter": `BlockRadioFilters_customBlockFilter__zwb9M`
};
export default ___CSS_LOADER_EXPORT___;
