import SummaryCard from "components/common/summaryCard/summaryCard";
import "./Delivery.scss";
import { filterbyDataType, findbyFilterType } from "utils/common-methods";
import { APIResponseDataType, PMAdherenceTableType, StackedBarChartType, StackedLineChartType, SummaryType, TableType } from "types/wctTypes";
import { BlockRadioFilters } from "components/resuable/radio-filters/BlockRadioFilters";
import { batchDispositionDeepDiveFilter, BatchDispositionBRRColumns, DataTypeKey, DEEP_DIVE_UI_CONSTANTS, DeliveryAgingTableColumnsType, pmAdherenceColumns, calClosureAdherenceColumns, pmAdherenceDeepDiveFilter, batchDispositionDeepDiveTableColumns } from "utils/wctConstants";
import { Key, useEffect, useState } from "react";
import AohTable from "components/common/table/AohTable";
import { StringKeyDataProps } from "utils/data-types";
import StackedBarChart from "components/common/StackedBar/StackedBar";
import RenderTierWiseComponent from "./RenderTierWiseComponent";
import { formatMTDDate, newMinMaxValue } from "components/common/StackedBar/utils";
import { fetchBatchDispositionFilterData } from "reducers/delivery-deep-dive/batchDispositionDeepDiveReducer";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";

interface DeliveryTier2Props {
    data: any;
    tierLabel: string;
    activeKeyDeepDiveTab: string;
}

const DeliveryTier2: React.FC<DeliveryTier2Props> = ({
    data,
    tierLabel,
    activeKeyDeepDiveTab,
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const [tableType, setTableType] = useState<string>("mfg")
    const [filter, setFilter] = useState<any>({})

    const [tableData, setTableData] = useState<StringKeyDataProps[]>()

    const summary: APIResponseDataType = filterbyDataType<SummaryType>(data, DataTypeKey.IsSummary)[0]
    const allStackedBarCharts: APIResponseDataType[] = filterbyDataType<StackedBarChartType>(data, DataTypeKey.IsStackedBarChart)
    const allStackedLineCharts: APIResponseDataType[] = filterbyDataType<StackedLineChartType>(data, DataTypeKey.IsStackedLineChart)
    const allTables: APIResponseDataType[] = filterbyDataType<TableType>(data, DataTypeKey.IsTable)

    const agingWoStackedBarChart: any = allStackedBarCharts[0]
    const agingWoStackedLineChart: any = allStackedLineCharts[0]
    const agingWoTable: any = allTables[0]

    useEffect(() => {
        const result = findbyFilterType<APIResponseDataType>(allTables, tableType)

        if (result) {
            setTableData(Array.isArray(result.data) ? result.data : [result.data]);
        } else {
            setTableData(Array.isArray(allTables?.[0]?.data) ? allTables[0].data : [allTables?.[0]?.data ?? []]);
        }
    }, [activeKeyDeepDiveTab, tableType])

    const handleRadioFilter = (filterType: any, nextValues: any) => {
        setFilter((prev: any) => ({ ...prev, [filterType]: nextValues }))
        const params = {
            workStream: "manufacturing",
            site: "AOH",
            persona: "wct",
            tierNumber: "tier 2",
            filterType,
            filterValue: nextValues

        }
        dispatch(fetchBatchDispositionFilterData(params as any));
    }

    const deliveryDeepDive: any = {
        "% PM Adherence & Upcoming PMs": {
            summaryTitle: DEEP_DIVE_UI_CONSTANTS.SUMMARY,
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary?.data}
                />
            ),
            title: "Upcoming PM WO",
            blockRadioFilters: (
                <BlockRadioFilters
                    filterLabel={DEEP_DIVE_UI_CONSTANTS.VIEW_BY}
                    radioFilter={pmAdherenceDeepDiveFilter}
                    setRadioFilter={setTableType}
                />
            ),
            table: <AohTable
                tableData={tableData}
                tableColumns={pmAdherenceColumns} />
        },
        "Aging Work Orders": {
            title: agingWoStackedBarChart?.dataParams?.title,
            stackBar: (
                <StackedBarChart
                    data={agingWoStackedBarChart?.data}
                    chartType="aging-work-orders"
                    xAxisData={agingWoStackedBarChart?.data?.map((item: any) => formatMTDDate(item))}
                    yAxisNames={[
                        { name: "COUNT OF OPEN AND CLOSED RECORDS", position: "left", max: newMinMaxValue(agingWoStackedBarChart?.data)?.Max },
                    ]}
                />
            ),
            title2: agingWoStackedLineChart?.dataParams?.title,
            subtitle: agingWoStackedLineChart?.dataParams?.subtitle,
            stackBar2: (
                <StackedBarChart
                    data={agingWoStackedLineChart?.data}
                    chartType="wo-closure-adherence"
                    xAxisData={agingWoStackedLineChart?.data?.map((item: any) => formatMTDDate(item))}
                    yAxisNames={[
                        { name: "", position: "left", max: newMinMaxValue(agingWoStackedLineChart?.data)?.Max },
                    ]}
                />
            ),
            title3: agingWoTable?.dataParams?.title,
            subtitle3: agingWoTable?.dataParams?.subtitle,
            table3: <AohTable tableData={agingWoTable?.data} tableColumns={DeliveryAgingTableColumnsType} />,
        },
        "Calibration Closure Adherence": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary?.data}
                />
            ),
            title: "Open Calibration WO",
            table: <AohTable
                tableData={tableData}
                tableColumns={calClosureAdherenceColumns} />
        }
    }

    if (activeKeyDeepDiveTab === "Batch Disposition") {
        return (<div className="delivery-tier-main">
            {data?.map((
                item: any, index: Key | null | undefined) => {
                return <RenderTierWiseComponent setTableType={handleRadioFilter} activeKeyDeepDiveTab={activeKeyDeepDiveTab} tierLabel={tierLabel} key={index} data={item} activeFilter={filter} deepDiveFilters={batchDispositionDeepDiveFilter} deepDiveTableColumns={batchDispositionDeepDiveTableColumns} />
            })}
        </div>)
    } else {
        return (
            <div className="delivery-tier-main">
                {deliveryDeepDive[activeKeyDeepDiveTab]?.summaryTitle && (
                    <div className="tier-container">
                        <h2 className="tier-title">
                            {/* Summary card */}
                            {deliveryDeepDive[activeKeyDeepDiveTab]?.summaryTitle}
                        </h2>
                    </div>
                )}
                {deliveryDeepDive[activeKeyDeepDiveTab]?.summary}
                <div
                    className={
                        deliveryDeepDive[activeKeyDeepDiveTab]?.summary ? "mr-bottom-20" : ""
                    }
                ></div>
                {deliveryDeepDive[activeKeyDeepDiveTab]?.title && (
                    <div className="tier-container">
                        <h2 className="tier-title">
                            {deliveryDeepDive[activeKeyDeepDiveTab]?.title}
                        </h2>
                    </div>
                )}
                <div className="tier-subtitle">
                    {deliveryDeepDive[activeKeyDeepDiveTab]?.subtitle}
                </div>
                {deliveryDeepDive[activeKeyDeepDiveTab]?.blockRadioFilters}
                {deliveryDeepDive[activeKeyDeepDiveTab]?.table}
                {deliveryDeepDive[activeKeyDeepDiveTab]?.stackBar}
                {deliveryDeepDive[activeKeyDeepDiveTab]?.title2 && (
                    <div className="tier-container">
                        <h2 className="tier-title">
                            {deliveryDeepDive[activeKeyDeepDiveTab]?.title2}
                        </h2>
                    </div>
                )}
                <div className="tier-subtitle">
                    {deliveryDeepDive[activeKeyDeepDiveTab]?.subtitle}
                </div>
                {deliveryDeepDive[activeKeyDeepDiveTab]?.stackBar2}
                {deliveryDeepDive[activeKeyDeepDiveTab]?.title3 && (
                    <div className="tier-container">
                        <h2 className="tier-title">
                            {deliveryDeepDive[activeKeyDeepDiveTab]?.title3}
                        </h2>
                    </div>
                )}
                <div className="tier-subtitle">
                    {deliveryDeepDive[activeKeyDeepDiveTab]?.subtitle3}
                </div>
                {deliveryDeepDive[activeKeyDeepDiveTab]?.table3}
            </div>

        )
    }

}
export default DeliveryTier2
