import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { FetchDataParams } from 'types/wctTypes'
import { filterParams, getMonthName, getOktaToken, setCapsLabel, setData } from 'utils/common-methods'
import { CRITICAL_MFC_URL } from 'utils/constants'
import { StringKeyDataProps } from 'utils/data-types'

interface CriticalMfcType {
    tooltipContent: string | number
    highlightedParams: string[]
    isHorizontalChart: boolean
    isTrendGraph: boolean
    data: any
    isCard?: Boolean
}

interface CriticalMfcDataType {
    cardData: StringKeyDataProps
    cardProps: CardProps
    graphParams?:any
}

interface CardProps {
    highlightedParams?: string[]
    alertType: "error" | "success"
}
interface CriticalMfcInfoStateType {
    criticalMfcInfo: CriticalMfcDataType | null
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    error: boolean
}

const initialState: CriticalMfcInfoStateType = {
    criticalMfcInfo: null,
    status: 'idle',
    error: false,
}

const fetchCriticalMfc = createAsyncThunk<CriticalMfcType, FetchDataParams>(
    CRITICAL_MFC_URL,
    async (params) => {
        const filteredParams = filterParams(params)
        const token: string = getOktaToken();

        const response = await fetch(
            `${process.env.REACT_APP_API_URL}${CRITICAL_MFC_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        }
        );

        if (!response.ok) {
            throw new Error('Failed to fetch batchDispoition');
        }
        return await response.json();
    }
)

const transformData = (criticalMfc: CriticalMfcType[]): CriticalMfcDataType => {
    const criticalMfcInfo: CriticalMfcDataType = {
        cardData: {},
        cardProps: {
            alertType: 'success',
        },
        graphParams: {}
    }

    for (let i = 0; i < criticalMfc.length; i++) {
        const item = criticalMfc[i];
        if (item.isCard) {
            criticalMfcInfo.cardData = setData(item.data)
            if (item.highlightedParams) { criticalMfcInfo.cardProps.highlightedParams = item.highlightedParams }
        }
    }

    return criticalMfcInfo
}

const CriticalMfcSlice = createSlice({
    name: 'deliveryCriticalMfc',
    initialState,
    reducers: {
        clearCriticalMfcData(state) {
            state.criticalMfcInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCriticalMfc.pending, (state) => {
                state.status = 'loading'
                state.error = false
            })
            .addCase(fetchCriticalMfc.fulfilled, (state, action: PayloadAction<CriticalMfcType>) => {
                state.status = 'succeeded'
                state.criticalMfcInfo = transformData(action.payload as any)
            })
            .addCase(fetchCriticalMfc.rejected, (state) => {
                state.status = 'failed'
                state.error = true
            })
    }
})

export { fetchCriticalMfc }

export const { clearCriticalMfcData } = CriticalMfcSlice.actions

export default CriticalMfcSlice.reducer