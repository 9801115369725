import { COLORS } from "./constants";

export const dataZoomConfig = [
    {
        type: "inside",
        zoomLock: true,
        borderWidth: 0, // Border width of the slider track area
        borderColor: "#F0F0F0",
    },
    {
        type: "slider",
        show: false,
        showDetail: false,
        backgroundColor: "#f0f0f0",
        fillerColor: "#217ecf",
        borderColor: "transparent", // Border color of the slider track area
        borderRadius: 12,
        height: 20,
        handleStyle: {
            color: "#175891",
            borderRadius: 5,
            borderColor: "transparent",
            shadowColor: "transparent",
        },
        dataBackground: {
            lineStyle: {
                opacity: 0,
            },
            areaStyle: {
                opacity: 0,
            },
        },
        brushSelect: false,
        brushStyle: {
            // opacity: 0,
        },
    },
];

export const tooltipConfig = {
    trigger: "axis",
    axisPointer: {
        type: "shadow",
    },
};

export const nameTextStyleConfig = {
    fontSize: 10,
    fontWeight: 700,
    fontFamily: "Inter",
    lineHeight: 14,
    color: "#595959",
    align: "center",
    verticalAlign: "middle",
    rotate: 90,
};

export const layoutConfig = {
    left: "4%",
    right: "4%",
    bottom: "18%",
    top: "6%",
    containLabel: true,
};

export const customConfig = {
    left: "54px",
    right: "4%",
    bottom: "18%",
    top: "6%",
    containLabel: true,
}

export const barSeriesConfig = {
    barWidth: 16,
    type: "bar",
    stack: "total",
};
export const verticalBarBaseViewConfig = {
    barWidth: 25,
    type: "bar",
    stack: "total",
    showBackground: true,
};

export const lineSeriesConfig = {
    itemStyle: { color: COLORS.safeBehavior },
    type: "line",
    lineStyle: {
        type: 'dotted',
    },
};

export const dashedLineSeriesConfig = {
    itemStyle: { color: COLORS.dashedLine },
    type: "line",
    lineStyle: {
        type: 'dashed',
    },
};

export const axisLabelConfig = {
    align: "right",
    margin: 10,
    formatter: (value: any) => `${Math.round(value)}%`,
    fontSize: 10,
    fontWeight: 700,
    fontFamily: "Inter",
    lineHeight: 14
};

export const axisLabelSimpleConfig = {
    align: "right",
    margin: 10,
    fontSize: 10,
    fontWeight: 700,
    fontFamily: "Inter",
    lineHeight: 14
};

export const leftAxisConfig = {
    fontSize: 10,
    fontWeight: 700,
    fontFamily: "Inter",
    lineHeight: 14
}

export const layoutConfigNoLeftSpace = {
    left: "1%", // Adjust this value to reduce the left space
    right: "4%",
    bottom: "18%",
    top: "5%",
    containLabel: true,
};