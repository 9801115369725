
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BaseViewCardPropsType, FetchDataParams, QueryParamsPropsType } from 'types/wctTypes';
import { filterParams, getOktaToken } from 'utils/common-methods'
import { INCA_URL } from 'utils/constants';
import { StringKeyDataProps } from 'utils/data-types';

interface IncaDataItem {
    overdue?: number | string,
    incident?: number | string,
    incident_last_24_hours?: number | string,
    coming_due?: number | string
}

interface IncaGraphItem {
    date?: string,
    number_of_inca_incident?: number | string
}

interface IncaDataType {
    card_data: IncaDataItem;
    graph_data: IncaGraphItem[];
    query_params: QueryParamsPropsType
}

interface IncaInfoState {
    incaInfo: BaseViewCardPropsType | null;
    loading: boolean;
    error: boolean;
}

const initialState: IncaInfoState = {
    incaInfo: null,
    loading: false,
    error: false,
};

const fetchInca = createAsyncThunk<IncaDataType, FetchDataParams>(
    INCA_URL,
    async (params) => {
        const filteredParams = filterParams(params)
        const token: string = getOktaToken();

        const response = await fetch(`${process.env.REACT_APP_API_URL}${INCA_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch inca');
        }
        return await response.json();
    }
)

const convertData = (data: IncaGraphItem[]): StringKeyDataProps[] => {
    return data.map((item) => {
      const date = new Date(item?.date ?? "");
      const options: Intl.DateTimeFormatOptions = {
        month: "short",
        day: "2-digit",
      };
      const name = date
        .toLocaleDateString("en-US", options)
        .replace(/\s/g, " ")
        .toUpperCase();
      return { name, value: item.number_of_inca_incident ?? 0 };
    });
  }

const transformData = (incaData: IncaDataType): BaseViewCardPropsType => {
    const incaBaseViewData: BaseViewCardPropsType = {
        cardData: {},
        graphData: [],
        graphParams: {},
        cardProps: {
            alertType: 'success'
        }
    }

    const tierLabel = incaData.query_params?.tierLabel

    const incaSanitizedData: StringKeyDataProps = {};
    const incaOverDue = incaData.card_data?.overdue;
    const incaIncident = incaData.card_data?.incident;
    const incaLast24Hours = incaData.card_data?.incident_last_24_hours;
    const incaComingDue = incaData.card_data?.coming_due;

    if (
        tierLabel?.toLowerCase() !== "tier 0" &&
        incaData?.graph_data?.length
    ) {
        const inputData1: IncaGraphItem[] = incaData?.graph_data;
        const outputData: StringKeyDataProps[] = convertData(inputData1);
        incaBaseViewData.graphData = outputData
        incaBaseViewData.graphParams.isGraph = true
    } else {
        incaBaseViewData.graphData = []
        incaBaseViewData.graphParams.isGraph = false
    }

    if (typeof incaOverDue === "number" && incaOverDue >= 0) {
        incaSanitizedData.OVERDUE = incaOverDue;
    }
    if (typeof incaIncident === "number" && incaIncident >= 0) {
        incaSanitizedData.INCIDENT = incaIncident;
    }

    if (typeof incaLast24Hours === "number" && incaLast24Hours >= 0) {
        incaSanitizedData["INCIDENT LAST 24 HOURS"] = incaLast24Hours;
    }

    if (typeof incaComingDue === "number" && incaComingDue >= 0) {
        incaSanitizedData["COMING DUE"] = incaComingDue;
    }

    incaBaseViewData.cardData = incaSanitizedData

    if (
        tierLabel?.toLowerCase() === "tier 1" ||
        tierLabel?.toLowerCase() === "tier 2"
    ) {
        incaBaseViewData.graphParams.tooltipContent = "Number of INCA Created in last 5 days"
    } else if (tierLabel?.toLowerCase() === "tier 3") {
        incaBaseViewData.graphParams.tooltipContent = "Number of INCA Created in last 6 months"
    }

    // if (
    //     tierLabel?.toLowerCase() !== "tier 0" &&
    //     typeof incaOverDue === "number" &&
    //     incaOverDue > 0
    // ) {
    //     incaBaseViewData.cardProps.alertType = "error"
    // } else {
    //     incaBaseViewData.cardProps.alertType = "success"
    // }

    return incaBaseViewData
}

const incaReducer = createSlice({
    name: 'inca',
    initialState,
    reducers: {
        clearIncaData(state) {
            state.incaInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchInca.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchInca.fulfilled, (state, action) => {
                state.loading = false;
                state.incaInfo = transformData(action.payload);
            })
            .addCase(fetchInca.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });

    }
});

export { fetchInca };

export const { clearIncaData } = incaReducer.actions

export default incaReducer.reducer;