import AohTable from "../../common/table/AohTable";
import AohTableWithLabels from "../../common/table/AohTableWithLabels";
import AohDropdown from "components/common/dropdown/aohdropdown";
import { Button } from "@gitlab-rtsensing/component-library";
import SummaryCard from "../../common/summaryCard/summaryCard";
import "./Safety.scss";
import { useState } from "react";
import { columns, ehssColumns, incaColumns, incaGlobalEhssColumns, seecardColumns, workOrderColumns } from "utils/constants";
import { DownloadOutlined } from "@ant-design/icons";
import { MenuProps } from "antd";
import { downloadCSV, generateCSV } from "utils/common-methods";

interface SafetyTierProps {
  data: any;
  tierLabel: string;
  activeKeyDeepDiveTab: string;
}

interface HeaderDropDownType {
  key: string;
  label: string;
}

const SafetyTier: React.FC<SafetyTierProps> = ({
  data,
  tierLabel,
  activeKeyDeepDiveTab,
}) => {
  const [filteredData, setFilteredData] = useState(data?.inca?.inca_open_incidents);
  const [currentStatus, setCurrentStatus] = useState<HeaderDropDownType>({
    key: "All",
    label: "All",
  });

  const statusDropDownData: HeaderDropDownType[] = [
    { key: "All", label: "All" }, 
    ...Array.from(new Set(data?.inca?.inca_open_incidents?.map((item: { status: string }) => item.status))) // Get unique status values
      .filter((status): status is string => status !== undefined && status !== null) // Filter out undefined or null values
      .map((uniqueStatus: string) => ({
          key: uniqueStatus,
          label: uniqueStatus,
      })),
  ];

  const handleMenuClick: MenuProps["onClick"] = (selectedStatus: any) => {
    setCurrentStatus({key: selectedStatus.key, label: selectedStatus.key});
    
    const newFilteredData =
      selectedStatus.key === "All"
        ? data?.inca?.inca_open_incidents
        : data?.inca?.inca_open_incidents.filter(
            (item: { status: string }) => item.status === selectedStatus.key
          );
    
    setFilteredData(newFilteredData);
  };
  
  const safetyDeepDive: any = {
    "SEE Cards": {
      title: "New SEE Cards",
      subtitle: "In Last 24 Hours",
      button: (
        <Button
          label="Download"
          className="download-btn"
          icon={<DownloadOutlined />}
          iconPosition="right"
          onClick={() => {
            if (data?.seeCards?.data && seecardColumns) {
              const csvContent = generateCSV(data.seeCards.data, seecardColumns);
              downloadCSV(csvContent, "SEE_Cards_Data.csv");
            } else {
              console.error("No data or columns available for download");
            }
          }}
          type="secondary"
        />
      ),
      table: <AohTable tableData={data?.seeCards?.data} tableColumns={seecardColumns} />,
    },
    "INCA Incidents": {
      title: "Open Incident List",
      title2: "Global EHSS Awareness",
      subtitle2: "In Last 5 Days",
      dropdown: (
        <div className="tier-dropdown">
          <AohDropdown
            items={statusDropDownData}
            title="STATUS"
            dropOptions={currentStatus?.label}
            handleMenuClick={handleMenuClick}
          />
        </div>
      ),
      table: (
        <AohTableWithLabels
          tableData={filteredData ?? data?.inca?.inca_open_incidents}
          tableColumns={incaColumns}
        />
      ),
      table2: <AohTable tableData={data?.inca?.inca_global_ehss} tableColumns={incaGlobalEhssColumns}/>,
    },
    "Safety Critical Work Orders": {
      title: "Summary",
      title2: "Open Safety Critical Work Orders",
      summary: (
        <SummaryCard
          data={data?.summary}
          tierLabel={tierLabel}
          activeKeyDeepDiveTab={activeKeyDeepDiveTab}
        />
      ),
      table2: (
        <AohTableWithLabels
          tableData={data?.workOrderDeepDive}
          tableColumns={workOrderColumns}
        />
      ),
    },
    "EHSS Training Compliance": {
      title: "Upcoming Due Tasks",
      subtitle: "Within 5 Days",
      table: (
        <AohTable tableData={data?.ehss?.data} tableColumns={ehssColumns} />
      ),
    },
  };

  return (
    <div className="safety-tier-main">
      <div className="tier-container">
        <h2 className="tier-title">
          {safetyDeepDive[activeKeyDeepDiveTab]?.title}
        </h2>
        {safetyDeepDive[activeKeyDeepDiveTab]?.button}
      </div>
      {safetyDeepDive[activeKeyDeepDiveTab]?.dropdown}
      <div className="tier-subtitle">
        {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle}
      </div>
      {safetyDeepDive[activeKeyDeepDiveTab]?.summary}
      {safetyDeepDive[activeKeyDeepDiveTab]?.table}

      <div className="tier-container">
        <h2 className="tier-title">
          {safetyDeepDive[activeKeyDeepDiveTab]?.title2}
        </h2>
      </div>
      <div className="tier-subtitle">
        {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle2}
      </div>
      {safetyDeepDive[activeKeyDeepDiveTab]?.table2}
    </div>
  );
};
export default SafetyTier;
