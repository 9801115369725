import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "store";
import {
  ConfigProvider,
  Select,
  Timeline,
  Table,
  Empty,
  Popover,
  Checkbox,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Loader } from "@gitlab-rtsensing/component-library";
import { fetchOneClickReport } from "reducers/oneClickReportReducer";
import {
  sqdipOptions,
  timeOptions,
  lineOptions,
  shiftOptions,
  buildingOptions,
} from "./constants";
import "./index.scss";
import AIGeneratedIcon from "assets/icons/AIGeneratedIcon";

function OneClickReport() {
  const dispatch = useDispatch<AppDispatch>();
  const { oneClickReportInfo, isOneClickLoading } = useSelector(
    (state: RootState) => state.oneClickReport
  );
  const [selectedSqdip, setSelectedSqdip] = useState(
    sqdipOptions?.map((option) => option.value)
  );
  const [selectedTime, setSelectedTime] = useState("last_month");
  const [selectedLine, setSelectedLine] = useState("all");
  const [selectedShift, setSelectedShift] = useState("all");
  const [selectedBuilding, setSelectedBuilding] = useState("all");
  const [isAllCheckboxChecked, setIsAllCheckboxChecked] = useState(true);
  const [allSelectedValue, setAllselectedValue] = useState(["All"]);
  const [payloadObject, setPayloadObject] = useState<any>({
    site: "aoh",
    time: selectedTime,
    sqdip: isAllCheckboxChecked ? ["all"] : selectedSqdip,
    line: selectedLine,
    shift: selectedShift,
    building: selectedBuilding,
  });

  useEffect(() => {
    dispatch(fetchOneClickReport(payloadObject));
  }, [payloadObject, JSON.stringify(payloadObject)]);

  const singleSelectDropdown = (
    label: string,
    options: any,
    selectedValue: string,
    setSelectedValue: any
  ) => {
    return (
      <div className="d-flex align-items-center">
        <span
          style={{
            marginRight: "10px",
            fontSize: "10px",
            color: "#595959",
            fontWeight: 700,
          }}
        >{`${label}:`}</span>
        <ConfigProvider
          theme={{
            components: {
              Select: {
                fontSize: 12,
              },
            },
          }}
        >
          <Select
            style={{ width: "210px" }}
            options={options}
            onChange={(value) => setSelectedValue(value)}
            value={selectedValue}
          />
        </ConfigProvider>
      </div>
    );
  };

  const handleSelectAll = (event: any) => {
    if (event.target.checked) {
      setSelectedSqdip(sqdipOptions.map((option) => option.value));
      setAllselectedValue(["All"]);
    } else {
      setSelectedSqdip([]);
      setAllselectedValue([]);
    }
    setIsAllCheckboxChecked(event.target.checked);
  };

  const handleCheckboxChange = (event: any) => {
    const value = event.target ? event.target.value : "";
    let updateSelectedOption = selectedSqdip || [];
    if (!event.target.checked) {
      updateSelectedOption = updateSelectedOption.filter(
        (val) => val !== value
      );
      setSelectedSqdip((prevselectedSqdip) =>
        prevselectedSqdip.filter((val) => val !== value)
      );
    } else {
      updateSelectedOption = [...updateSelectedOption, value];
      setSelectedSqdip((prevselectedSqdip) => [
        ...(prevselectedSqdip || []),
        value,
      ]);
    }
    setIsAllCheckboxChecked(false);
    setAllselectedValue(updateSelectedOption);
  };

  const dropdownRender = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Checkbox
          checked={isAllCheckboxChecked}
          onChange={(e) => handleSelectAll(e)}
        >
          <span style={{ fontSize: "13px" }}>All</span>
        </Checkbox>
        {sqdipOptions.map((option: any) => (
          <Checkbox
            key={option.value}
            value={option.value}
            checked={selectedSqdip.includes(option.value)}
            onChange={(e) => handleCheckboxChange(e)}
          >
            <span style={{ fontSize: "13px" }}>{option.label}</span>
          </Checkbox>
        ))}
      </div>
    );
  };

  const multiSelectDropdown = (label: string) => {
    return (
      <div className="d-flex align-items-center">
        <span
          style={{
            marginRight: "10px",
            fontSize: "10px",
            color: "#595959",
            fontWeight: 700,
          }}
        >{`${label}:`}</span>
        <ConfigProvider
          theme={{
            components: {
              Select: {
                fontSize: 12,
              },
            },
          }}
        >
          <Select
            mode="tags"
            dropdownRender={dropdownRender}
            value={isAllCheckboxChecked ? allSelectedValue : selectedSqdip}
            showSearch
            maxTagCount={1}
            style={{ width: "210px" }}
            onDeselect={(value) => {
              if (isAllCheckboxChecked) {
                handleSelectAll({ target: { checked: false } });
              } else {
                setSelectedSqdip(selectedSqdip.filter((val) => val !== value));
              }
            }}
          ></Select>
        </ConfigProvider>
      </div>
    );
  };

  const timeLineItems = oneClickReportInfo?.data?.map((item: any) => {
    return {
      label: (
        <div style={{ fontWeight: 700 }}>
          <div style={{ color: "#595959" }}>{item?.kpi}</div>
          <div style={{ color: "#8c8c8c" }}>{item?.metric}</div>
        </div>
      ),
      children: (
        <Table
          bordered
          scroll={{ x: "max-content" }}
          columns={item?.columns?.map((column: any) => ({
            title: (
              <div className="d-flex align-items-center">
                {column?.title}
                {(column?.title === "Similar Incident Summary" ||
                  column?.title === "Summary References") && (
                  <div style={{ marginLeft: "4px", marginTop: "2px" }}>
                    <AIGeneratedIcon className="" />
                  </div>
                )}
              </div>
            ),
            dataIndex: column?.dataIndex,
            key: column?.key,
            render: (text: string) =>
              text?.length > 80 ? (
                <Popover
                  content={text}
                  title=""
                  overlayInnerStyle={{
                    maxHeight: "260px",
                    overflowY: "auto",
                    padding: "10px",
                  }}
                  overlayStyle={{
                    maxWidth: "760px",
                    maxHeight: "260px",
                  }}
                >
                  {text.slice(0, 80)}...
                </Popover>
              ) : (
                text
              ),
          }))}
          dataSource={item?.data}
          pagination={{
            defaultPageSize: 5,
            hideOnSinglePage: true,
            showSizeChanger: false,
          }}
          style={{ marginTop: "10px" }}
        />
      ),
    };
  });

  return (
    <div style={{ marginTop: "245px", width: "100%" }}>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ marginTop: "20px" }}
      >
        {multiSelectDropdown("SQDIP")}
        {singleSelectDropdown(
          "LINE",
          lineOptions,
          selectedLine,
          setSelectedLine
        )}
        {singleSelectDropdown(
          "SHIFT",
          shiftOptions,
          selectedShift,
          setSelectedShift
        )}
        {singleSelectDropdown(
          "BUILDING",
          buildingOptions,
          selectedBuilding,
          setSelectedBuilding
        )}
        {singleSelectDropdown(
          "TIME",
          timeOptions,
          selectedTime,
          setSelectedTime
        )}
      </div>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ marginTop: "20px" }}
      >
        <h3>Select filters and click on Generate Report</h3>
        <Button
          type="secondary"
          label="Generate Report"
          className="run-report-btn"
          onClick={() => {
            setPayloadObject({
              site: "aoh",
              time: selectedTime,
              sqdip: isAllCheckboxChecked ? ["all"] : selectedSqdip,
              line: selectedLine,
              shift: selectedShift,
              building: selectedBuilding,
            });
          }}
        />
      </div>
      {isOneClickLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      ) : oneClickReportInfo?.data?.length === 0 ? (
        <div style={{ marginTop: "120px" }}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      ) : (
        <div style={{ marginTop: "40px" }}>
          <ConfigProvider
            theme={{
              components: {
                Timeline: {
                  fontSize: 12,
                },
                Table: {
                  fontSize: 12,
                },
                Popover: {
                  fontSize: 12,
                },
              },
            }}
          >
            <div className="timeline-container">
              <Timeline mode="left" items={timeLineItems} />
            </div>
          </ConfigProvider>
        </div>
      )}
    </div>
  );
}

export default OneClickReport;
