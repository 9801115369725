export const sqdipOptions = [
  { label: "Safety", value: "Safety" },
  { label: "Quality", value: "Quality" },
  { label: "Delivery", value: "Delivery" },
  { label: "Inventory", value: "Inventory" },
  { label: "Productivity", value: "Productivity" },
];

export const timeOptions = [
  { label: "Last Month", value: "last_month" },
  { label: "Last 2 Days", value: "last_2_days" },
  { label: "Last 24 Hours", value: "last_24_hours" },
];

export const lineOptions = [
  { label: "All", value: "all" },
  { label: "L001", value: "l001" },
  { label: "L002", value: "l002" },
];

export const shiftOptions = [
  { label: "All", value: "all" },
  { label: "Shift 1", value: "shift_1" },
  { label: "Shift 2", value: "shift_2" },
];

export const buildingOptions = [
  { label: "All", value: "all" },
  { label: "AOH1", value: "aoh_1" },
];

export const tierLabelMappping: any = {
  tier0: "Tier 0",
  tier1: "Tier 1",
  tier2: "Tier 2",
  tier3: "Tier 3",
};
