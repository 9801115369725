export const COLORS = {
    serious: "#E01029",
    potentiallySerious: "#FDB81E",
    major: "#0063C3",
    minor: "#9FE7E2",
    atRiskBehavior: "#0063C3",
    atRiskCondition: "#FDB81E",
    safeBehavior: "#2FBCB6",
    nonEHSIssue: "#0063C3",
    aging: "#E01029",
    adherence: "#0063C3",
    trends: "#FDB81E",
    dashedLine: "#00BCE4"
};

export interface DataItem {
    on_time_disposition_batches: number;
    not_on_time_disposition_batches: number;
    percent_batch_disposition_within_target: number;
    avg_turn_around_time: number;
    total_completed: number;
    avg_dlt_per_batch: number;
    percent_brr_completed_within_target: number;
    not_on_time_batches_mtd: number;
    on_time_batches_mtd: number;
    month?: string;
    closed_workorders: number;
    closure_adherence_percentage: number;
    total_workorders: number;
    GMCAL: number;
    CMCAL: number;
    PMCAL: number;
    value: number;
    line?: string;
    date?: string
    serious?: number
    potentiallySerious?: number
    major?: number
    minor?: number
    atRiskBehavior?: number
    atRiskCondition?: number
    safeBehavior?: number
    nonEHSIssue?: number
    aging?: number
    adherence?: number
    trends?: number
    last_7d_date?: string
    Month?: number
    Year?: number
    open_aging_count_7d?: number
    open_count_7d?: number
    mtd_date?: string
    not_on_time_review_mtd?: number
    on_time_review_mtd?: number
    deviation_percentage_mtd?: number
    deviation_percentage_6m?: number
    not_on_time_review_6m?: number
    on_time_review_6m?: number
    aging_open_percentage_6m?: number
    open_aging_count_6m?: number
    open_count_6m?: number
    open_count_mtd?: number
    open_aging_count_mtd?: number
    aging_open_percentage_mtd?: number
    coming_due_last_7d_count?: number
    aging_last_7days?: number
    normal_last_7days?: number
    compliance_date?: string
    compliance_rate?: number
    not_on_time_cc?: number
    on_time_cc?: number
    aging_cc?: number
    non_aging_cc?: number
    non_aging_flag?: number
    aging_flag?: number
    coming_due?: number
    on_time?: number
    not_on_time?: number
    aging_percentage?: number
    non_aging?: number
    closedWorkOrders?: number
    openWorkOrders?: number
    woClosureAdherence?: number
    pm_vs_cm_gm_ratio?: number
    pm_percentage_6m?: number
    labor_group?: string
    open?: number
    closed?: number
    pre_closure_over_weeks?: number
    quarter?: number
    ytd_actual?: number
    ytd_planned?: number
    quarterly_actual?: number
    year?: number | string

}

export interface StackedBarChartProps {
    data: DataItem[]
    timePeriod?:
    | "Last Week"
    | "Last Month"
    | "Last 3 Months"
    | "MTD"
    | "Last6Months";
    chartType: "see-cards" | "inca" | "minor-deviation" | "minor-deviation-adherence-mtd" | "minor-deviation-adherence-6m" | "minor-deviation-aging-mtd" | "minor-deviation-aging-6m"
    | "major-deviation" | "major-deviation-adherence-mtd" | "major-deviation-adherence-6m" | "major-deviation-aging-mtd" | "major-deviation-aging-6m" | "change-control-status"
    | "change-control-adherence-6m" | "change-control-aging-mtd" | "change-control-aging-6m" | "change-control-adherence-mtd" | "capa-status" |
    "capa-status-mtd" | "capa-status-adherence-6m" | "capa-status-aging-mtd" | "capa-status-aging-6m" | "ratio-pm" | "ratio-pm-vs" | "percent-closure-pm" | "aging-work-orders" | "rft"
    | "wo-closure-adherence" | "wo-calibration-dis" | "per-calibration-closure" | "on-time-schedule-bv" | "batch-disposition" | "global";

    xAxisData?: string[]
    yAxisNames?: { name: string; position: string, max: number | undefined }[]
    nameGapCustom?: number
    chartConfig?: ChartConfigProps[]
    yAxisMax?: number
    showSlider?: boolean
    grid?: EchartsGridProps
}

export interface EchartsGridProps {
    top?: number | string
    right?: number | string
    bottom?: number | string
    left?: number | string
    containLabel?: boolean
}

export interface ChartConfigProps extends LegendProps {
    dataKey: string;
    nameKey: string;
}

export interface LegendProps {
    statusValue: string;
    circleColor: string;
}

export const excludedKeys = ['last_7d_date', 'Month', 'Year', 'deviation_percentage_6m',
    'deviation_percentage_mtd', 'mtd_date', 'aging_open_percentage_6m', 'aging_open_percentage_mtd',
    'compliance_date', 'compliance_rate', 'aging_percentage', 'date', 'weekend', 'week', 'date_of_incident', 'priority', 'year_of_incident'];
