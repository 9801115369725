import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOktaToken } from "utils/common-methods";

interface FetchDataParams {
  site?: string | undefined;
  tierNumber?: string | undefined;
  metrics?: string | undefined;
  id?: string | undefined;
  uniqueId?: string | undefined;
  note?: string | undefined;
}

const postWctNotes = createAsyncThunk<any, FetchDataParams>(
  "/work-center-team/note",
  async (params: FetchDataParams) => {
    const { site, tierNumber, metrics, id, uniqueId, note } = params;
    const token: string = getOktaToken();
    const response = await fetch(
      `${process.env.REACT_APP_GENAI_API_URL}/work-center-team/note?site=${site}&tierNumber=${tierNumber}&uuid=${id}&metric=${metrics}&unique_alert_id=${uniqueId}&note=${note}`,
      {
        method: "POST",
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to add WCT Notes");
    }
    return await response.json();
  }
);

const addWctNotesReducer = createSlice({
  name: "addWctNotes",
  initialState: {
    postNotesInfo: [],
    isNotePostLoading: false,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postWctNotes.pending, (state) => {
      state.isNotePostLoading = true;
      state.error = false;
    });
    builder.addCase(postWctNotes.fulfilled, (state, action) => {
      state.postNotesInfo = action.payload.data;
      state.isNotePostLoading = false;
      state.error = false;
    });
    builder.addCase(postWctNotes.rejected, (state) => {
      state.isNotePostLoading = false;
      state.error = true;
    });
  },
});

export { postWctNotes };
export default addWctNotesReducer.reducer;
