import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { APIResponseDataType, FetchDataParams } from 'types/wctTypes';
import { filterParams, getOktaToken } from 'utils/common-methods';
import { AGING_WO_DEEP_DIVE_URL, AGING_WO_VIEW_BY_URL } from 'utils/constants';
import { StringKeyAnyDataProps } from 'utils/data-types';

interface AgingWoDeepDiveStateProps {
    agingWoDeepDiveInfo: APIResponseDataType[] | null;
    agingWoViewByData: StringKeyAnyDataProps[] | null;
    loading: boolean;
    error: boolean;
}

const initialState: AgingWoDeepDiveStateProps = {
    agingWoDeepDiveInfo: null,
    agingWoViewByData: null,
    loading: false,
    error: false
};

const fetchAgingWoDeepDive = createAsyncThunk<APIResponseDataType[], FetchDataParams>(
    AGING_WO_DEEP_DIVE_URL,
    async (params) => {
        const filteredParams = filterParams(params);
        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}${AGING_WO_DEEP_DIVE_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch PM Adherence');
        }
        return await response.json();
    }
);

const fetchAgingWoViewByData = createAsyncThunk<StringKeyAnyDataProps[], FetchDataParams>(
    AGING_WO_VIEW_BY_URL,
    async (params) => {
        const filteredParams = filterParams(params);
        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}${AGING_WO_VIEW_BY_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch radio filter data');
        }
        return await response.json();
    }
);

const agingWoDeepDiveReducer = createSlice({
    name: 'agingWoDeepDive',
    initialState,
    reducers: {
        clearAgingWoDeepDiveData(state) {
            state.agingWoDeepDiveInfo = null;
            state.agingWoViewByData = null;
        },
        
        clearAgingWoViewByData(state) {
            state.agingWoViewByData = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAgingWoDeepDive.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchAgingWoDeepDive.fulfilled, (state, action) => {
                state.loading = false;
                state.agingWoDeepDiveInfo = action.payload;
            })
            .addCase(fetchAgingWoDeepDive.rejected, (state) => {
                state.loading = false;
                state.error = true;
            })
            .addCase(fetchAgingWoViewByData.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchAgingWoViewByData.fulfilled, (state, action) => {
                state.loading = false;
                state.agingWoViewByData = action.payload;
            })
            .addCase(fetchAgingWoViewByData.rejected, (state) => {
                state.loading = false;
                state.error = true;
            });
    }
});

export const { clearAgingWoDeepDiveData, clearAgingWoViewByData } = agingWoDeepDiveReducer.actions;
export { fetchAgingWoDeepDive, fetchAgingWoViewByData };
export default agingWoDeepDiveReducer.reducer;
