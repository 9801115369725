import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine, CartesianGrid, Area, ComposedChart } from 'recharts';
import { SimpleLineGraphProps } from './CustomMetricCardTypes';
import CustomXAxisTick from './tick-formatter/CustomXAxisTick';
import CustomYAxisTick from './tick-formatter/CustomYAxisTick';
import { CurveType } from 'recharts/types/shape/Curve';

const SimpleLineGraph: React.FC<SimpleLineGraphProps> = (props) => {
  const {
    data,
    lineColor = '#0063C3',
    height = 100,
    marginTop = 16,
    marginBottom = 4,
    marginLeft = -24,
    marginRight = 4,
    xAxisLine = { stroke: '#00000026' },
    xAxisInterval = 0,
    yAxisTickMargin = 16,
    yAxisDomain = [0, 100],
    xAxisDataKey = 'name',
    lineStrokeWidth = 2,
    lineDataKey = 'value',
    targetReferenceLineStroke = '#00000026',
    targetReferenceValue,
    thresholdReferenceLineStroke = '#00000026',
    thresholdReferenceStrokeDashArray = '3 3',
    xAxisTickLine = false,
    yAxisLine = false,
    yAxisTickLine = false,
    lineDot = false,
    strokeDashedarray = "",
    grid = {},
    lineGraphType
  } = props
  const xStartLabel = data[0]?.name;
  const xEndLabel = data[data.length - 1]?.name;
  const isDeci = data.some(item => {
    return typeof item.value === 'number' && item.value % 1 !== 0;
  })
  const graphType = lineGraphType ?? "monotone"
  const maxValue = Math.max(
    ...data
      .map(obj => (obj.value !== undefined? parseFloat(String(obj.value)) ?? 0: 0)) // Create an array of values
      .filter((value): value is number => typeof value === 'number') // Filter out non-numbers
  );
  let yEndLabel = Math.ceil((maxValue ?? 5)/5) * 5
 
  if (yEndLabel === 0) {
    yEndLabel = 5
  }
  
  if (isDeci) {
    yEndLabel = 100;
  }

  return (
    <ResponsiveContainer width="100%" height={height}>
      <ComposedChart
        data={data}
        margin={{ top: marginTop, right: marginRight, bottom: marginBottom, left: marginLeft }}
      >
        <XAxis
          dataKey={xAxisDataKey}
          tick={(props) => <CustomXAxisTick {...props} startLabel={xStartLabel} endLabel={xEndLabel} />}
          axisLine={xAxisLine}
          tickLine={xAxisTickLine}
          interval={xAxisInterval}
        />
        <YAxis
          tick={(props) => <CustomYAxisTick {...props} isDeci={isDeci}/>}
          axisLine={yAxisLine}
          tickLine={yAxisTickLine}
          tickMargin={yAxisTickMargin}
          domain={[0,yEndLabel]}
          ticks={[0,yEndLabel]}
        />
        {grid.stroke? <CartesianGrid 
          stroke = {String(grid?.stroke)}
          strokeDasharray={grid?.strokeDashedarray}
          horizontal={true}
          vertical={false}
        /> : null}
        {graphType === "linear" && <Area type={graphType} dataKey={lineDataKey} fill="rgba(0, 122, 255, 0.2)" stroke="none" />}
        <Line
          type={graphType as CurveType}
          dataKey={lineDataKey} 
          stroke={targetReferenceValue?(lineDataKey >= targetReferenceValue?"#E01029":"#2CC84D"):lineColor} 
          strokeWidth={lineStrokeWidth} 
          dot={lineDot} 
          strokeDasharray={strokeDashedarray}/>
        <ReferenceLine y={targetReferenceValue} stroke={targetReferenceLineStroke} strokeDasharray="5 5"/>
        <ReferenceLine y={yEndLabel} stroke={targetReferenceLineStroke}/>
        {props.thresholdReferenceValue && <ReferenceLine y={props.thresholdReferenceValue}
          stroke={thresholdReferenceLineStroke}
          strokeDasharray={thresholdReferenceStrokeDashArray} />  /* Threshold line */}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default SimpleLineGraph;