import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOktaToken } from "utils/common-methods";

interface FetchDataParams {
  site?: string | undefined;
  tierNumber?: string | undefined;
  metrics?: string | undefined;
  id?: string | undefined;
  uniqueId?: string | undefined;
}

const fetchWctNotes = createAsyncThunk<any, FetchDataParams>(
  "/work-center-team/note",
  async (params: FetchDataParams) => {
    const { site, tierNumber, metrics, id, uniqueId } = params;
    const token: string = getOktaToken();
    const response = await fetch(
      `${process.env.REACT_APP_GENAI_API_URL}/work-center-team/note?site=${site}&tierNumber=${tierNumber}&uuid=${id}&metric=${metrics}&unique_alert_id=${uniqueId}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch WCT Notes");
    }
    return await response.json();
  }
);

const wctNotesReducer = createSlice({
  name: "wctNotes",
  initialState: {
    wctNotesInfo: [],
    isWctNotesLoading: false,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWctNotes.pending, (state) => {
      state.isWctNotesLoading = true;
      state.error = false;
    });
    builder.addCase(fetchWctNotes.fulfilled, (state, action) => {
      state.wctNotesInfo = action.payload.data;
      state.isWctNotesLoading = false;
      state.error = false;
    });
    builder.addCase(fetchWctNotes.rejected, (state) => {
      state.isWctNotesLoading = false;
      state.error = true;
    });
  },
});

export { fetchWctNotes };
export default wctNotesReducer.reducer;
