import React from 'react';
import { Radio } from "@gitlab-rtsensing/component-library";

interface RadioFilterProps<T> {
    label: string;
    options: T[];
    selectedOption: T;
    type?: 'primary' | 'secondary' | 'disabled';
    setSelectedOption: (value: T) => void;
    filterDataByOption: (value: T) => void;
}

// Define constant style objects
const styles = {
    container: {
        display: 'flex',
        marginBottom: '10px',
    },
    label: {
        fontSize: '12px', // equivalent to ops-fs-5
        fontWeight: '600', // equivalent to ops-fw-bold
        marginRight: '15px',
    },
    radioContainer: {
        marginRight: '8px',
    },
};

export const RadioFilter = <T extends string | number>({
    label,
    options,
    selectedOption,
    type = 'primary',
    setSelectedOption,
    filterDataByOption,
}: RadioFilterProps<T>) => {
    return (
        <div style={styles.container}>
            <div style={styles.label}>
                {label.toUpperCase()} :
            </div>
            {options.map((option) => (
                <div key={option} style={styles.radioContainer}>
                    <Radio
                        name="radioFilter"
                        value={option}
                        checked={selectedOption === option}
                        onChange={() => {
                            setSelectedOption(option);
                            filterDataByOption(option);
                        }}
                        variant={type}>
                        {option}
                    </Radio>
                </div>
            ))}
        </div>
    );
};