import { useEffect, useRef, useState } from "react";
import "./index.scss";
import { Empty } from "antd";

interface percentDetail {
  percentage: number;
  flag: string;
  passedHour: string;
  totalHour: string;
}

interface ProgressType {
  percentageArray: percentDetail[];
}

const CleanProgressBar: React.FC<ProgressType> = ({ percentageArray }) => {
  const [boxWidth, setBoxWidth] = useState<number>(1);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentWidth = containerRef.current?.clientWidth;
    setBoxWidth((prev) => currentWidth ?? prev);
  }, [containerRef]);

  return (
    <div ref={containerRef} className="clean-progress-container-no-round">
      {percentageArray.length > 0 &&(
        <>
          <div className="progress-bar-wrapper">
            {percentageArray.map((data: percentDetail, i: number) => (
              <div
                key={i}
                className={
                  data.flag === "RED"
                    ? "child-div-red-container"
                    : data.flag === "BLUE"
                    ? "child-div-blue-container"
                    : " "
                }
                style={{
                  width: `${data.percentage}%`,
                }}
              />
            ))}
          </div>
          <div className="progress-labels">
            <span className="start-label">0 HR</span>
            {percentageArray.map((data: percentDetail, i: number) => (
              <div
                key={i}
                className="black-line-container"
                style={{
                  left: `${data.percentage}%`,
                  transform: "translateX(-50%)",
                }}
              >
                <div className="progress-black-line" />
                <span className="tooltip">{data.passedHour}</span>
              </div>
            ))}
            <span className="end-label">{percentageArray[0]?.totalHour}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default CleanProgressBar;
