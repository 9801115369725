// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MetricsComponent_cardContainer__Sn94M {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding: 4px;
    flex: 1 1;
    gap: 8px;
    word-break: break-word;
    overflow-wrap: break-word;
    border-radius: 8px;
    background-color: #F0F0F0;
    font-weight: 600;
    font-size: 14px;
    color: #1F1F1F;
}

.MetricsComponent_cardContainer__Sn94M .ant-alert-error {
    background-color: #FFE7E6;
    border: 1px solid #E01029;
}

.MetricsComponent_cardContainer__Sn94M .ant-alert-success {
    background-color: #F0FFF0;
    border: 1px solid #2CC84D;
}

.MetricsComponent_sqdipBtn__flPpQ {
    background-color: #FFFFFF !important;
}

.MetricsComponent_sqdipBtn__flPpQ:hover {
    background-color: #0063C3 !important;
}

.MetricsComponent_customSubCard__1nbVq .ant-alert-success {
    border: 1px solid #F0F0F0;
    background-color: #F0FFF0;
} 

.MetricsComponent_customSubCard__1nbVq .ant-alert-error {
    border: 1px solid #F0F0F0;
    background-color: #FFE7E6;
}

.MetricsComponent_customAlert__gF8Of {
    position: -webkit-sticky;
    position: sticky;
    top: var(--alert-top-position);
    font-weight: 600;
    z-index: 20;
}`, "",{"version":3,"sources":["webpack://./src/components/metrics-component/MetricsComponent.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAW;IACX,sBAAsB;IACtB,YAAY;IACZ,SAAO;IACP,QAAQ;IACR,sBAAsB;IACtB,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,wBAAgB;IAAhB,gBAAgB;IAChB,8BAA8B;IAC9B,gBAAgB;IAChB,WAAW;AACf","sourcesContent":[".cardContainer {\n    display: flex;\n    flex: 1 1 0;\n    flex-direction: column;\n    padding: 4px;\n    flex: 1;\n    gap: 8px;\n    word-break: break-word;\n    overflow-wrap: break-word;\n    border-radius: 8px;\n    background-color: #F0F0F0;\n    font-weight: 600;\n    font-size: 14px;\n    color: #1F1F1F;\n}\n\n.cardContainer :global(.ant-alert-error) {\n    background-color: #FFE7E6;\n    border: 1px solid #E01029;\n}\n\n.cardContainer :global(.ant-alert-success) {\n    background-color: #F0FFF0;\n    border: 1px solid #2CC84D;\n}\n\n.sqdipBtn {\n    background-color: #FFFFFF !important;\n}\n\n.sqdipBtn:hover {\n    background-color: #0063C3 !important;\n}\n\n.customSubCard :global(.ant-alert-success) {\n    border: 1px solid #F0F0F0;\n    background-color: #F0FFF0;\n} \n\n.customSubCard :global(.ant-alert-error) {\n    border: 1px solid #F0F0F0;\n    background-color: #FFE7E6;\n}\n\n.customAlert {\n    position: sticky;\n    top: var(--alert-top-position);\n    font-weight: 600;\n    z-index: 20;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": `MetricsComponent_cardContainer__Sn94M`,
	"sqdipBtn": `MetricsComponent_sqdipBtn__flPpQ`,
	"customSubCard": `MetricsComponent_customSubCard__1nbVq`,
	"customAlert": `MetricsComponent_customAlert__gF8Of`
};
export default ___CSS_LOADER_EXPORT___;
