/* eslint-disable @typescript-eslint/restrict-plus-operands */

import { useDispatch, useSelector } from "react-redux";
import PageLayout from "components/resuable/layout";
import "./index.scss";
import { useLocation, useParams } from "react-router";
import { useContext, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Breadcrumb,
  ConfigProvider,
  Tabs,
  Popover,
} from "antd";
import {
  permissionObject,
  personas,
  wctNavigation,
  wctSafetyDeepdiveNavigation,
  wctQualityDeepdiveNavigation,
  SQDIPType,
  safetyMetricNames,
  qualityMetricNames,
  deliveryMetricNames,
  wctDeliveryDeepdiveNavigation,
  inventoryMetricNames,
  productivityMetricNames,
  wctInventoryDeepdiveNavigation,
  wctProductivityDeepdiveNavigation,
} from "utils/constants";
import {
  BreadcrumbMenuItemType,
  MCT,
  PermissionType,
  PersonasAuthorization,
} from "types/permission-type";
import { DeepDiveTabItemsType } from "types/wctTypes";
import Header from "./aoh/wct/header/Header";
import type { MenuProps } from "antd";
import AohDropdown from "components/common/dropdown/aohdropdown";
import wctCss from "./workCenterTeam.module.css";
import AIGeneratedIcon from "assets/icons/AIGeneratedIcon";
import { NavLink } from "components/side-nav/types";
import {
  Button,
  Loader,
  MetricCard,
} from "@gitlab-rtsensing/component-library";

import { AppDispatch, RootState } from "../store";
import { fetchTierInfo } from "reducers/tierInfoReducer";
import ErrorHandler from "utils/error-handler";
import { clearCalendarData, fetchCalendar } from "reducers/safety-base-view/calendarReducer";
import AohDeepDiveMain from "components/aoh-deep-dive-main";
import { fetchLineInfo } from "reducers/lineInfoReducer";
import { fetchOverallSummary } from "reducers/sqdipSummaryReducer";
import { fetchDeepdiveSummary } from "reducers/safety-deep-dive/deepdiveSummaryReducer";
import { updateCustomMetricParams } from "reducers/customMetricParamsReducer";
import DeepdiveDrawer from "components/common/drawer/DeepdiveDrawer";
import OneClickReport from "./one-click-report";
import MetricsContainer from "components/metrics-component/MetricsComponent";
import { AppContext } from "context/app-context";

interface WorkCenterTeamType {
  permissions: PermissionType[];
  setNavList: (navList: NavLink) => void;
}

interface CustomEventDetail {
  tabKey: string;
}

interface HeaderDropDownType {
  key: string;
  label: string;
}

interface ShiftType {
  key?: number;
  label?: string;
  manager?: ManagerType[];
}

interface ManagerType {
  key: number;
  label: string;
}

interface CostCenterType {
  name: string;
}

const initialDeepDiveTabItems: DeepDiveTabItemsType = {
  Safety: wctSafetyDeepdiveNavigation,
  Quality: Object.values(wctQualityDeepdiveNavigation),
  Delivery: Object.values(wctDeliveryDeepdiveNavigation),
  Inventory: Object.values(wctInventoryDeepdiveNavigation),
  Productivity: Object.values(wctProductivityDeepdiveNavigation)
}

const WorkCenterTeam = (props: WorkCenterTeamType) => {
  const dispatch = useDispatch<AppDispatch>();
   const { appState, setAppState } = useContext(AppContext);
  const { tierInfo, loading, error } = useSelector((state: RootState) => state.tierInfo);
  const { calendarInfo } = useSelector((state: RootState) => state.calendar);
  const { workOrderInfo } = useSelector((state: RootState) => state.workOrder);
  const { incaInfo } = useSelector((state: RootState) => state.incaInfo);
  const { capaInfo } = useSelector((state: RootState) => state.capaInfo);
  const { ehssInfo } = useSelector((state: RootState) => state.ehssInfo);
  const { seeCardsInfo } = useSelector((state: RootState) => state.seeCardsInfo);
  const { lineInfo } = useSelector((state: RootState) => state.lineInfo);
  const { seeCardsDeepDiveInfo } = useSelector((state: RootState) => state.seeCardsDeepDive)
  const { incaDeepDiveInfo } = useSelector((state: RootState) => state.incaDeepDive)
  const { workOrderDeepDiveInfo } = useSelector((state: RootState) => state.workOrderDeepDive)
  const { ehssDeepDiveInfo } = useSelector((state: RootState) => state.ehssDeepDive)
  const { capaDeepDiveInfo } = useSelector((state: RootState) => state.capaDeepDive)
  const { qualityChangeControlInfo } = useSelector((state: RootState) => state.qualityChangeControlInfo)
  const { qualityCAPAInfo } = useSelector((state: RootState) => state.qualityCAPAInfo)
  const { minorDeviationInfo } = useSelector((state: RootState) => state.minorDeviation);
  const { majorDeviationInfo } = useSelector((state: RootState) => state.majorDeviation);
  const { gmpTrainingInfo } = useSelector((state: RootState) => state.gmpTraining);
  const { minorDeviationDeepDiveInfo } = useSelector((state: RootState) => state.minorDeviationDeepDive);
  const { majorDeviationDeepDiveInfo } = useSelector((state: RootState) => state.majorDeviationDeepDive);
  const { changeControlDeepDiveInfo } = useSelector((state: RootState) => state.changeControlDeepdiveInfo);
  const { overallSummaryInfo, isBaseSummaryLoading } = useSelector((state: RootState) => state.overallSummary);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { deepdiveSummaryInfo } = useSelector((state: RootState) => state.deepdiveSummary);
  const { gxpDeepDiveInfo } = useSelector((state: RootState) => state.qualityGxpDeepDiveInfo)
  const { qualityRFTInfo } = useSelector((state: RootState) => state.qualityRFTInfo)
  const { qualityDdRFTInfo } = useSelector((state: RootState) => state.qualityDdRFT)
  const { qualityCapaDeepDiveInfo } = useSelector((state: RootState) => state.qualityCapaDeepDiveInfo)
  const { agingWorkorderInfo } = useSelector((state: RootState) => state.agingWorkorder)
  const { pmAdherenceInfo } = useSelector((state: RootState) => state.pmAdherence)
  const { deliveryCalibrationInfo } = useSelector((state: RootState) => state.deliveryCalibrationInfo)
  const { batchDispoitionInfo } = useSelector((state: RootState) => state.batchDisposition)
  const { OnTimeScheduleAdherenceBVInfo } = useSelector((state: RootState) => state.onTimeScheduleAdherence)
  const { pmAdherenceDeepDiveInfo } = useSelector((state: RootState) => state.pmAdherenceDeepDive)
  const { CalClosureAdherenceDeepDiveInfo } = useSelector((state: RootState) => state.calClosureAdherenceDeepDive)
  const { agingWoDeepDiveInfo } = useSelector((state: RootState) => state.agingWoDeepDive)
  const { batchDispositionDeepDiveInfo } = useSelector((state: RootState) => state.batchDispositionDeepDive)
  // Inventory Base View
  const { turnOverInfo } = useSelector((state: RootState) => state.turnOver)
  const { criticalMfcInfo } = useSelector((state: RootState) => state.criticalMfc)
  const { kanbanHealthBVInfo } = useSelector((state: RootState) => state.kanbanHealthBV)
  // Inventory Deep Dive View
  const { briticalMfcDeepDiveInfo } = useSelector((state: RootState) => state.criticalMfcDeepDive)

  const {
    dropContainer,
    wctContainer,
    relativeContainer,
    wctHeaderContainer,
    notificationBar,
    tabHeader,
    wctContentContainer,
    sqdipBtn
  } = wctCss;
  const { permissions, setNavList } = props;
  const { site } = useParams();
  const location = useLocation();
  const [persona, setPersona] = useState({
    name: "wct",
    label: "Work Center Team",
  });
  const headerRef = useRef<HTMLDivElement>(null);
  const aiNotificationRef = useRef<HTMLDivElement>(null);
  const paragraphRef = useRef<HTMLParagraphElement>(null);
  const [activeKey, setActiveKey] = useState<string>("tab-1");
  const [showMore, setShowMore] = useState<boolean>(false)
  const [tierLabel, setTierLabel] = useState("Tier 0");
  const [shifts, setShifts] = useState<ShiftType[]>();
  const [currentShift, setCurrentShift] = useState<ShiftType>();
  const [currentLine, setCurrentLine] = useState(lineInfo?.lines ? lineInfo?.lines[0]?.line : "");
  const [building, setBuilding] = useState(lineInfo?.lines ? lineInfo?.lines[0]?.buildingName : "");
  const [costCenters, setCostCenter] = useState<CostCenterType[]>();
  const [currentCostCenter, setCurrentCostCenter] = useState(
    lineInfo?.lines && lineInfo?.lines[0]?.costCenters
      ? lineInfo?.lines[0]?.costCenters[0]?.name
      : ""
  );
  const [metrics, setMetric] = useState(tierInfo?.tiers?.[0]?.metricName)

  // set deep dive generic data
  const [deepDiveTabItems, setDeepDiveTabItems] = useState<DeepDiveTabItemsType>(initialDeepDiveTabItems)
  // Safety Deep Dive State
  const [isSafetyOpenDeepDive, setIsSafetyOpenDeepDive] = useState(false);
  const [activeSafetyKeyDeepDiveTab, setActiveSafetyKeyDeepDiveTab] = useState<string>(wctSafetyDeepdiveNavigation[0]);
  // Quality Deep Dive State
  const [isQualityOpenDeepDive, setIsQualityOpenDeepDive] = useState(false);
  const [activeQualityKeyDeepDiveTab, setActiveQualityKeyDeepDiveTab] = useState<string>(deepDiveTabItems.Quality[0])
  // delivery Deep Dive Status
  const [isDeliveryOpenDeepDive, setIsDeliveryOpenDeepDive] = useState(false);
  const [activeDeliveryKeyDeepDiveTab, setActiveDeliveryKeyDeepDiveTab] = useState<string>(deepDiveTabItems.Delivery[0])
  // inventory Deep Dive Status
  const [isInventoryOpenDeepDive, setIsInventoryOpenDeepDive] = useState(false);
  const [activeInventoryKeyDeepDiveTab, setActiveInventoryKeyDeepDiveTab] = useState<string>(deepDiveTabItems.Inventory[0])
  // productivity Deep Dive Status
  const [isProductivityOpenDeepDive, setIsProductivityOpenDeepDive] = useState(false);
  const [activeProductivityKeyDeepDiveTab, setActiveProductivityKeyDeepDiveTab] = useState<string>(deepDiveTabItems.Productivity[0])

  const [deepDiveAlert, setDeepDiveAlert] = useState("");
  const [summaryId, setSummaryId] = useState("");
  const [summaryMetric, setSummaryMetric] = useState("");

  const summaryComponent = () => {
    const loopData = !showMore
      ? overallSummaryInfo?.data?.slice(0, 1)
      : overallSummaryInfo?.data;
    return (
      <div>
        {loopData?.map((item: any, index: any) => (
          <div
            className="d-flex align-items-center justify-content-between"
            style={{
              fontSize: "12px",
              marginLeft: "5px",
              marginRight: "5px",
              marginTop: index === 0 ? "0px" : "5px",
              paddingTop: index === 0 ? "0px" : "5px",
              borderTop: index === 0 ? "none" : "1px solid #d9d9d9",
            }}
            key={index}
          >
            {item?.metric && <div style={{ width: "70px", fontWeight: 700, fontSize: "16px" }}>
              {item?.metric?.charAt(0).toUpperCase() + item?.metric?.slice(1)?.toLowerCase()}
            </div>}
            <div
              style={{ width: "85%" }}
              dangerouslySetInnerHTML={{ __html: item?.summary }}
            ></div>
            {item?.metric && <Button
              className={sqdipBtn}
              label="Deep Dive"
              type="secondary"
              onClick={() => {
                setIsDrawerOpen(true);
                setDeepDiveAlert(item?.summary);
                setSummaryId(item?.id);
                setSummaryMetric(item?.metric.toLowerCase())
              }}
            />}
          </div>
        ))}
      </div>
    )
  }

  const tierDropDownData: HeaderDropDownType[] =
    tierInfo?.tiers?.map((tier) => {
      return {
        key: tier.label,
        label: tier.label,
      };
    }) ?? [];

  const lineDropDownData: HeaderDropDownType[] =
    lineInfo?.lines?.map((line) => {
      return {
        key: line?.line ?? "",
        label: line?.line ?? "",
      };
    }) ?? [];

  const buildingDropDownData: HeaderDropDownType[] =
    lineInfo?.lines?.map((line) => {
      return {
        key: line?.buildingName ?? "",
        label: line?.buildingName ?? "",
      };
    }) ?? [];

  const shiftDropDownData: HeaderDropDownType[] =
    shifts?.map((shift) => {
      return {
        key: shift?.label ?? "",
        label: shift?.label ?? "",
      };
    }) ?? [];

  const handleTierClick: MenuProps["onClick"] = (e) => {
    const latestTierInfo = tierInfo?.tiers.filter(
      (tier) => tier.label === e?.key
    )[0];
    if (latestTierInfo) {
      setTierLabel(latestTierInfo.label);
      setMetric(latestTierInfo?.metricName);
    }
  };

  const handleShiftClick: MenuProps["onClick"] = (e) => {
    const latestShiftInfo: ShiftType | undefined = shifts?.filter(
      (shift) => shift?.label === e?.key
    )[0];
    if (latestShiftInfo) {
      setCurrentShift(latestShiftInfo);
    }
  };

  const handleLineClick: MenuProps["onClick"] = (e) => {
    const latestLineInfo = lineInfo?.lines?.filter(
      (line) => line?.line === e?.key
    )[0];
    if (latestLineInfo) {
      setCurrentLine(latestLineInfo.line);
    }
  };

  const handleCostCenter = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget.textContent) {
      setCurrentCostCenter(e.currentTarget.textContent);
    }
  };

  const content = (
    <div style={{ maxHeight: "240px", overflowY: "auto" }}>
      {costCenters
        ? costCenters.map((item) => {
          return (
            <div
              key={item.name}
              onClick={handleCostCenter}
              className={wctCss.showCCDrop}
            >
              {item.name}
            </div>
          );
        })
        : "Loading..."}
    </div>
  );

  const handleBuildingClick: MenuProps["onClick"] = (e) => {
    const latestBuildingInfo = lineInfo?.lines?.filter(
      (line) => line?.buildingName === e?.key
    )[0];
    if (latestBuildingInfo) {
      setBuilding(latestBuildingInfo.buildingName);
    }
  };

  const handleShowMoreClick = () => {
    setShowMore((prevState: boolean) => !prevState);
    if (paragraphRef.current) {
      paragraphRef.current.scrollTop = 0; // Scroll to the top
    }
  }

  useEffect(() => {
    if (lineInfo?.lines) {
      if (lineInfo?.lines[0]?.line) {
        setCurrentLine(lineInfo?.lines[0]?.line);
      }
      setShifts(lineInfo?.lines[0]?.shift);
      setBuilding(lineInfo?.lines[0]?.buildingName);
    }
  }, [lineInfo?.lines]);

  useEffect(() => {
    if (lineInfo?.lines) {
      const buildingData = lineInfo?.lines.find(
        (item) => item.buildingName === building
      );
      setCostCenter(buildingData?.costCenters);
    }
  }, [building]);

  useEffect(() => {
    setMetric(tierInfo?.tiers[0]?.metricName);
    const params = {
      tierNumber: tierInfo?.tiers[0]?.label.toLowerCase(),
    };
    if (tierInfo) dispatch(fetchLineInfo(params));
  }, [tierInfo?.tiers]);

  useEffect(() => {
    if (shifts && shifts.length > 0) {
      setCurrentShift(shifts[0]);
    }
  }, [shifts])

  useEffect(() => {
    if (tierLabel) {
      const params = {
        tierNumber: tierLabel.toLowerCase(),
      };
      dispatch(fetchLineInfo(params));
    }
  }, [tierLabel])

  useEffect(() => {
    const safetyTabItems = tierLabel.includes('Tier 0') ? Object.values(wctSafetyDeepdiveNavigation).slice(0, 4) : Object.values(wctSafetyDeepdiveNavigation);

    const qualityTabItems: string[] = Object.values(wctQualityDeepdiveNavigation).map((enumValue) => {
      if (metrics?.find(({ label }) => enumValue === label)) {
        return enumValue as string
      }
      return ''
    }).filter(val => val !== undefined && val !== "") ?? Object.values(wctQualityDeepdiveNavigation)
    
    const deliveryTabItems: string[] = Object.values(wctDeliveryDeepdiveNavigation).map((enumValue) => {
      if (metrics?.find(({ label }) => enumValue === label)) {
        return enumValue as string
      }
      return ''
    }).filter(val => val !== undefined && val !== "") ?? Object.values(wctDeliveryDeepdiveNavigation)

    setDeepDiveTabItems(prev => ({
      ...prev,
      Safety: safetyTabItems,
      Quality: qualityTabItems,
      Delivery: deliveryTabItems
    }))

    setActiveSafetyKeyDeepDiveTab(safetyTabItems[0])
    setActiveQualityKeyDeepDiveTab(qualityTabItems[0])
    setActiveDeliveryKeyDeepDiveTab(deliveryTabItems[0])
  }, [metrics, tierLabel])

  function checkTierParams() {
    switch (tierLabel.toLowerCase()) {
      case "tier 0":
        if (currentLine && currentShift?.label) {
          return true;
        }
        return false;
      case "tier 1":
        if (currentShift?.label) {
          return true;
        }
        return false;
      case "tier 2":
        if (building) {
          return true;
        }
        return false;
      default:
        return true;
    }
  }

  useEffect(() => {
    const shift = currentShift?.label;
    const costCenter = currentCostCenter;
    const line = currentLine;
    if (checkTierParams()) {
      const updatedParams = {
        workStream: appState.workStream,
        site: site ?? "",
        persona: persona.name ?? "",
        tierNumber: tierLabel.toLowerCase() ?? "",
        line: line ?? "",
        shift: shift ?? "",
        interval: "6 days",
        costCenter: costCenter?.split("-")[0] ?? "",
        building,
        viewBy: "schedule_gm"
      }

      dispatch(clearCalendarData())

      dispatch(updateCustomMetricParams(updatedParams))
      dispatch(fetchCalendar(updatedParams));
      dispatch(fetchOverallSummary(updatedParams));
      dispatch(fetchDeepdiveSummary(updatedParams))
    }
  }, [tierLabel, currentShift, building, currentCostCenter, currentLine, tierInfo])

  const mfgItems = [
    {
      key: "1",
      label: <a href="/manufacturing">Manufacturing</a>,
    },
  ];

  const avaialableSites: Record<string, boolean> = Object.fromEntries(
    Object.entries(permissionObject).filter(
      ([, value]: [string, boolean]) => value
    )
  );

  const siteItems: BreadcrumbMenuItemType[] = Object.entries(
    avaialableSites
  ).map(([key], index) => {
    return {
      key: `${index + 1}`,
      label: (
        <a
          rel="noopener noreferrer"
          key={`${key}-menu-${index + 1}`}
          href={`/manufacturing/${key}`}
        >
          {key.toUpperCase()}
        </a>
      ),
    };
  });

  const personaObject: PersonasAuthorization | undefined =
    permissions[1]?.workstreams?.mct?.filter(
      (permission: MCT) => permission.url === site
    )[0]?.personas;

  const personaItems = Object.entries(personaObject ? personaObject : {})
    .filter(([key, value]) => {
      if (key === "eTOC" && value) {
        return false;
      } else {
        return true;
      }
    })
    ?.map(([key], index) => {
      const persona = personas.filter((persona) => persona.name === key)[0];

      return {
        key: `${index + 1}`,
        label: (
          <a
            rel="noopener noreferrer"
            href={`/manufacturing/${site}/${persona?.url}`}
          >
            {persona?.label}
          </a>
        ),
      };
    });

  useEffect(() => {
    setNavList({
      label: site?.toUpperCase() ?? "",
      authorized: true,
      externalSections: [],
      internalSections: [
        {
          sectionLabel: "WORK CENTER TEAM",
          links: wctNavigation.map((sideNavLabel: string, index: number) => {
            return {
              label: sideNavLabel,
              componentKey: `tab-${index + 1}`,
            };
          }),
        },
        {
          sectionLabel: "Shop Floor",
          links: [
            {
              label: "All Lines",
            },
            {
              label: "Line 1",
            },
            {
              label: "Line 2",
            },
          ],
        },
        {
          sectionLabel: "Learners/eTOC",
          links: [
            {
              label: `eTOC ${site?.toUpperCase()} Home`,
            },
            {
              label: "Menu Item 2",
            },
            {
              label: "Menu Item 3",
            },
          ],
        },
      ],
    })

    const handleCustomEvent = (event: CustomEvent<CustomEventDetail>) => {
      setActiveKey(event.detail.tabKey);
    };

    document.addEventListener(
      "sideNavClick",
      handleCustomEvent as EventListener
    );

    return () => {
      document.removeEventListener(
        "sideNavClick",
        handleCustomEvent as EventListener
      )
    }
  }, [])

  useEffect(() => {
    dispatch(fetchTierInfo())
  }, [])

  useEffect(() => {
    if (location.pathname.includes("shop-floor")) {
      setPersona({
        name: "shopFloor",
        label: "Shop Floor",
      });
    } else {
      setPersona({
        name: "wct",
        label: "Work Center Team",
      });
    }
  }, [location]);

  useEffect(() => {
    setAppState({
      ...appState,
      site,
      persona: persona?.name ?? "",
      building,
    });
  }, [site, persona, building])

  const notificationCounts: Record<string, number> = {
    SQDIP: 0,
    "Tier Communications": 5,
    "Issue Tracking and Countermeasures": 3,
    Administrator: 0,
  };

  const items = wctNavigation.map((sideNavLabel, index) => {
    if (notificationCounts[sideNavLabel] > 0) {
      return {
        key: `tab-${index + 1}`,
        label: (
          <div className={wctCss.tabTitle}>
            {sideNavLabel}
            <Avatar
              className={wctCss.notificationNumber}
              style={{ backgroundColor: "black", verticalAlign: "middle" }}
              size="small"
            >
              {notificationCounts[sideNavLabel]}
            </Avatar>
          </div>
        ),
      };
    } else {
      return {
        key: `tab-${index + 1}`,
        label: <div className={wctCss.tabTitle}>{sideNavLabel}</div>,
      };
    }
  });

  const deepDiveData: Record<string, any | null> = {
    "SEE Cards": seeCardsDeepDiveInfo,
    "INCA Incidents": incaDeepDiveInfo,
    "Safety Critical Work Orders": workOrderDeepDiveInfo,
    "EHSS Training Compliance": ehssDeepDiveInfo,
    "Safety CAPAs": capaDeepDiveInfo,
  };

  const qualityDeepDiveData: Record<string, any | null> = {
    "GXP Training": gxpDeepDiveInfo,
    "Change Control": changeControlDeepDiveInfo,
    "Minor Deviation": minorDeviationDeepDiveInfo,
    "Major Deviation": majorDeviationDeepDiveInfo,
    "CAPA Status": qualityCapaDeepDiveInfo,
    'Right First Time': qualityDdRFTInfo
  };
  
  const deliveryDeepDiveData: Record<string, any | null> = {
    "% PM Adherence & Upcoming PMs": pmAdherenceDeepDiveInfo,
    "Calibration Closure Adherence": CalClosureAdherenceDeepDiveInfo,
    "Aging Work Orders": agingWoDeepDiveInfo,
    "Batch Disposition": batchDispositionDeepDiveInfo
  };

  const inventryDeepDiveData: Record<string, any | null> = {
    "Critical MFCs": briticalMfcDeepDiveInfo,
    "Kanban Healh": [],
  }

  const isPagelayoutRender = (!loading && !error)

  return (
    <div>
      {isPagelayoutRender ? <PageLayout className={relativeContainer}>
        <div className={wctContainer}>
          <div className={wctHeaderContainer} ref={headerRef}>
            <ConfigProvider theme={{ token: { fontSize: 12 } }}>
              <Breadcrumb
                className="breadcrumb sf-breadcrumb"
                items={[
                  {
                    title: <a href="/">Digital Control Tower</a>,
                  },
                  {
                    title: <span>Manufacturing</span>,
                    menu: { items: mfgItems },
                  },
                  {
                    title: <span>{site?.toUpperCase()}</span>,
                    menu: { items: siteItems },
                  },
                  {
                    title: <span>{persona.label}</span>,
                    menu: { items: personaItems },
                  },
                ]}
              />
            </ConfigProvider>
            {loading || error ? (
              <div className="shop-floor-loader">
                <ErrorHandler
                  isLoading={loading}
                  isError={error}
                  loaderColor={"BLUE"}
                />
              </div>
            ) : (
              <div>
                <Header
                  items={tierDropDownData}
                  handleMenuClick={handleTierClick}
                  title="TIER"
                  dropOptions={tierLabel}
                  activeKey={activeKey}
                />
                {tierLabel.toLowerCase() !== "tier 3" && activeKey !== "tab-5" && (
                  <div className={dropContainer}>
                    {tierLabel.toLowerCase() === "tier 0" && (
                      <AohDropdown
                        items={lineDropDownData}
                        title="LINE"
                        dropOptions={currentLine}
                        handleMenuClick={handleLineClick}
                      />
                    )}
                    {(tierLabel.toLowerCase() === "tier 0" ||
                      tierLabel.toLowerCase() === "tier 1") && (
                        <AohDropdown
                          items={shiftDropDownData}
                          title="SHIFT"
                          dropOptions={currentShift?.label}
                          handleMenuClick={handleShiftClick}
                        />
                      )}
                    {tierLabel.toLowerCase() === "tier 2" && (
                      <div className={wctCss.buildingContainer}>
                        <AohDropdown
                          items={buildingDropDownData}
                          title="BUILDING"
                          dropOptions={building}
                          handleMenuClick={handleBuildingClick}
                        />
                        <Popover
                          placement="bottomRight"
                          title={<span className={wctCss.showCCHeader}>APPLICABLE COST CENTERS</span>}
                          content={content}
                        >
                          <div className={wctCss.showCC}>SHOW CC</div>
                        </Popover>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

            <Tabs
              className={tabHeader}
              activeKey={activeKey}
              onChange={setActiveKey}
              defaultActiveKey={activeKey}
              items={items}
            />
          </div>
          <DeepdiveDrawer
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            deepDiveAlert={deepDiveAlert}
            tier={tierLabel}
            metrics={summaryMetric}
            id={summaryId}
          />
          {activeKey === "tab-5" && <OneClickReport />}
          {activeKey !== "tab-5" && <div
            className={notificationBar}
            ref={aiNotificationRef}
            id="sqdip-ai-insights-summary"
            style={{ marginTop: tierLabel.toLowerCase() === "tier 3" ? "236px" : "284px" }}
          >
            <MetricCard>
              <>
                <MetricCard.Header>
                <div className="d-flex align-items-center justify-content-between">
                        <h3
                          style={{
                            fontWeight: 700,
                            fontSize: "16px",
                          }}
                        >
                          WCT SQDIP Alerts
                        </h3>
                        <div className="d-flex">
                          <AIGeneratedIcon className={wctCss.aiSVGIcon} />
                          <div
                            className={wctCss.buttonText}
                            onClick={handleShowMoreClick}
                          >
                            {showMore ? "Show Less" : "Show More"}
                          </div>
                        </div>
                      </div>
                </MetricCard.Header>
                <MetricCard.Content>
                  {isBaseSummaryLoading ? (
                    <div className="summary-loader">
                      <Loader />
                    </div>
                  ) : (
                    <div
                      className="d-flex flex-column"
                      style={{ maxHeight: "238px", overflowY: "auto", width: "100%" }}
                    >
                      {summaryComponent()}
                    </div>
                  )}
                </MetricCard.Content>
              </>
            </MetricCard>
          </div>}
          {activeKey !== "tab-5" && <div className={wctContentContainer}>
            {/* Safety Base View  */}
            <MetricsContainer
              metrics={metrics ?? []}
              sqdipType={SQDIPType.Safety}
              metricNames={safetyMetricNames}
              calendarInfo={calendarInfo}
              incaInfo={incaInfo}
              seeCardsInfo={seeCardsInfo}
              workOrderInfo={workOrderInfo}
              ehssInfo={ehssInfo}
              capaInfo={capaInfo}
              setIsOpenDeepDive={setIsSafetyOpenDeepDive}
              setActiveKeyDeepDiveTab={setActiveSafetyKeyDeepDiveTab}
              tierLabel={tierLabel}
            />

            {/* Quality Base View  */}
            <MetricsContainer
              metrics={metrics ?? []}
              sqdipType={SQDIPType.Quality}
              metricNames={qualityMetricNames}
              calendarInfo={calendarInfo}
              minorDeviationInfo={minorDeviationInfo}
              majorDeviationInfo={majorDeviationInfo}
              gmpTrainingInfo={gmpTrainingInfo}
              qualityChangeControlInfo={qualityChangeControlInfo}
              qualityCAPAInfo={qualityCAPAInfo}
              qualityRFTInfo={qualityRFTInfo}
              setIsOpenDeepDive={setIsQualityOpenDeepDive}
              setActiveKeyDeepDiveTab={setActiveQualityKeyDeepDiveTab}
              tierLabel={tierLabel}
            />
            {/* Delivery Base View */}
            <MetricsContainer
              metrics={metrics ?? []}
              sqdipType={SQDIPType.Delivery}
              metricNames={deliveryMetricNames}
              calendarInfo={calendarInfo}
              agingWorkorderInfo={agingWorkorderInfo}
              pmAdherenceInfo={pmAdherenceInfo}
              deliveryCalibrationInfo={deliveryCalibrationInfo}
              batchDispoitionInfo={batchDispoitionInfo}
              setIsOpenDeepDive={setIsDeliveryOpenDeepDive}
              setActiveKeyDeepDiveTab={setActiveDeliveryKeyDeepDiveTab}
              OnTimeScheduleAdherenceBVInfo={OnTimeScheduleAdherenceBVInfo}
              tierLabel={tierLabel}
            />
            {/* Inventory Base View */}
            <MetricsContainer
              metrics={metrics ?? []}
              sqdipType={SQDIPType.Inventory}
              metricNames={inventoryMetricNames}
              calendarInfo={calendarInfo}
              turnOverInfo={turnOverInfo}
              criticalMfcInfo={criticalMfcInfo}
              kanbanHealthBVInfo={kanbanHealthBVInfo}
              setIsOpenDeepDive={setIsInventoryOpenDeepDive}
              setActiveKeyDeepDiveTab={setActiveInventoryKeyDeepDiveTab}
              tierLabel={tierLabel}
            />
            {/* Productivity Base View */}
            <MetricsContainer
              metrics={metrics ?? []}
              sqdipType={SQDIPType.Productivity}
              metricNames={productivityMetricNames}
              calendarInfo={calendarInfo}
              setIsOpenDeepDive={setIsProductivityOpenDeepDive}
              setActiveKeyDeepDiveTab={setActiveProductivityKeyDeepDiveTab}
              tierLabel={tierLabel}
            />
          </div >}

          <AohDeepDiveMain
            data={deepDiveData[activeSafetyKeyDeepDiveTab] ?? []}
            tierLabel={tierLabel}
            shift={currentShift?.label}
            line={currentLine}
            building={building}
            costCenter={currentCostCenter}
            sqdipTabType={SQDIPType.Safety}
            isOpen={isSafetyOpenDeepDive}
            isClose={() => {
              setIsSafetyOpenDeepDive(false);
              setActiveSafetyKeyDeepDiveTab(deepDiveTabItems.Safety[0]);
            }}
            activeKeyDeepDiveTab={activeSafetyKeyDeepDiveTab}
            setActiveKeyDeepDiveTab={(key: string) =>
              setActiveSafetyKeyDeepDiveTab(key)
            }
            deepdiveSummaryData={
              deepdiveSummaryInfo?.data?.[
              activeSafetyKeyDeepDiveTab?.toLowerCase().replace(/ /g, "_")
              ]
            }
            tabItems={deepDiveTabItems.Safety ?? []}
          />
          <AohDeepDiveMain
            data={qualityDeepDiveData[activeQualityKeyDeepDiveTab] ?? []}
            tierLabel={tierLabel}
            shift={currentShift?.label}
            line={currentLine}
            building={building}
            costCenter={currentCostCenter}
            sqdipTabType={SQDIPType.Quality}
            isOpen={isQualityOpenDeepDive}
            isClose={() => {
              setIsQualityOpenDeepDive(false);
              setActiveQualityKeyDeepDiveTab(deepDiveTabItems.Quality[0]);
            }}
            activeKeyDeepDiveTab={activeQualityKeyDeepDiveTab}
            setActiveKeyDeepDiveTab={(key: string) =>
              setActiveQualityKeyDeepDiveTab(key)
            }
            deepdiveSummaryData={
              deepdiveSummaryInfo?.data?.[
              activeQualityKeyDeepDiveTab?.toLowerCase().replace(/ /g, "_")
              ]
            }
            tabItems={deepDiveTabItems.Quality ?? []}
          />
          <AohDeepDiveMain
            data={deliveryDeepDiveData[activeDeliveryKeyDeepDiveTab] ?? []}
            tierLabel={tierLabel}
            shift={currentShift?.label}
            line={currentLine}
            building={building}
            costCenter={currentCostCenter}
            sqdipTabType={SQDIPType.Delivery}
            isOpen={isDeliveryOpenDeepDive}
            isClose={() => {
              setIsDeliveryOpenDeepDive(false);
              setActiveDeliveryKeyDeepDiveTab(deepDiveTabItems.Delivery[0]);
            }}
            activeKeyDeepDiveTab={activeDeliveryKeyDeepDiveTab}
            setActiveKeyDeepDiveTab={(key: string) =>
              setActiveDeliveryKeyDeepDiveTab(key)
            }
            deepdiveSummaryData={
              deepdiveSummaryInfo?.data?.[
              activeDeliveryKeyDeepDiveTab?.toLowerCase().replace(/ /g, "_")
              ]
            }
            tabItems={deepDiveTabItems.Delivery ?? []}
          />
          <AohDeepDiveMain
            data={inventryDeepDiveData[activeInventoryKeyDeepDiveTab] ?? []}
            tierLabel={tierLabel}
            shift={currentShift?.label}
            line={currentLine}
            building={building}
            costCenter={currentCostCenter}
            sqdipTabType={SQDIPType.Inventory}
            isOpen={isInventoryOpenDeepDive}
            isClose={() => {
              setIsInventoryOpenDeepDive(false);
              setActiveInventoryKeyDeepDiveTab(deepDiveTabItems.Inventory[0]);
            }}
            activeKeyDeepDiveTab={activeInventoryKeyDeepDiveTab}
            setActiveKeyDeepDiveTab={(key: string) =>
              setActiveInventoryKeyDeepDiveTab(key)
            }
            deepdiveSummaryData={
              deepdiveSummaryInfo?.data?.[
              activeInventoryKeyDeepDiveTab?.toLowerCase().replace(/ /g, "_")
              ]
            }
            tabItems={deepDiveTabItems.Inventory ?? []}
          />
        </div >
      </PageLayout > : <div className="page-layout-loader">
        <ErrorHandler
          isLoading={loading}
          isError={error}
          loaderColor={"BLUE"}
        />
      </div>}
    </div>
  )
}

export default WorkCenterTeam;
