import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOktaToken } from 'utils/common-methods';

interface FetchDataParams {
    tierNumber?: string | undefined
}

interface Shift {
    name: string;
}

interface CostCenter {
    name: string;
}
interface Shifts {
    key?: number;
    string?: string
}

interface LineInfoItem {
    building_name?: string;
    line_name?: string,
    shifts?: Shift[],
    costCenters?: CostCenter[];
}

interface LineInfoDataType {
    data?: LineInfoItem[];
}

interface TransformedLineItem {
    line?: string;
    shift?: Shifts[];
    buildingName?: string;
    costCenters?: { name: string }[];
}

interface TransformedData {
    lines?: TransformedLineItem[];
}

interface LineInfoState {
    lineInfo: TransformedData | null;
    loading: boolean;
    error: boolean;
}

const initialState: LineInfoState = {
    lineInfo: null,
    loading: false,
    error: false,
};

const fetchLineInfo = createAsyncThunk<LineInfoDataType, FetchDataParams>(
    'work-center-team/line-info',
    async (params) => {
        const { tierNumber } = params;
        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/work-center-team/line-info?tierNumber=${tierNumber}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch lines');
        }
        return await response.json();
    }
);

const mapItems = (items: CostCenter[] = []): { name: string }[] =>
    items.map((item) => ({
        name: item.name
    }));

const transformed = (shifts: Shift[] | undefined) => {
    const sortedShift = shifts?.sort((a: Shift, b: Shift) => {
        const numA = parseInt(a.name.split(' ')[1]);
        const numB = parseInt(b.name.split(' ')[1]);
        return numA - numB;
    });

    if (sortedShift) {
        const keyShift = sortedShift.map((item, index) => ({
            key: index,
            label: item.name
        }));
        return keyShift
    }
    return sortedShift
};

const transformData = (data: LineInfoDataType): TransformedData => ({
    lines: data?.data?.map((item) => ({
        line: item?.line_name,
        shift: transformed(item?.shifts),
        buildingName: item?.building_name,
        costCenters: mapItems(item?.costCenters),
    })),
});

const lineInfoSlice = createSlice({
    name: 'lineInfo',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLineInfo.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchLineInfo.fulfilled, (state, action) => {
                state.loading = false;
                
                const sortedPayload = action.payload?.data !== undefined && Object.keys(action.payload.data).length !== 0 ? action.payload?.data.sort((a, b) => {
                    if (a.line_name && b.line_name && a.line_name < b.line_name) {
                        return -1; // a comes before b
                    }
                    if (a.line_name && b.line_name && a.line_name < b.line_name) {
                        return 1; // a comes after b
                    }
                    return 0;
                }) : [];
                const load = { data: sortedPayload }
                state.lineInfo = Object.keys(load).length !== 0 ? transformData(load) : {};

            })
            .addCase(fetchLineInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });

    },
});

export { fetchLineInfo };

export default lineInfoSlice.reducer;