import { ConfigProvider, Radio } from 'antd';
import BlockFilterCss from './BlockRadioFilters.module.css'

export interface RadioFilter {
  label: string,
  value: string
}

interface RadioFiltersProps {
  filterLabel: string
  radioFilter: RadioFilter[];
  setRadioFilter: (value: string) => void;
}

export const BlockRadioFilters: React.FC<RadioFiltersProps> = ({
  filterLabel,
  radioFilter,
  setRadioFilter
}) => {

  const { customBlockFilter } = BlockFilterCss
  return (
    <div style={{ display: "flex", marginBottom: "10px", gap: "8px", alignItems: "center" }}>
      <div style={{ fontWeight: 700, fontSize: "10px", lineHeight: "14px", textAlign: "right" }}>
        {filterLabel}:
      </div>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#0063C3"
          },
        }}
      >
        <Radio.Group className={customBlockFilter} options={radioFilter} defaultValue={radioFilter?.[0].value} optionType="button"
          onChange={(e) => setRadioFilter(e.target.value)} />
      </ConfigProvider>
    </div>
  );
};
