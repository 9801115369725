import { useEffect, useRef, useState } from "react";
import "./index.scss";

interface percentDetail {
  percentage: number;
  flag: string;
  passedHour: string | number;
  totalHour: string | number;
}
interface ProgressType {
  percentageArray: percentDetail[];
}

const HorizontalProgressBar: React.FC<ProgressType> = ({ percentageArray }) => {
  const [boxWidth, setBoxWidth] = useState<number>(1);
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const currentWidth = containerRef.current?.clientWidth;
    setBoxWidth((prev) => currentWidth ?? prev);
  }, [containerRef]);
  return (
    <div className="horizontal-bar-container">
      <div ref={containerRef} className="horizontal-progress-container-no-round">
        {percentageArray.length > 0 && (
          <>
            <div className="horizontal-progress-bar-wrapper">
              {percentageArray.map((data: percentDetail, i: number) => (
                <div
                  key={i}
                  className={
                    data.flag === "RED"
                      ? "child-div-red-container"
                      : data.flag === "BLUE"
                        ? "child-div-blue-container"
                        : ""
                  }
                  style={{
                    width: `${data.totalHour}%`,
                  }}
                />
              ))}
            </div>
            <div className="horizontal-progress-labels">
              {percentageArray.map((data: percentDetail, i: number) => (
                <div
                  key={i}
                  className="dashed-line-container"
                  style={{
                    left: `${data.passedHour}%`,
                    transform: "translateX(-50%)",
                  }}
                >
                  <div className="progress-dashed-line" />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HorizontalProgressBar;
