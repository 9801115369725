import { clearIncaData, fetchInca } from "reducers/safety-base-view/incaReducer";
import { clearcapaData, fetchCapa } from "reducers/safety-base-view/capaReducer";
import { clearEhssData, fetchEhss } from "reducers/safety-base-view/ehssReducer";
import { clearSeeCardsData, fetchSeeCards } from "reducers/safety-base-view/seeCardsReducer";
import { clearMinorDeviationData, fetchMinorDeviation } from "reducers/quality-base-view/minorDeviation";
import { clearMajorDeviationData, fetchMajorDeviation } from "reducers/quality-base-view/majorDeviation";
import { clearGmpTrainingData, fetchGmpTraining } from "reducers/quality-base-view/gmpReducer";
import { AppDispatch } from "../store";
import { clearworkOrderData, fetchWorkOrder } from "reducers/safety-base-view/workOrderReducer";
import { fetchCapaDeepDive } from "reducers/safety-deep-dive/capaDeepDiveReducer";
import { clearSafetyIncaDeepDiveData, fetchIncaDeepDive } from "reducers/safety-deep-dive/incaDeepDiveReducer";
import { fetchWorkOrderDeepDive } from "reducers/safety-deep-dive/workOrderReducer";
import { fetchEhssDeepDive } from "reducers/safety-deep-dive/ehssReducer";
import { fetchSeeCardsDeepDive } from "reducers/safety-deep-dive/seeCardsDeepDiveReducer";
import { clearQualityCAPAData, fetchQualityCAPA } from "reducers/quality-base-view/qualityCAPAReducer";
import { clearQualityChangeControlData, fetchQualityChangeControl } from "reducers/quality-base-view/qualityChangeControlReducer";
import { clearQCCDD, fetchChangeControlDeepDive } from "reducers/quality-deep-dive/changeControlDeepDiveReducer";
import { clearQualityRFTData, fetchQualityRFT } from "reducers/quality-base-view/qualityRFTReducers";
import { clearQualityMajorDevData, fetchMajorDeviationDeepDive } from "reducers/quality-deep-dive/majorDevDeepDiveReducer";
import { clearQualityGXPData, fetchGxpDeepDive } from "reducers/quality-deep-dive/gxpReducer";
import { clearQualityMinorDevData, fetchMinorDeviationDeepDive } from "reducers/quality-deep-dive/minorDevDeepDiveReducer";
import { clearQCSDD, fetchCapaStatusDeepDive } from "reducers/quality-deep-dive/qualityCapaDeepDiveReducer";
import { clearAgingWorkorderData, fetchAgingWorkorder } from "reducers/delivery-base-view/agingWorkorder";
import { clearPmAdherenceData, fetchPmAdherence } from "reducers/delivery-base-view/pmAdherence";
import { clearDeliveryCalibrationData, fetchDeliveryCalibration } from "reducers/delivery-base-view/deliveryCalibrationReducers";
import { clearBatchDispoitionData, fetchBatchDispoition } from "reducers/delivery-base-view/batchDispositionReducer";
import { clearPmAdherenceDeepDiveData, fetchPmAdherenceDeepDive } from "reducers/delivery-deep-dive/pmAdherenceDeepDiveReducer";
import { clearOnTimeScheduleAdherenceBVData, fetchOnTimeScheduleAdherenceBV } from "reducers/delivery-base-view/onTimeScheduleAdherenceReducer";
import { clearAgingWoDeepDiveData, fetchAgingWoDeepDive } from "reducers/delivery-deep-dive/agingWoDeepDiveReducer";
import { clearCalClosureAdherenceDeepDiveData,fetchCalClosureAdherenceDeepDive } from "reducers/delivery-deep-dive/calClosureAdherenceDeepDiveReducer";
import { FetchDataParams } from "types/wctTypes";
import { fetchQualityDeepdiveRFT } from "reducers/quality-deep-dive/qualityRFTReducers";
import {clearTurnOverData, fetchTurnOver } from "reducers/inventory-base-view/turnOver";
import { clearCriticalMfcData, fetchCriticalMfc } from "reducers/inventory-base-view/criticalMfc";
import { clearKanBanHealthBVData, fetchKanBanHealthBV } from "reducers/inventory-base-view/kanbanHealthBVReducer";
import { clearBatchDispositionDeepDiveData, fetchBatchDispositionDeepDive } from "reducers/delivery-deep-dive/batchDispositionDeepDiveReducer";
import { clearCriticalMfcDeepDiveData, fetchCriticalMfcDeepDive } from "reducers/inventory-deep-dive/criticalMfc";

export const actionMap = {
  inca: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearIncaData())
    dispatch(clearSafetyIncaDeepDiveData())
    dispatch(fetchInca(params));
    dispatch(fetchIncaDeepDive(params));
  },
  capa: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearcapaData())
    dispatch(fetchCapa(params));
    dispatch(fetchCapaDeepDive(params));
  },
  ehss: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearEhssData())
    dispatch(fetchEhss(params));
    dispatch(fetchEhssDeepDive(params));
  },
  seecards: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearSeeCardsData())
    dispatch(fetchSeeCards(params));
    dispatch(fetchSeeCardsDeepDive(params));
  },
  workorder: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearworkOrderData())
    dispatch(fetchWorkOrder(params));
    dispatch(fetchWorkOrderDeepDive(params));
  },
  minordeviation: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearMinorDeviationData())
    dispatch(clearQualityMinorDevData())
    dispatch(fetchMinorDeviation(params))
    dispatch(fetchMinorDeviationDeepDive(params))
  },
  majordeviation: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearMajorDeviationData())
    dispatch(clearQualityMajorDevData())
    dispatch(fetchMajorDeviation(params))
    dispatch(fetchMajorDeviationDeepDive(params))
  },
  gxptraining: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearGmpTrainingData())
    dispatch(clearQualityGXPData())
    dispatch(fetchGmpTraining(params))
    dispatch(fetchGxpDeepDive(params))
  },
  changecontrol: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearQualityChangeControlData())
    dispatch(clearQCCDD())
    dispatch(fetchQualityChangeControl(params))
    dispatch(fetchChangeControlDeepDive(params));
  },
  capastatus: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearQualityCAPAData())
    dispatch(clearQCSDD())
    dispatch(fetchQualityCAPA(params))
    dispatch(fetchCapaStatusDeepDive(params))
  },
  rft: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearQualityRFTData())
    dispatch(fetchQualityRFT(params))
    dispatch(fetchQualityDeepdiveRFT(params))
  },
  agingworkorder: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearAgingWorkorderData())
    dispatch(clearAgingWoDeepDiveData())
    dispatch(fetchAgingWorkorder(params))
    dispatch(fetchAgingWoDeepDive(params))
  },
  pmadherence: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearPmAdherenceData())
    dispatch(clearPmAdherenceDeepDiveData())
    dispatch(fetchPmAdherence(params))
    dispatch(fetchPmAdherenceDeepDive(params))
  },
  calibrationClosure: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearDeliveryCalibrationData())
    dispatch(clearCalClosureAdherenceDeepDiveData())
    dispatch(fetchDeliveryCalibration(params))
    dispatch(fetchCalClosureAdherenceDeepDive(params))
  },
  batchDisposition: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearBatchDispoitionData())
    dispatch(fetchBatchDispoition(params))
  },
  onTimeScheduleAdherence: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearOnTimeScheduleAdherenceBVData())
    dispatch(fetchOnTimeScheduleAdherenceBV(params))
    dispatch(clearBatchDispositionDeepDiveData())
    // dispatch(clearAgingWoDeepDiveData())
    // dispatch(fetchAgingWorkorder(params))
    dispatch(fetchBatchDispositionDeepDive(params))
  },  
  turnOver: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearTurnOverData())
    dispatch(fetchTurnOver(params))
  },
  criticalMfc: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearCriticalMfcData())
    dispatch(fetchCriticalMfc(params))
    dispatch(fetchCriticalMfcDeepDive(params))
    dispatch(clearCriticalMfcDeepDiveData())
  }, 
  kanbanHealth: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearKanBanHealthBVData())
    dispatch(fetchKanBanHealthBV(params))
  }
}

/**
 * Checks if any key in an object has a non -empty . non-undifined value.
 * @param {Object} obj - the object to check
 * @returns {boolean}
 */
export const hasValidValues = (obj: Record<string, any>): boolean => {
  return Object.values(obj).some((val) => val !== "" && val !== undefined);
}