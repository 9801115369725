import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { FetchDataParams, GraphParamsType } from 'types/wctTypes'
import { filterParams, getMonthName, getMonthNameWithString, getOktaToken, setData, setDataKeys } from 'utils/common-methods'
import { QUALITY_DEEP_DIVE_RFT_URL } from 'utils/constants'
import { StringKeyAnyDataProps, StringKeyDataProps } from 'utils/data-types'

interface RFTType {
    card_data?: StringKeyDataProps
    deviations_over_target?: StringKeyDataProps[]
    pending_exception?: StringKeyDataProps[]
    mtd_graph_data?: StringKeyDataProps[]
    trend_graph_data?: StringKeyDataProps[]
    graph_params?: StringKeyAnyDataProps
}

interface RFTDataType {
    data: any[],
    tableData: any[],
}

interface CardProps {
    alertType: "error" | "success"
}
interface RFTInfoStateType {
    qualityDdRFTInfo: any;
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    error: boolean
}

const initialState: RFTInfoStateType = {
    qualityDdRFTInfo: null,
    status: 'idle',
    error: false,
}

const fetchQualityDeepdiveRFT = createAsyncThunk<RFTType, FetchDataParams>(
    QUALITY_DEEP_DIVE_RFT_URL,
    async (params) => {
        const filteredParams = filterParams(params)

        const token: string = getOktaToken()
        const response = await fetch(`${process.env.REACT_APP_API_URL}${QUALITY_DEEP_DIVE_RFT_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })

        if (!response.ok) {
            throw new Error('Failed to fetch RFT')
        }
        return await response.json()
    }
)

const transformData = (RFT: any, tierNumberValue: string = ''): RFTDataType => {
    if (tierNumberValue.toLowerCase() === 'tier 1') {
        const modData = Array.isArray(RFT) ? RFT.reduce((prevData, currData) => {
            if (currData.isBarChart) {
                if (Array.isArray(currData.data)) {
                    const graphModData = currData.data.map((item: any) => {
                        return {
                            name: item.line,
                            value: item.number_of_pending_exception,
                            avg_pending_exception: item.avg_pending_exception
                        }
                    })
                    currData.data = {
                        avgPendingException: graphModData?.[0]?.avg_pending_exception || 0,
                        graphAllData: graphModData,
                    };
                }
                prevData.data = currData.data;
            } else if (currData.isTableAllLineFilter) {
                prevData.tableData.allLineFilterData = currData?.data || [];
            } else if (currData.isTableAll) {
                prevData.tableData.All = currData?.data || [];
            }

            return prevData;
        }, {
            data: {},
            tableData: {
                allLineFilterData: [],
                All: [],
            },
        }) : [];
        const allTableData = modData.tableData.All || [];
        const lineWiseData = allTableData.reduce((prevData: any, currData: any) => {
            const line = currData.line;
            if (!prevData[line]) {
                prevData[line] = [];
            }
            prevData[line].push(currData);
            return prevData;
        }, {});

        if (lineWiseData) {
            const tableData = {
                ...modData.tableData,
                ...lineWiseData
            };

            modData.tableData = tableData;
        }

        return {
            tableData: modData.tableData,
            data: modData.data,
        };
    }

    if (tierNumberValue.toLowerCase() === 'tier 2') {
        const modData = Array.isArray(RFT) ? RFT.reduce((prevData, currData) => {
            if (currData.isCard) {
                prevData.summary = currData?.data?.[0] || {};
            } else if (currData.isBarChart) {
                if (currData.pendingExceptions) {
                    const pendingExceptionsData = (currData.data || [])?.map((item: any) => {
                        return {
                            name: `${item.line}`,
                            value: item.number_of_pending_exception
                        }
                    });
                    prevData.barGraphData.pendingExceptions = pendingExceptionsData || [];
                };

                if (currData.batchDeviations) {
                    const batchDeviationsData = (currData.data || [])?.map((item: any) => {
                        return {
                            name: `${item.line}`,
                            value: item.number_of_deviations
                        }
                    });
                    prevData.barGraphData.batchDeviations = batchDeviationsData || [];
                };
            } else if (currData.isLineChart) {
                const lineAvgData = (currData.data || [])?.map((item: any) => {
                    return {
                        name: item.date,
                        value: item.avg
                    }
                });
                const lineOpenExceptData = (currData.data || [])?.map((item: any) => {
                    return {
                        name: item.date,
                        value: item.open_lines_excep_count
                    }
                });
                prevData.lineGraphData.avg = lineAvgData || [];
                prevData.lineGraphData.lines = lineOpenExceptData || [];
            } else if (currData.isTableAll) {
                prevData.tableDataAll = currData?.data || [];
            } else if (currData.isTablePending) {
                prevData.tableDataPending = currData?.data || [];
            } else if (currData.isTableAcknowledge) {
                prevData.tableDataAcknowledge = currData?.data || [];
            } else {
                prevData.data = currData;
            }
            return prevData;
        }, {
            summary: {},
            barGraphData: {
                pendingExceptions: [],
                batchDeviations: [],
            },
            lineGraphData: {
                avg: [],
                lines: [],
            },
            tableDataAll: [],
            tableDataPending: [],
            tableDataAcknowledge: [],
        }) : [];
        return modData;
    }

    if (tierNumberValue.toLowerCase() === 'tier 3') {
        const modData = Array.isArray(RFT) ? RFT.reduce((prevData, currData) => {
            if (currData.isCard) {
                prevData.summary = currData?.data?.[0] || {};
            } else if (currData.isExceptionsLineMtd) {
                const exceptionGraphDataMtdAll = (currData.data || [])?.map((item: any) => {
                    let {
                        max_exp: maxMtd = 0,
                        mtd_date: mtdDate = '',
                        min_exp: minMtd = 0,
                        avg_excep: avgExcepPerBatchMtd = 0
                    } = item;
                    minMtd = minMtd === null ? 0 : minMtd;
                    maxMtd = maxMtd === null ? 0 : maxMtd;
                    avgExcepPerBatchMtd = avgExcepPerBatchMtd === null ? 0 : avgExcepPerBatchMtd;
                    mtdDate = mtdDate === null ? '' : mtdDate;
                    return {
                        name: mtdDate,
                        dateValue: getMonthNameWithString(mtdDate),
                        minMax: [minMtd, maxMtd],
                        lineValue: avgExcepPerBatchMtd,
                    }
                });
                prevData.exceptionGraphData.mtdAll = exceptionGraphDataMtdAll || [];
            } else if (currData.isExceptionsLineMtdLine) {
                const exceptionGraphDataMtdLine = (currData.data || [])?.map((item: any) => {
                    let {
                        max_exp: maxLineMtd = 0,
                        line = 0,
                        min_exp: minLineMtd = 0,
                        mtd_date: mtdDate = '',
                        avg_excep: avgExcepPerBatchLineMtd = 0
                    } = item;
                    minLineMtd = minLineMtd === null ? 0 : minLineMtd;
                    maxLineMtd = maxLineMtd === null ? 0 : maxLineMtd;
                    avgExcepPerBatchLineMtd = avgExcepPerBatchLineMtd === null ? 0 : avgExcepPerBatchLineMtd;
                    line = line === null ? 0 : line;
                    return {
                        name: line,
                        dateValue: getMonthNameWithString(mtdDate),
                        minMax: [minLineMtd, maxLineMtd],
                        lineValue: avgExcepPerBatchLineMtd,
                    }
                });
                prevData.exceptionGraphData.mtdLine = exceptionGraphDataMtdLine || [];
            } else if (currData.isExceptionsLine6m) {
                const exceptionGraphDataSixMonthAll = (currData.data || [])?.map((item: any) => {
                    let {
                        max_exp: max6m = 0,
                        min_exp: min6m = 0,
                        avg_excep: avgExcepPerBatch6m = 0
                    } = item;
                    min6m = min6m === null ? 0 : min6m;
                    max6m = max6m === null ? 0 : max6m;
                    avgExcepPerBatch6m = avgExcepPerBatch6m === null ? 0 : avgExcepPerBatch6m;
                    return {
                        name: `${item.year}-${item.month}`,
                        dateValue: getMonthName(item.month),
                        minMax: [min6m, max6m],
                        lineValue: avgExcepPerBatch6m,
                    }
                });
                prevData.exceptionGraphData.sixMonthsAll = exceptionGraphDataSixMonthAll || [];
            } else if (currData.isExceptionsLine6mLine) {
                const exceptionGraphDataSixMonthLine = (currData.data || [])?.map((item: any) => {
                    let {
                        max_exp: maxLine6m = 0,
                        line = 0,
                        min_exp: minLine6m = 0,
                        avg_excep: avgExcepPerBatchLine6m = 0
                    } = item;
                    minLine6m = minLine6m === null ? 0 : minLine6m;
                    maxLine6m = maxLine6m === null ? 0 : maxLine6m;
                    avgExcepPerBatchLine6m = avgExcepPerBatchLine6m === null ? 0 : avgExcepPerBatchLine6m;
                    line = line === null ? 0 : line;
                    return {
                        name: line,
                        dateValue: getMonthName(item.month),
                        minMax: [minLine6m, maxLine6m],
                        lineValue: avgExcepPerBatchLine6m,
                    }
                });
                prevData.exceptionGraphData.sixMonthsLine = exceptionGraphDataSixMonthLine || [];
            } else if (currData.isDeviationsMtd) {
                const deviationGraphDataMtdAll = (currData.data || [])?.map((item: any) => {
                    let {
                        max_devi: maxMtd = 0,
                        mtd_date: mtdDate = '',
                        min_devi: minMtd = 0,
                        avg_devi: avgDeviPerBatchMtd = 0
                    } = item;
                    minMtd = minMtd === null ? 0 : minMtd;
                    maxMtd = maxMtd === null ? 0 : maxMtd;
                    avgDeviPerBatchMtd = avgDeviPerBatchMtd === null ? 0 : avgDeviPerBatchMtd;
                    mtdDate = mtdDate === null ? '' : mtdDate;
                    return {
                        name: mtdDate,
                        dateValue: getMonthNameWithString(mtdDate),
                        minMax: [minMtd, maxMtd],
                        lineValue: avgDeviPerBatchMtd || 0,
                    }
                });
                prevData.deviationGraphData.mtdAll = deviationGraphDataMtdAll || [];
            } else if (currData.isDeviationsMtdLine) {
                const deviationGraphDataMtdLine = (currData.data || [])?.map((item: any) => {
                    let {
                        max_devi: maxLineMtd = 0,
                        line = 0,
                        mtd_date: mtdDate = '',
                        min_devi: minLineMtd = 0,
                        avg_devi: avgDeviPerBatchLineMtd = 0
                    } = item;
                    minLineMtd = minLineMtd === null ? 0 : minLineMtd;
                    maxLineMtd = maxLineMtd === null ? 0 : maxLineMtd;
                    avgDeviPerBatchLineMtd = avgDeviPerBatchLineMtd === null ? 0 : avgDeviPerBatchLineMtd;
                    line = line === null ? 0 : line;
                    return {
                        name: line,
                        dateValue: getMonthNameWithString(mtdDate),
                        minMax: [minLineMtd, maxLineMtd],
                        lineValue: avgDeviPerBatchLineMtd,
                    }
                });
                prevData.deviationGraphData.mtdLine = deviationGraphDataMtdLine || [];
            } else if (currData.isDeviations6m) {
                const deviationGraphDataSixMonthAll = (currData.data || [])?.map((item: any) => {
                    let {
                        max_devi: max6m = 0,
                        min_devi: min6m = 0,
                        avg_devi: avgDeviPerBatch6m = 0
                    } = item;
                    min6m = min6m === null ? 0 : min6m;
                    max6m = max6m === null ? 0 : max6m;
                    avgDeviPerBatch6m = avgDeviPerBatch6m === null ? 0 : avgDeviPerBatch6m;
                    return {
                        name: `${item.year}-${item.month}`,
                        minMax: [min6m, max6m],
                        dateValue: getMonthName(item.month),
                        lineValue: avgDeviPerBatch6m,
                    }
                });
                prevData.deviationGraphData.sixMonthsAll = deviationGraphDataSixMonthAll || [];
            } else if (currData.isDeviations6mLine) {
                const deviationGraphDataSixMonthLine = (currData.data || [])?.map((item: any) => {
                    let {
                        max_devi: maxLine6m = 0,
                        line = 0,
                        min_devi: minLine6m = 0,
                        avg_devi: avgDeviPerBatchLine6m = 0
                    } = item;
                    minLine6m = minLine6m === null ? 0 : minLine6m;
                    maxLine6m = maxLine6m === null ? 0 : maxLine6m;
                    avgDeviPerBatchLine6m = avgDeviPerBatchLine6m === null ? 0 : avgDeviPerBatchLine6m;
                    line = line === null ? 0 : line;
                    return {
                        name: line,
                        dateValue: getMonthName(item.month),
                        minMax: [minLine6m, maxLine6m],
                        lineValue: avgDeviPerBatchLine6m,
                    }
                });
                prevData.deviationGraphData.sixMonthsLine = deviationGraphDataSixMonthLine || [];
            } else {
                prevData.data = currData;
            }
            return prevData;
        }, {
            summary: {},
            exceptionGraphData: {
                mtdAll: [],
                mtdLine: [],
                sixMonthsAll: [],
                sixMonthsLine: [],
            },
            deviationGraphData: {
                mtdAll: [],
                mtdLine: [],
                sixMonthsAll: [],
                sixMonthsLine: [],
            },
        }) : [];
        return modData;
    }

    return RFT;
}

const qualityDdRFTSlice = createSlice({
    name: 'qualityDeepdiveRFT',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchQualityDeepdiveRFT.pending, (state) => {
                state.status = 'loading'
                state.error = false
            })
            .addCase(fetchQualityDeepdiveRFT.fulfilled, (state, action: PayloadAction<RFTType>) => {
                state.status = 'succeeded'
                state.qualityDdRFTInfo = transformData(action.payload, ((action as any)?.meta?.arg?.tierNumber) ?? '')
            })
            .addCase(fetchQualityDeepdiveRFT.rejected, (state) => {
                state.status = 'failed'
                state.error = true
            })
    }
})

export { fetchQualityDeepdiveRFT }

export default qualityDdRFTSlice.reducer