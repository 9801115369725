import React from "react";
import wctCss from "./../../../page/workCenterTeam.module.css";
import AIGeneratedIcon from "assets/icons/AIGeneratedIcon";
import { Button, MetricCard } from "@gitlab-rtsensing/component-library";
import DeepdiveDrawer from "components/common/drawer/DeepdiveDrawer";

interface AiGeneratedComponentProps {
  summary: any;
  tierLabel: any;
}

export const AiGeneratedComponent: React.FC<AiGeneratedComponentProps> = ({
  summary,
  tierLabel,
}) => {
  const { deepDiveAIContainer, sqdipBtn } = wctCss;
  const aiNotificationRef = React.useRef<HTMLDivElement>(null);
  const paragraphRef = React.useRef<HTMLParagraphElement>(null);
  const [showMore, setShowMore] = React.useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [deepDiveAlert, setDeepDiveAlert] = React.useState("");
  const handleShowMoreClick = () => {
    setShowMore(!showMore);
    if (paragraphRef.current) {
      paragraphRef.current.scrollTop = 0; // Scroll to the top
    }
  };

  const summaryComponent = () => {
    const loopData = !showMore ? summary?.slice(0, 1) : summary;
    return (
      <div>
        {loopData?.map((item: any, index: any) => (
          <div
            className="d-flex align-items-center justify-content-between"
            style={{
              fontSize: "12px",
              marginLeft: "5px",
              marginRight: "5px",
              marginTop: index === 0 ? "0px" : "5px",
              paddingTop: index === 0 ? "0px" : "5px",
              borderTop: index === 0 ? "none" : "1px solid #e3e3e3",
            }}
            key={index}
          >
            {item?.metric && <div
              style={{
                width: "70px",
                fontWeight: 700,
                fontSize: "16px",
              }}
            >
              {`${item?.metric?.charAt(0).toUpperCase()}${item?.metric
                ?.slice(1)
                ?.toLowerCase()}`}
            </div>}
            <div
              style={{ width: "85%" }}
              dangerouslySetInnerHTML={{ __html: item?.summary }}
            ></div>
            {item?.metric && <Button
              className={sqdipBtn}
              label="Deep Dive"
              type="secondary"
              onClick={() => {
                setIsDrawerOpen(true);
                setDeepDiveAlert(item?.summary);
              }}
            />}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className={deepDiveAIContainer}
      ref={aiNotificationRef}
      id="sqdip-ai-insights-summary"
    >
      <DeepdiveDrawer
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        deepDiveAlert={deepDiveAlert}
        tier={tierLabel}
        metrics={summary ? summary[0]?.metric?.toLowerCase() : ""}
        id={summary ? summary[0]?.id : ""}
      />
      <MetricCard>
        <>
          <MetricCard.Header>
            <div className="d-flex align-items-center justify-content-between">
              <AIGeneratedIcon className={wctCss.aiSVGIcon} />
              <div className={wctCss.buttonText} onClick={handleShowMoreClick}>
                {showMore ? "Show Less" : "Show More"}
              </div>
            </div>
          </MetricCard.Header>
          <MetricCard.Content>
            <div
              className="d-flex flex-column"
              style={{ maxHeight: "238px", overflowY: "auto", width: "100%" }}
            >
              {summaryComponent()}
            </div>
          </MetricCard.Content>
        </>
      </MetricCard>
    </div>
  );
};
