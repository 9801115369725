import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BaseViewCardPropsType, FetchDataParams, QueryParamsPropsType } from 'types/wctTypes';
import { filterParams, getMonthName, getOktaToken } from 'utils/common-methods';
import { TURN_OVER_URL } from 'utils/constants'
import { StringKeyDataProps } from 'utils/data-types';

interface GraphDataType {
    month?: number | string;
    year?: number | string;
    Inventory_Turnovers?: number | string;
}

interface GraphType {
    isGraph: true;
    data: GraphDataType[];
    query_params: QueryParamsPropsType;
}

type CombinedDataType = (GraphType)[];

interface turnOverInfoState {
    turnOverInfo: BaseViewCardPropsType | null;
    loading: boolean;
    error: boolean;
}

const initialState: turnOverInfoState = {
    turnOverInfo: null,
    loading: false,
    error: false
}

const fetchTurnOver = createAsyncThunk<CombinedDataType, FetchDataParams>(
    TURN_OVER_URL,
    async (params) => {
        const filteredParams = filterParams(params)
        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}${TURN_OVER_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })
        if (!response.ok) {
            throw new Error('Failed to fetch Turn Over')
        }
        return await response.json()
    }
)

const isGraphType = (item: GraphType): item is GraphType => {
    return !!item.isGraph;
};
const turnOverConvertData = (data: GraphDataType[]): StringKeyDataProps[] => {
    const sortedData = [...data].sort((a, b) => {
        const monthA = typeof a.month === "number" ? a.month : Number(a.month);
        const monthB = typeof b.month === "number" ? b.month : Number(b.month);
        return monthA - monthB;
    });
    return sortedData.map((item) => {
        const monthNumber = typeof item.month === "number" ? item.month : Number(item.month);
        const value = Math.round(
            typeof item.Inventory_Turnovers === "string"
                ? parseFloat(item.Inventory_Turnovers)
                : 0
        );

        const monthName = getMonthName(monthNumber - 1);
        return { name: monthName, value };
    });
};

const transformData = (turnOverInfo: CombinedDataType): BaseViewCardPropsType => {
    const turnOverBaseViewData: BaseViewCardPropsType = {
        cardData: {},
        graphData: [],
        graphParams: {},
        cardProps: {
            alertType: 'success'
        }
    }

    const tierLabel = turnOverInfo?.[0].query_params?.tierLabel

    const TurnOverSanitizedData: StringKeyDataProps = {};

    turnOverBaseViewData.graphParams.tooltipContent = "Inventory Turnovers for the previous 5 Months"

    if (
        tierLabel?.toLowerCase() === "tier 3" &&
        turnOverInfo?.[0]?.data?.length &&
        isGraphType(turnOverInfo?.[0])
    ) {
        const inputData1: GraphDataType[] = turnOverInfo?.[0]?.data;
        const outputData: StringKeyDataProps[] = turnOverConvertData(inputData1);
        turnOverBaseViewData.graphData = outputData;
        turnOverBaseViewData.graphParams.isGraph = true;
    } else {
        turnOverBaseViewData.graphData = [];
        turnOverBaseViewData.graphParams.isGraph = false;
    }

    return turnOverBaseViewData;
}

const turnOverReducer = createSlice({
    name: 'turnOver',
    initialState,
    reducers: {
        clearTurnOverData(state) {
            state.turnOverInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTurnOver.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchTurnOver.fulfilled, (state, action) => {
                state.loading = false;
                state.turnOverInfo = transformData(action.payload)
            })
            .addCase(fetchTurnOver.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export { fetchTurnOver };

export const { clearTurnOverData } = turnOverReducer.actions

export default turnOverReducer.reducer;