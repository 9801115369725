import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { APIResponseDataType, FetchDataParams } from 'types/wctTypes';
import { filterParams, getOktaToken } from 'utils/common-methods';
import { CRITICAL_MFC_DEEP_DIVE_URL } from 'utils/constants';

interface CriticalMfcDeepDiveStateProps {
    briticalMfcDeepDiveInfo: APIResponseDataType[] | null;
    loading: boolean;
    error: boolean;
}

const initialState: CriticalMfcDeepDiveStateProps = {
    briticalMfcDeepDiveInfo: null,
    loading: false,
    error: false
};

const fetchCriticalMfcDeepDive = createAsyncThunk<APIResponseDataType[], FetchDataParams>(
    CRITICAL_MFC_DEEP_DIVE_URL,
    async (params) => {
        const filteredParams = filterParams(params);
        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}${CRITICAL_MFC_DEEP_DIVE_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch PM Adherence');
        }
        return await response.json();
    }
);

const briticalMfcDeepDiveReducer = createSlice({
    name: 'briticalMfcDeepDive',
    initialState,
    reducers: {
        clearCriticalMfcDeepDiveData(state) {
            state.briticalMfcDeepDiveInfo = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCriticalMfcDeepDive.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchCriticalMfcDeepDive.fulfilled, (state, action) => {
                state.loading = false;
                state.briticalMfcDeepDiveInfo = action.payload;
            })
            .addCase(fetchCriticalMfcDeepDive.rejected, (state) => {
                state.loading = false;
                state.error = true;
            })
    }
});

export const { clearCriticalMfcDeepDiveData } = briticalMfcDeepDiveReducer.actions;
export { fetchCriticalMfcDeepDive };
export default briticalMfcDeepDiveReducer.reducer;
