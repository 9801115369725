import { type MenuProps, type TableColumnsType } from "antd";
import TableLabel from "../components/resuable/label/tableLabel";
import TooltipWrapper from "./TooltipWrapper";
import { ascendDescend } from "./common-methods";

export interface LandingStateInterface {
  label: string;
  subLabel: string;
  sideNavLabel: string;
  site: string;
  route: string;
  authorized: boolean;
}

export const availableApps: LandingStateInterface[] = [
  {
    label: "AOH",
    subLabel: "Amgen Ohio",
    sideNavLabel: "Amgen Ohio (AOH)",
    site: "aoh",
    route: "aoh",
    authorized: false,
  },
  {
    label: "ABR",
    subLabel: "Amgen Breda",
    sideNavLabel: "Amgen Breda (ABR)",
    site: "abr",
    route: "abr",
    authorized: false,
  },
  {
    label: "ANC",
    subLabel: "Amgen North Carolina",
    sideNavLabel: "Amgen North Carolina (ANC)",
    site: "anc",
    route: "anc",
    authorized: false,
  },
  {
    label: "ARI",
    subLabel: "Amgen Rhode Island",
    sideNavLabel: "Amgen Rhode Island (ARI)",
    site: "ari",
    route: "ari",
    authorized: false,
  },
  {
    label: "AML",
    subLabel: "Amgen Puerto Rico",
    sideNavLabel: "Amgen Puerto Rico (AML)",
    site: "aml",
    route: "aml",
    authorized: false,
  },
  {
    label: "ADL",
    subLabel: "Amgen Dun Laoghaire",
    sideNavLabel: "Amgen Dun Laoghaire (ADL)",
    site: "adl",
    route: "adl",
    authorized: false,
  },
  {
    label: "ASM",
    subLabel: "Amgen Singapore Manufacturing",
    sideNavLabel: "Amgen Singapore Manufacturing (ASM)",
    site: "asm",
    route: "asm",
    authorized: false,
  },
  {
    label: "ATO",
    subLabel: "Amgen Thousand Oaks",
    sideNavLabel: "Amgen Thousand Oaks (ATO)",
    site: "ato",
    route: "ato",
    authorized: false,
  },
];

export const personas = [
  {
    name: "shopFloor",
    label: "Shop Floor",
    subLabel: "Shop Floor",
    url: "shop-floor/all-lines",
    id: "shop-floor-container",
  },
  {
    name: "wct",
    label: "Work Center Team",
    subLabel: "WCT",
    url: "work-center-team",
    id: "work-center-team-container",
  },
  {
    name: "eTOC",
    label: "Electronic Table of Contents (eTOC)",
    subLabel: "ETOC",
    url: "etoc",
    id: "etoc-container",
  },
];

export const permissionObject: Record<string, boolean> = {
  aoh: false,
  abr: false,
  anc: false,
  ari: false,
  aml: false,
  adl: false,
  asm: false,
  ato: false,
};

export const tierItems: MenuProps["items"] = [
  {
    label: "Tier 0",
    key: "0",
  },
  {
    label: "Tier 1",
    key: "1",
  },
  {
    label: "Tier 2",
    key: "2",
  },
  {
    label: "Tier 3",
    key: "3",
  },
];

export const shiftItems: MenuProps["items"] = [
  {
    label: "Shift 0",
    key: "0",
  },
  {
    label: "Shift 1",
    key: "1",
  },
  {
    label: "Shift 2",
    key: "2",
  },
  {
    label: "Shift 3",
    key: "3",
  },
];

export const managerItems: MenuProps["items"] = [
  {
    label: "Manager 0",
    key: "0",
  },
  {
    label: "Manager 1",
    key: "1",
  },
  {
    label: "Manager 2",
    key: "2",
  },
  {
    label: "Manager 3",
    key: "3",
  },
];

export const costCenterItems: MenuProps["items"] = [
  {
    label: "All Cost Centers",
    key: "0",
  },
  {
    label: "Cost Center 1",
    key: "1",
  },
  {
    label: "Cost Center 2",
    key: "2",
  },
  {
    label: "Cost Center 3",
    key: "3",
  },
];

export const tierMapping: Record<number, string> = {
  0: "Tier 0",
  1: "Tier 1",
  2: "Tier 2",
  3: "Tier 3",
};

export const wctNavigation: string[] = [
  "SQDIP",
  "Tier Communications",
  "Issue Tracking and Countermeasures",
  "Administrator",
  "WCT Report",
];

export const wctSafetyDeepdiveNavigation: string[] = [
  "SEE Cards",
  "INCA Incidents",
  "Safety Critical Work Orders",
  "EHSS Training Compliance",
  "Safety CAPAs"
]

export enum wctQualityDeepdiveNavigation {
  RightFirstTime = "Right First Time",
  MajorDeviation = "Major Deviation",
  MinorDeviation = "Minor Deviation",
  GXPTraining = "GXP Training",
  CAPAStatus = "CAPA Status",
  ChangeControl = "Change Control"
}

export enum wctDeliveryDeepdiveNavigation {
  "PMAdherence&UpcomingPMs" = "% PM Adherence & Upcoming PMs",
  "AgingWorkOrders" = "Aging Work Orders",
  "CalibrationClosureAdherence" = "Calibration Closure Adherence",
  "BatchDisposition" = "Batch Disposition"
}

export enum wctInventoryDeepdiveNavigation {
  criticalMfc = "Critical MFCs",
  kanbanHealth = "Kanban Health",
}

export enum wctProductivityDeepdiveNavigation {
  sample = "Productivity",
}

export enum SQDIPType {
  Safety = "Safety",
  Quality = "Quality",
  Delivery = "Delivery",
  Inventory = "Inventory",
  Productivity = "Productivity"
}

interface SummaryData {
  summary: number;
  description: string;
}

interface TierData {
  workorder: SummaryData[];
  ehss?: SummaryData[];
  inca?: SummaryData[];
  seeCards?: SummaryData[];
  capa?: SummaryData[];
}

interface SummarySafetyDeepDiveData {
  tier0: TierData;
  tier1: TierData;
  tier2: TierData;
  tier3: TierData;
}

export const summarySafetyDeepDiveData: SummarySafetyDeepDiveData = {
  tier0: {
    workorder: [
      {
        summary: 55,
        description: "Incidents in last 24 Hours",
      },
      {
        summary: 5,
        description: "Overdue",
      },
      {
        summary: 17,
        description: "Coming Due in next 5 Days",
      },
    ],
  },
  tier1: {
    workorder: [
      {
        summary: 55,
        description: "Safety Critical Work Orders in last 24 Hours",
      },
    ],
    ehss: [
      {
        summary: 55,
        description: "Upcoming Due in next 5 Days",
      },
    ],
    inca: [
      {
        summary: 55,
        description: "Incidents in last 24 Hours",
      },
      {
        summary: 5,
        description: "Overdue",
      },
      {
        summary: 17,
        description: "Coming Due in next 5 Days",
      },
    ],
    seeCards: [
      {
        summary: 55,
        description: "SEE Cards in last 24 Hours",
      },
      {
        summary: 5,
        description: "Overdue",
      },
      {
        summary: 17,
        description: "Coming Due in 5 Days",
      },
    ],
    capa: [
      {
        summary: 5,
        description: "Overdue",
      },
      {
        summary: 17,
        description: "Upcoming Due in 5 Days",
      },
    ],
  },
  tier2: {
    workorder: [
      {
        summary: 55,
        description: "Safety Critical Work Orders in last 24 Hours",
      },
      {
        summary: 5,
        description: "Overdue",
      },
    ],
    ehss: [
      {
        summary: 55,
        description: "Upcoming Due in 2 Days",
      },
    ],
    inca: [
      {
        summary: 55,
        description: "Incidents",
      },
      {
        summary: 5,
        description: "Overdue",
      },
      {
        summary: 17,
        description: "Coming Due in 2 Days",
      },
    ],
    seeCards: [
      {
        summary: 55,
        description: "SEE Cards Today",
      },
      {
        summary: 5,
        description: "Overdue",
      },
      {
        summary: 17,
        description: "Coming Due in 2 Days",
      },
    ],
    capa: [
      {
        summary: 5,
        description: "Overdue",
      },
      {
        summary: 55,
        description: "Upcoming Due in 2 Days",
      },
    ],
  },
  tier3: {
    workorder: [
      {
        summary: 55,
        description: "Safety Critical Work Orders in last 24 Hours",
      },
    ],
    ehss: [
      {
        summary: 55,
        description: "% compliance for AOH",
      },
    ],
    inca: [
      {
        summary: 55,
        description: "Incidents",
      },
      {
        summary: 5,
        description: "Overdue",
      },
      {
        summary: 17,
        description: "Coming Due in next 2 Days",
      },
    ],
    capa: [
      {
        summary: 5,
        description: "Overdue",
      },
    ],
  },
};

interface SummaryQualityData {
  summary: number;
  description: string;
}

interface TierQualityData {
  minorDeviation: SummaryQualityData[];
  majorDeviation?: SummaryQualityData[];
}

interface SummaryQualityDeepDiveData {
  tier0: TierQualityData;
  tier1: TierQualityData;
  tier2: TierQualityData;
  tier3: TierQualityData;
}

interface DataType {
  key: React.Key;
  issueDate: string;
  issue: string;
  owner: string;
  shortDescription: string;
  type: string;
  ehssProgramArea: string;
  behaviourObservationType: string;
  overdue?: number | null;
}

export interface WorkOrderDataType {
  issued_last_24_hours: number;
  overdue: number | null;
  work_order_number: string;
  work_order_type: string;
  classification: string;
  short_desc: string;
}

interface IncaDataType {
  issue_date: number | string;
  priority: string;
  owner: string;
  incident_type: number | string;
  safety_pyramid_level: string;
  short_description: string;
  status: string;
  overdue: number | string | null;
}

export const columns: TableColumnsType<DataType> = [
  {
    title: "ISSUE DATE",
    dataIndex: "issueDate",
    sorter: (a, b) => {
      return ascendDescend(a.issueDate, b.issueDate);
    },
  },
  {
    title: "ISSUE",
    dataIndex: "issue",
    sorter: (a, b) => {
      return ascendDescend(a.issue, b.issue);
    },
  },
  {
    title: "OWNER",
    dataIndex: "owner",
    sorter: (a, b) => {
      return ascendDescend(a.owner, b.owner);
    },
  },
  {
    title: "SHORT DESCRIPTION",
    dataIndex: "shortDescription",
    sorter: (a, b) => {
      return ascendDescend(a.shortDescription, b.shortDescription);
    },
  },
  {
    title: "TYPE",
    dataIndex: "type",
    sorter: (a, b) => {
      return ascendDescend(a.type, b.type);
    },
  },
  {
    title: "EHSS PROGRAM AREA",
    dataIndex: "ehssProgramArea",
  },
  {
    title: "BEHAVIOUR OBSERVATION TYPE",
    dataIndex: "behaviourObservationType",
  },
];

export const columnsWithLabels: TableColumnsType<DataType> = [
  {
    title: "ISSUE DATE",
    dataIndex: "issueDate",
    sorter: (a, b) => {
      return ascendDescend(a.issueDate, b.issueDate);
    },
    render: (text, record) => (
      <TableLabel
        StatusValue={text}
        circleColor={
          record.overdue === 1
            ? "#E01029"
            : record.overdue === 0
              ? "#D9B500"
              : "transparent"
        }
      />
    ),
  },
  {
    title: "ISSUE",
    dataIndex: "issue",
    sorter: (a, b) => {
      return ascendDescend(a.issue, b.issue);
    },
  },
  {
    title: "OWNER",
    dataIndex: "owner",
    sorter: (a, b) => {
      return ascendDescend(a.owner, b.owner);
    },
  },
  {
    title: "SHORT DESCRIPTION",
    dataIndex: "shortDescription",
    sorter: (a, b) => {
      return ascendDescend(a.shortDescription, b.shortDescription);
    },
  },
  {
    title: "TYPE",
    dataIndex: "type",
    sorter: (a, b) => {
      return ascendDescend(a.type, b.type);
    },
  },
  {
    title: "EHSS PROGRAM AREA",
    dataIndex: "ehssProgramArea",
  },
  {
    title: "BEHAVIOUR OBSERVATION TYPE",
    dataIndex: "behaviourObservationType",
  },
];

export const incaColumns: TableColumnsType<IncaDataType> = [
  {
    title: "ISSUE DATE",
    dataIndex: ["issue_date"],
    sorter: (a, b) => ascendDescend(a.issue_date, b.issue_date),
    width: "160.5px",
    render: (text, record) => (
      <TableLabel
        StatusValue={text}
        circleColor={
          record.overdue === 1 || record.overdue === "overdue"
            ? "#E01029"
            : record.overdue === 0 || record.overdue === "coming_due"
              ? "#D9B500"
              : "transparent"
        }
      />
    ),
  },
  {
    title: "PRIORITY",
    dataIndex: "priority",
    sorter: (a, b) => ascendDescend(a.priority, b.priority),
  },
  {
    title: "OWNER",
    dataIndex: "owner",
    sorter: (a, b) => ascendDescend(a.owner, b.owner),
  },
  {
    title: "TYPE",
    dataIndex: "incident_type",
    sorter: (a, b) => ascendDescend(a.incident_type, b.incident_type),
  },
  {
    title: "SAFETY PYRAMID",
    dataIndex: "safety_pyramid_level",
    sorter: (a, b) =>
      ascendDescend(a.safety_pyramid_level, b.safety_pyramid_level),
  },
  {
    title: "SHORT DESCRIPTION",
    dataIndex: "short_description",
    sorter: (a, b) => ascendDescend(a.short_description, b.short_description),
  },
  {
    title: "STATUS",
    dataIndex: "status",
    sorter: (a, b) => ascendDescend(a.status, b.status),
  },
];

interface IncaGlobalEhssType {
  date: string;
  amgen_site: string;
  incident_type: string;
  short_description: string;
  action_taken: string;
}

export const incaGlobalEhssColumns: TableColumnsType<IncaGlobalEhssType> = [
  {
    title: "ISSUE DATE",
    dataIndex: "date",
    sorter: (a, b) => {
      return ascendDescend(a.date, b.date);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "AMGEN SITE",
    dataIndex: "amgen_site",
    sorter: (a, b) => {
      return ascendDescend(a.amgen_site, b.amgen_site);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "INCIDENT TYPE",
    dataIndex: "incident_type",
    sorter: (a, b) => {
      return ascendDescend(a.incident_type, b.incident_type);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "SHORT DESCRIPTION",
    dataIndex: "short_description",
    sorter: (a, b) => {
      return ascendDescend(a.short_description, b.short_description);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "ACTION TAKEN",
    dataIndex: "action_taken",
    sorter: (a, b) => {
      return ascendDescend(a.action_taken, b.action_taken);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
];

interface seeCardColumnType {
  date: string;
  record_id: string;
  manager_to_respond: string;
  short_description: string;
  ehss_program_area: string;
  see_bop_type_of_observation: string;
  safety_pyramid_level: string;
}

export const seecardColumns: TableColumnsType<seeCardColumnType> = [
  {
    title: "ISSUE DATE",
    dataIndex: "date",
    sorter: (a, b) => {
      return ascendDescend(a.date, b.date);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "ISSUE ID",
    dataIndex: "record_id",
    sorter: (a, b) => {
      return ascendDescend(a.record_id, b.record_id);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "OWNER",
    dataIndex: "manager_to_respond",
    sorter: (a, b) => {
      return ascendDescend(a.record_id, b.record_id);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "SHORT DESCRIPTION",
    dataIndex: "short_description",
    sorter: (a, b) => {
      return ascendDescend(a.short_description, b.short_description);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "TYPE",
    dataIndex: "safety_pyramid_level",
    sorter: (a, b) => {
      return ascendDescend(a.safety_pyramid_level, b.safety_pyramid_level);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "EHSS PROGRAM AREA",
    dataIndex: "ehss_program_area",
    sorter: (a, b) => {
      return ascendDescend(a.ehss_program_area, b.ehss_program_area);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "BEHAVIOUR OBSERVATION TYPE",
    dataIndex: "see_bop_type_of_observation",
    sorter: (a, b) => {
      return ascendDescend(
        a.see_bop_type_of_observation,
        b.see_bop_type_of_observation
      );
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
];

export const workOrderColumns: TableColumnsType<WorkOrderDataType> = [
  {
    title: "WO NUMBER",
    dataIndex: ["work_order_number"],
    sorter: (a, b) => ascendDescend(a.work_order_number, b.work_order_number),
    width: "170.5px",
    render: (text, record) => (
      <TableLabel
        StatusValue={text}
        circleColor={
          record.overdue === 1
            ? "#E01029"
            : record.overdue === 0
              ? "#D9B500"
              : "transparent"
        }
      />
    ),
  },
  {
    title: "WORK ORDER TYPE",
    dataIndex: "work_order_type",
    width: "200.5px",
    sorter: (a, b) => ascendDescend(a.work_order_type, b.work_order_type),
  },
  {
    title: "CLASSIFICATION",
    dataIndex: "classification",
    sorter: (a, b) => ascendDescend(a.classification, b.classification),
  },
  {
    title: "SHORT DESCRIPTION",
    dataIndex: "short_desc",
    sorter: (a, b) => ascendDescend(a.short_desc, b.short_desc),
  },
];

export const ehssColumns: TableColumnsType<any> = [
  {
    title: "DUE DATE",
    dataIndex: "due_date",
    sorter: (a, b) => {
      return ascendDescend(a.due_date, b.due_date);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "TASK NAME",
    dataIndex: "task_name",
    sorter: (a, b) => {
      return ascendDescend(a.task_name, b.task_name);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "TASK CODE",
    dataIndex: "task_code",
    sorter: (a, b) => {
      return ascendDescend(a.task_code, b.task_code);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
];

export const capaColumns: TableColumnsType<any> = [
  {
    title: "RECORD ID",
    dataIndex: "record_id",
    sorter: (a, b) => {
      return ascendDescend(a.record_id, b.record_id);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "CAPA TYPE",
    dataIndex: "capa_type",
    sorter: (a, b) => {
      return ascendDescend(a.capa_type, b.capa_type);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "CAPA SOURCE",
    dataIndex: "capa_source",
    sorter: (a, b) => {
      return ascendDescend(a.capa_source, b.capa_source);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "STATUS",
    dataIndex: "status",
    sorter: (a, b) => {
      return ascendDescend(a.status, b.status);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "CAPA DUE DATE",
    dataIndex: "capa_due_date",
    sorter: (a, b) => {
      return ascendDescend(a.capa_due_date, b.capa_due_date);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "CAPA DESCRIPTION",
    dataIndex: "capa_description",
    sorter: (a, b) => {
      return ascendDescend(a.capa_description, b.capa_description);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "PRIORITY",
    dataIndex: "priority",
    sorter: (a, b) => {
      return ascendDescend(a.priority, b.priority);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "FUNCTIONAL GROUP",
    dataIndex: "functional_group",
    sorter: (a, b) => {
      return ascendDescend(a.functional_group, b.functional_group);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "ASSIGNED TO",
    dataIndex: "assigned_to",
    sorter: (a, b) => {
      return ascendDescend(a.assigned_to, b.assigned_to);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
]

export const customCss: Record<string, number> = {
  defaultMarginTop: 240,
  tier3MarginTop: 192,
};

export type Alert = "Red" | "Yellow" | "Green";

export const verticalBar = [
  { name: "28/09", a: 5, b: 10, c: 15, d: 10 },
  { name: "29/09", a: 5, b: 20, c: 10, d: 5 },
  { name: "30/09", a: 5, b: 10, c: 10, d: 10 },
  { name: "31/09", a: 5, b: 10, c: 10, d: 20 },
  { name: "01/10", a: 5, b: 10 },
];

export const wctMetrics: string[] = [
  "Safety",
  "Quality",
  "Delivery",
  "Inventory",
  "Productivity",
];

export interface MetricNames {
  ehss?: string;
  inca?: string;
  workorder?: string;
  seeCards?: string;
  capa?: string;
  minorDeviation?: string;
  majorDeviation?: string;
  gxpTraining?: string;
  changecontrol?: string;
  capastatus?: string;
  rft?: string
  agingWorkOrders?: string;
  pmAdherence?: string;
  onTimeScheduleAdherence?: string;
  batchDisposition?: string
  calClosureAdherence?: string
  inventoryTurnOver?:string
  criticalMfc?: string
  kanbanHealth?: string
}

export const safetyMetricNames: MetricNames = {
  ehss: "ehss training compliance",
  inca: "inca incidents",
  workorder: "safety critical work orders",
  seeCards: "see cards",
  capa: "safety capas"
}

export const qualityMetricNames: MetricNames = {
  minorDeviation: "Minor Deviation",
  majorDeviation: "Major Deviation",
  gxpTraining: "GXP Training",
  changecontrol: "change control",
  capastatus: "capa status",
  rft: "Right First Time",
};

export const deliveryMetricNames: MetricNames = {
  agingWorkOrders: "Aging Work Orders",
  pmAdherence: "% PM Adherence & Upcoming PMs",
  batchDisposition: "Batch Disposition",
  calClosureAdherence: "Calibration Closure Adherence",
  onTimeScheduleAdherence: "On Time Schedule Adherence"
}

export const inventoryMetricNames: MetricNames = {
  inventoryTurnOver: "Inventory Turnovers",
  criticalMfc: "Critical MFCs",
  kanbanHealth: "Kanban Health"
}

export const productivityMetricNames: MetricNames = {
}

export const calClosureDummy: any =
{
  summary: [
    {
      over_due_calibration: 10,
      percentage_closure_adherence_this_week: 80,
    }
  ],
  graphData: [
    { Level: "LEVEL 1", Adherence: 20 },
    { Level: "LEVEL 2", Adherence: 35 },
    { Level: "LEVEL 3", Adherence: 50 }
  ],
}

// URLs
export const WCT_URL = "work-center-team/tier-info";

// safety base view
export const CALENDAR_URL =
  "/work-center-team/metrics/safety/base-view/calendar";
export const EHSS_URL =
  "/work-center-team/metrics/safety/base-view/ehss";
export const WORK_ORDER_URL =
  "/work-center-team/metrics/safety/base-view/work-orders";
export const INCA_URL =
  "/work-center-team/metrics/safety/base-view/inca";
export const CAPA_URL =
  "/work-center-team/metrics/safety/base-view/capa";
export const SEE_CARDS_URL =
  "/work-center-team/metrics/safety/base-view/see-cards";

// safety deep dive
export const INCA_DEEP_DIVE_URL =
  "/work-center-team/metrics/safety/deep-dive-view/inca";

  export const INCA_TIME_PERIOD_DEEP_DIVE_URL =
  "/work-center-team/metrics/safety/deep-dive-view/inca/incidents-time-period";
  
  export const INCA_HBC_TIME_PERIOD_DEEP_DIVE_URL =
  "/work-center-team/metrics/safety/deep-dive-view/inca/incidents-hbc-time-period";

export const WORK_ORDER_DEEP_DIVE_URL =
  "/work-center-team/metrics/safety/deep-dive-view/work-orders";
// quality base view
export const MINOR_DEVIATION_URL =
  "work-center-team/metrics/quality/base-view/minor-deviation"

export const MAJOR_DEVIATION_URL =
  "/work-center-team/metrics/quality/base-view/major-deviation"

export const GXP_TRAINING_URL =
  "/work-center-team/metrics/quality/base-view/gxp-training";

export const QUALITY_CHANGE_CONTROL_URL = '/work-center-team/metrics/quality/base-view/change-control'

export const QUALITY_CAPA_URL = '/work-center-team/metrics/quality/base-view/capa'

export const QUALITY_RFT_URL = '/work-center-team/metrics/quality/base-view/right-first-time'

// quality deep dive view 
export const GXP_DEEP_DIVE_URL = "work-center-team/metrics/quality/deep-dive-view/gxp-training"

export const MINOR_DEVIATION_DEEP_DIVE_URL = "/work-center-team/metrics/quality/deep-dive-view/minor-deviation"

export const MAJOR_DEVIATION_DEEP_DIVE_URL = "/work-center-team/metrics/quality/deep-dive-view/major-deviation"

export const CHANGE_CONTROL_DEEP_DIVE_URL = "/work-center-team/metrics/quality/deep-dive-view/change-control"

export const QUALITY_DEEP_DIVE_RFT_URL = '/work-center-team/metrics/quality/deep-dive-view/right-first-time'

export const CAPA_DEEP_DIVE_URL = "/work-center-team/metrics/quality/deep-dive-view/capa"

// Delivery Base view 
export const AGING_WORKORDER_URL = "/work-center-team/metrics/delivery/base-view/aging-work-order"

export const PM_ADHERENCE_URL = "/work-center-team/metrics/delivery/base-view/percentage-pm-adherence"

export const DELIVERY_CALIBRATION_URL = '/work-center-team/metrics/delivery/base-view/calibration'

export const BATCH_DISPOSIOTION_URL = '/work-center-team/metrics/delivery/base-view/batch-disposition'

export const  ON_TIME_SCHEDULE_AD_URL = '/work-center-team/metrics/delivery/base-view/on-time-schedule-adherence'
// Delivery Deep Dive view
export const AGING_WO_DEEP_DIVE_URL = "/work-center-team/metrics/delivery/deep-dive-view/aging-work-order"

export const AGING_WO_VIEW_BY_URL = "/work-center-team/metrics/delivery/deep-dive-view/aging-wo-view-by"

export const PM_ADHERENCE_DEEP_DIVE_URL = "/work-center-team/metrics/delivery/deep-dive-view/preventive-maintenance"

export const CAL_CLOSURE_ADHERENCE_DEEP_DIVE_URL = "/work-center-team/metrics/delivery/deep-dive-view/calibration"

export const BATCH_DISPOSIOTION_DEEP_DIVE_URL = "/work-center-team/metrics/delivery/deep-dive-view/batch-disposition"

// Inventory Base View 
export const TURN_OVER_URL = '/work-center-team/metrics/inventory/base-view/turn-over'

export const CRITICAL_MFC_URL = '/work-center-team/metrics/inventory/base-view/critical-mfc'
export const KANBAN_HEALTH_URL = '/work-center-team/metrics/inventory/base-view/kanban-health'

// Inventory Deep Dive View 
export const CRITICAL_MFC_DEEP_DIVE_URL = '/work-center-team/metrics/inventory/deep-dive-view/critical-mfc'

export const deepDiveStackedInterval = {
  "Last Week": "6 days",
  "Last Month": "29 days",
  "Last 3 Months": "2 months",
}

export const dummyDataForBatchDisposition = [
  {
    summary: {
      percentage_batch_dispostion: 0,
      avgBatchDltMtd: 4,
      overdueBatchDispositioned: 3,
      brrDueVsOpenBatchesV: "7/10",
      brrDueIn7DaysValue: 7, // Count
    }
  },
  {
    data: [
      {
        batch_disposition: 95.52447552447553,
        Month: 10
      },
      {
        batch_disposition: 85.23644752018454,
        Month: 8
      },
      {
        batch_disposition: 94.5273631840796,
        Month: 9
      },
    ]
  }
]

export const dummyAgingData = [
  { date: '2023-01-01', openWorkOrders: 10, closedWorkOrders: 5 },
  { date: '2023-02-01', openWorkOrders: 15, closedWorkOrders: 10 },
  { date: '2023-03-01', openWorkOrders: 20, closedWorkOrders: 15 },
  { date: '2023-04-01', openWorkOrders: 25, closedWorkOrders: 20 },
  { date: '2023-05-01', openWorkOrders: 30, closedWorkOrders: 25 },
];

export const dummyDataWOClosureAdherence = [
  { date: '2023-01-01', woClosureAdherence: 75 },
  { date: '2023-02-01', woClosureAdherence: 80 },
  { date: '2023-03-01', woClosureAdherence: 85 },
  { date: '2023-04-01', woClosureAdherence: 90 },
  { date: '2023-05-01', woClosureAdherence: 95 },
];

export const dummyData = [
  {
    name: "LEVEL1",
    value: 5
  },
  {
    name: "LEVEL2",
    value: 4
  },
  {
    name: "LEVEL3",
    value: 8
  }
]