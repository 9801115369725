import "./Delivery.scss";

interface DeliveryTier0Props {
  data: any;
  tierLabel: string;
  activeKeyDeepDiveTab: string;
}

const DeliveryTier0: React.FC<DeliveryTier0Props> = ({
  data,
  tierLabel,
  activeKeyDeepDiveTab,
}) => {
  const deliveryDeepDive: any = {
    
  }
  
  return (
    <div className="delivery-tier-main">
      <div className="tier-container">
        <h2 className="tier-title">
          {/* Summary card */}
          {deliveryDeepDive[activeKeyDeepDiveTab]?.summaryTitle}
        </h2>
      </div>
      {deliveryDeepDive[activeKeyDeepDiveTab]?.summary}
      <div
        className={
          deliveryDeepDive[activeKeyDeepDiveTab]?.summary ? "mr-bottom-20" : ""
        }
      ></div>
      <div className="tier-container">
        <h2 className="tier-title">
          {deliveryDeepDive[activeKeyDeepDiveTab]?.title}
        </h2>
        {deliveryDeepDive[activeKeyDeepDiveTab]?.button}
      </div>
      <div className="tier-subtitle">
        {deliveryDeepDive[activeKeyDeepDiveTab]?.subtitle}
      </div>
      {deliveryDeepDive[activeKeyDeepDiveTab]?.table}
    </div>
  );
};
export default DeliveryTier0;
