import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { APIResponseDataType, FetchDataParams } from 'types/wctTypes';
import { filterParams, getOktaToken } from 'utils/common-methods';
import { ON_TIME_SCHEDULE_AD_URL } from 'utils/constants'

interface OnTimeScheduleAdherenceInfoState {
    OnTimeScheduleAdherenceBVInfo: APIResponseDataType[] | null;
    loading: boolean;
    error: boolean;
}

const initialState: OnTimeScheduleAdherenceInfoState = {
    OnTimeScheduleAdherenceBVInfo: null,
    loading: false,
    error: false
}

const fetchOnTimeScheduleAdherenceBV = createAsyncThunk<APIResponseDataType[], FetchDataParams>(
    ON_TIME_SCHEDULE_AD_URL,
    async (params) => {
        const filteredParams = filterParams(params)
        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}${ON_TIME_SCHEDULE_AD_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })
        if (!response.ok) {
            throw new Error('Failed to fetch On Time Schedule Adherence')
        }
        return await response.json()
    }
)

const OnTimeScheduleAdherenceBVReducer = createSlice({
    name: 'OnTimeScheduleAdherenceBV',
    initialState,
    reducers: {
        clearOnTimeScheduleAdherenceBVData(state) {
            state.OnTimeScheduleAdherenceBVInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOnTimeScheduleAdherenceBV.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchOnTimeScheduleAdherenceBV.fulfilled, (state, action) => {
                state.loading = false;
                state.OnTimeScheduleAdherenceBVInfo = action.payload;
            })
            .addCase(fetchOnTimeScheduleAdherenceBV.rejected, (state) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export { fetchOnTimeScheduleAdherenceBV };

export const { clearOnTimeScheduleAdherenceBVData } = OnTimeScheduleAdherenceBVReducer.actions

export default OnTimeScheduleAdherenceBVReducer.reducer;