import React from 'react';
import Label from "components/resuable/label";

interface LegendProps {
  statusValue: string;
  circleColor: string;
}

interface LegendItemsProps {
  legendItems: LegendProps[];
}

const Legend: React.FC<LegendItemsProps> = ({ legendItems }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "-40px auto 16px auto"
        }}
      >
        {legendItems.map((item, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "16px",
            }}
          >
            <Label
              StatusValue={item.statusValue}
              circleColor={item.circleColor}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Legend;