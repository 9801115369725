import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BaseViewCardPropsType, FetchDataParams, QueryParamsPropsType } from 'types/wctTypes';
import { filterParams, getMonthName, getOktaToken } from 'utils/common-methods';
import { AGING_WORKORDER_URL } from 'utils/constants'
import { StringKeyDataProps } from 'utils/data-types';

interface CardDataType {
    overdue_cm?: number | string;
    upcoming_due_in_24_hours_cm?: number | string;
    open_wo_non_cms_3_days?: number | string;
    overdue_wos?: number | string;
    upcoming_due_in_7_days?: number | string;
    percentage_wo_closure_adherence_last_month?: number | string;
}

interface GraphDataType {
    week?: number | string;
    weeks_1st_day?: number | string;
    pre_closure_over_weeks?: number | string;
    month?: number | string;
    year?: number | string;
    pre_adherence_6m?: number | string;
}

interface CardType {
    isCard: true;
    data: CardDataType[];
    query_params: QueryParamsPropsType;
}

interface GraphType {
    isGraph: true;
    data: GraphDataType[];
    query_params: QueryParamsPropsType;
}

type CombinedDataType = (CardType | GraphType)[];

interface agingWorkorderInfoState {
    agingWorkorderInfo: BaseViewCardPropsType | null;
    loading: boolean;
    error: boolean;
}

const initialState: agingWorkorderInfoState = {
    agingWorkorderInfo: null,
    loading: false,
    error: false
}

const fetchAgingWorkorder = createAsyncThunk<CombinedDataType, FetchDataParams>(
    AGING_WORKORDER_URL,
    async (params) => {
        const filteredParams = filterParams(params)
        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}${AGING_WORKORDER_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })
        if (!response.ok) {
            throw new Error('Failed to fetch Aging Work Order')
        }
        return await response.json()
    }
)

const isCardType = (item: CardType | GraphType): item is CardType => {
    return !!(item as CardType).isCard;
};

const isGraphType = (item: CardType | GraphType): item is GraphType => {
    return !!(item as GraphType).isGraph;
};

const agingWorkorderConvertData = (data: GraphDataType[]): StringKeyDataProps[] => {

    const sortedData = [...data].sort((a, b) => {
        const dateA = new Date(a.weeks_1st_day as string);
        const dateB = new Date(b.weeks_1st_day as string);
        return dateA.getTime() - dateB.getTime();
    });
    return sortedData.map((item) => {
        const date = new Date(item.weeks_1st_day as string);
        const monthName = getMonthName(date.getMonth());
        const formattedDate = `${monthName} ${date.getDate().toString().padStart(2, "0")}`;
        const value =
            typeof item.pre_closure_over_weeks === "number"
                ? item.pre_closure_over_weeks.toFixed(2)
                : "0.00";

        return { name: formattedDate, value: parseFloat(value) };
    });
};

const agingWorkorderConvertMtdData = (data: GraphDataType[]): StringKeyDataProps[] => {

    const sortedData = [...data].sort((a, b) => {
        if (typeof a.month === "number" && typeof b.month === "number") {
            return a.month - b.month;
        } else {
            return Number(a.month) - Number(b.month);
        }
    });
    return sortedData.map((item) => {
        const monthNumber =
            typeof item.month === "number" ? item.month : Number(item.month);
        const value =
            typeof item.pre_adherence_6m === "number"
                ? item.pre_adherence_6m.toFixed(2)
                : "0.00";

        const monthName = getMonthName(monthNumber - 1);
        return { name: monthName, value: parseFloat(value) };
    });
}

const transformData = (agingWorkorderInfo: CombinedDataType): BaseViewCardPropsType => {
    const agingWorkorderBaseViewData: BaseViewCardPropsType = {
        cardData: {},
        graphData: [],
        graphParams: {},
        cardProps: {
            alertType: 'success'
        }
    }

    const tierLabel = agingWorkorderInfo?.[0].query_params?.tierLabel

    const AgingWorkorderSanitizedData: StringKeyDataProps = {};
    let overdueCm;
    let upcomingDueIn24HoursCm;
    let openWoNonCms3Days;
    let overdueWos;
    let upcomingDueIn7Days;
    let percentageWoClosureAdherenceLastMonth;
    if (agingWorkorderInfo?.[0]?.data?.length && isCardType(agingWorkorderInfo[0])) {
        overdueCm = agingWorkorderInfo?.[0]?.data[0]?.overdue_cm;
        upcomingDueIn24HoursCm = agingWorkorderInfo?.[0]?.data[0]?.upcoming_due_in_24_hours_cm;
        openWoNonCms3Days = agingWorkorderInfo?.[0]?.data[0]?.open_wo_non_cms_3_days;
        overdueWos = agingWorkorderInfo?.[0]?.data[0]?.overdue_wos;
        upcomingDueIn7Days = agingWorkorderInfo?.[0]?.data[0]?.upcoming_due_in_7_days;
        percentageWoClosureAdherenceLastMonth = agingWorkorderInfo?.[0].data[0]?.percentage_wo_closure_adherence_last_month;
    }

    if (typeof overdueCm === "number" && overdueCm >= 0) {
        AgingWorkorderSanitizedData["OVERDUE (CM)"] = overdueCm;
    }
    if (typeof upcomingDueIn24HoursCm === "number" && upcomingDueIn24HoursCm >= 0) {
        AgingWorkorderSanitizedData["UPCOMING DUE IN 24 HRS (CM)"] = upcomingDueIn24HoursCm;
    }
    if (typeof openWoNonCms3Days === "number" && openWoNonCms3Days >= 0) {
        AgingWorkorderSanitizedData["OPEN WORK ORDERS (NON CMS) 3 DAYS"] = openWoNonCms3Days;
    }
    if (typeof overdueWos === "number" && overdueWos >= 0) {
        AgingWorkorderSanitizedData["OVERDUE WOS"] = overdueWos;
    }
    if (typeof upcomingDueIn7Days === "number" && upcomingDueIn7Days >= 0) {
        AgingWorkorderSanitizedData["UPCOMING DUE IN 7 DAYS"] = upcomingDueIn7Days;
    }
    if (typeof percentageWoClosureAdherenceLastMonth === "number" && percentageWoClosureAdherenceLastMonth >= 0) {
        AgingWorkorderSanitizedData["% WO CLOSURE ADHERENCE LAST MONTH"] = percentageWoClosureAdherenceLastMonth;
    }
    agingWorkorderBaseViewData.cardData = AgingWorkorderSanitizedData

    if (tierLabel?.toLowerCase() === "tier 2") {
        agingWorkorderBaseViewData.graphParams.tooltipContent = "% WO Closure Adherence for last 1 month (Weekly data points)"
    } else {
        agingWorkorderBaseViewData.graphParams.tooltipContent = "% WO Closure Adherence Month Over Month for last 6 Months (Monthly data points)"
    }
// code commentde don't remove to further uses
    // if (
    //     tierLabel?.toLowerCase() === "tier 1" &&
    //     typeof overdueCm === "number" &&
    //     overdueCm > 0
    // ) {
    //     agingWorkorderBaseViewData.cardProps.alertType = "error"
    // } else if (
    //     (tierLabel?.toLowerCase() === "tier 2" || tierLabel?.toLowerCase() === "tier 3") &&
    //     typeof overdueWos === "number" &&
    //     overdueWos > 0
    // ) {
    //     agingWorkorderBaseViewData.cardProps.alertType = "error"
    // } else {
    //     agingWorkorderBaseViewData.cardProps.alertType = "success"
    // }

    if (
        tierLabel?.toLowerCase() === "tier 2" &&
        agingWorkorderInfo?.[1]?.data?.length &&
        isGraphType(agingWorkorderInfo?.[1])
    ) {
        const inputData1: GraphDataType[] = agingWorkorderInfo?.[1]?.data;
        const outputData: StringKeyDataProps[] = agingWorkorderConvertData(inputData1);
        agingWorkorderBaseViewData.graphData = outputData;
        agingWorkorderBaseViewData.graphParams.isGraph = true;
    } else if (
        tierLabel?.toLowerCase() === "tier 3" &&
        agingWorkorderInfo?.[1]?.data?.length &&
        isGraphType(agingWorkorderInfo?.[1])
    ) {
        const inputData1: GraphDataType[] = agingWorkorderInfo?.[1]?.data;
        const outputData: StringKeyDataProps[] = agingWorkorderConvertMtdData(inputData1);
        agingWorkorderBaseViewData.graphData = outputData;
        agingWorkorderBaseViewData.graphParams.isGraph = true;
    } else {
        agingWorkorderBaseViewData.graphData = [];
        agingWorkorderBaseViewData.graphParams.isGraph = false;
    }

    return agingWorkorderBaseViewData;
}

const agingWorkorderReducer = createSlice({
    name: 'agingWorkorder',
    initialState,
    reducers: {
        clearAgingWorkorderData(state) {
            state.agingWorkorderInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAgingWorkorder.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchAgingWorkorder.fulfilled, (state, action) => {
                state.loading = false;
                state.agingWorkorderInfo = transformData(action.payload)
            })
            .addCase(fetchAgingWorkorder.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export { fetchAgingWorkorder };

export const { clearAgingWorkorderData } = agingWorkorderReducer.actions

export default agingWorkorderReducer.reducer;