import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { BaseViewCardPropsType, FetchDataParams } from 'types/wctTypes'
import { filterParams, getOktaToken, setData } from 'utils/common-methods'
import { QUALITY_CAPA_URL } from 'utils/constants'
import { StringKeyDataProps } from 'utils/data-types'

interface CAPAType {
    data: StringKeyDataProps
}

interface CAPAInfoStateType {
    qualityCAPAInfo: BaseViewCardPropsType | null
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    error: boolean
}

const initialState: CAPAInfoStateType = {
    qualityCAPAInfo: null,
    status: 'idle',
    error: false,
}

const fetchQualityCAPA = createAsyncThunk<CAPAType, FetchDataParams>(
    'work-center-team/metrics/quality/base-view/capa',
    async (params) => {
        const filteredParams = filterParams(params)

        const token: string = getOktaToken()
        const response = await fetch(`${process.env.REACT_APP_API_URL}${QUALITY_CAPA_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })

        if (!response.ok) {
            throw new Error('Failed to fetch capa')
        }
        return await response.json()
    }
)

const transformData = (capa: CAPAType): BaseViewCardPropsType => {
    const qualityCAPAInfo: BaseViewCardPropsType = {
        cardData: {},
        cardProps: {
            alertType: 'success'
        },
        graphData: [],
        graphParams: {}
    }

    qualityCAPAInfo.cardData = setData(capa.data)

    const { data: { overdue } } = capa
// code commentde don't remove to further uses

    // if (capa?.data && overdue && overdue as number > 0) {
    //     qualityCAPAInfo.cardProps.alertType = 'error'
    // }

    return qualityCAPAInfo
}

const qualityCAPASlice = createSlice({
    name: 'qualityCAPA',
    initialState,
    reducers: {
        clearQualityCAPAData(state) {
            state.qualityCAPAInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchQualityCAPA.pending, (state) => {
                state.status = 'loading'
                state.error = false
            })
            .addCase(fetchQualityCAPA.fulfilled, (state, action: PayloadAction<CAPAType>) => {
                state.status = 'succeeded'
                state.qualityCAPAInfo = transformData(action.payload)
            })
            .addCase(fetchQualityCAPA.rejected, (state) => {
                state.status = 'failed'
                state.error = true
            })
    }
})

export { fetchQualityCAPA }

export const { clearQualityCAPAData } = qualityCAPASlice.actions

export default qualityCAPASlice.reducer