import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { FetchDataParams } from "types/wctTypes";
import { filterParams, getOktaToken } from "utils/common-methods";
import { INCA_DEEP_DIVE_URL, INCA_HBC_TIME_PERIOD_DEEP_DIVE_URL, INCA_TIME_PERIOD_DEEP_DIVE_URL } from "utils/constants";
import { StringKeyAnyDataProps, StringKeyDataProps } from "utils/data-types";

interface IncaDataType {
  inca_global_ehss: any[];
  inca_open_incidents: any[];
  summary: any[];
  data: any[];
  inca_bar_chart_type: StringKeyDataProps[];
  inca_bar_chart_priority: StringKeyDataProps[];
  dataParams: StringKeyAnyDataProps;
}

interface TransformedData {
  inca: IncaDataType;
}

interface IncaInfoState {
  incaDeepDiveInfo: TransformedData | null;
  incaTimePeriodInfo: StringKeyAnyDataProps | null;
  incaHbcTimePeriodInfo: StringKeyAnyDataProps | null;
  loading: boolean;
  error: boolean;
  loading2: boolean;
  error2: boolean;
}

const initialState: IncaInfoState = {
  incaDeepDiveInfo: null,
  incaTimePeriodInfo: null,
  incaHbcTimePeriodInfo: null,
  loading: false,
  error: false,
  loading2: false,
  error2: false,
};

const fetchIncaDeepDive = createAsyncThunk<any, FetchDataParams>(
  INCA_DEEP_DIVE_URL,
  async (params) => {
    const filteredParams = filterParams(params)
    const token: string = getOktaToken();

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${INCA_DEEP_DIVE_URL}?${new URLSearchParams(filteredParams).toString()}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch inca");
    }
    return await response.json();
  }
)

const fetchIncaTimePeriodDeepDive = createAsyncThunk<any, FetchDataParams>(
  INCA_TIME_PERIOD_DEEP_DIVE_URL,
  async (params) => {
    const filteredParams = filterParams(params)
    const token: string = getOktaToken();

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${INCA_TIME_PERIOD_DEEP_DIVE_URL}?${new URLSearchParams(filteredParams).toString()}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch inca");
    }
    return await response.json();
  }
)
const fetchIncaHbcTimePeriodDeepDive = createAsyncThunk<any, FetchDataParams>(
  INCA_HBC_TIME_PERIOD_DEEP_DIVE_URL,
  async (params) => {
    const filteredParams = filterParams(params)
    const token: string = getOktaToken();

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${INCA_HBC_TIME_PERIOD_DEEP_DIVE_URL}?${new URLSearchParams(filteredParams).toString()}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch inca");
    }
    return await response.json();
  }
)

const transformData = (data: any): TransformedData => ({
  inca: {
    inca_global_ehss: data.inca_global_ehss?.map((item: any) => ({
      action_taken: item.action_taken ?? "",
      amgen_site: item.amgen_site ?? "",
      date: item.date ?? "",
      incident_type: item.incident_type ?? "",
      short_description: item.short_description ?? "",
    })),
    inca_open_incidents: data.inca_open_incidents?.map((item: any) => ({
      overdue: item.overdue_status ?? "",
      issue_date: item.issue_date ?? "",
      priority: item.priority ?? "",
      owner: item.owner ?? "",
      incident_type: item.incident_type ?? "",
      safety_pyramid_level: item.safety_pyramid_level ?? "",
      short_description: item.short_description ?? "",
      status: item.status ?? "",
    })),
    summary: data?.summary?.[0],
    data: data?.inca_trend_chart,
    inca_bar_chart_type: data.inca_type_bar_chart,
    inca_bar_chart_priority: data.inca_priority_bar_chart,
    dataParams: data?.dataParams
  }
});

const incaDeepDiveReducer = createSlice({
  name: "inca",
  initialState,
  reducers: {
    clearSafetyIncaDeepDiveData(state) {
      state.incaDeepDiveInfo = null
    },
    clearSfIncaTimePeriodDdv(state) {
      state.incaTimePeriodInfo = null
    },
    clearSfIncaHbcTimePeriodDdv(state) {
      state.incaHbcTimePeriodInfo = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIncaDeepDive.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchIncaDeepDive.fulfilled, (state, action) => {
        state.loading = false;
        state.incaDeepDiveInfo = transformData(action.payload);
        state.incaTimePeriodInfo = transformData(action.payload)?.inca;
        state.incaHbcTimePeriodInfo = transformData(action.payload)?.inca;
      })
      .addCase(fetchIncaDeepDive.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(fetchIncaTimePeriodDeepDive.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchIncaTimePeriodDeepDive.fulfilled, (state, action) => {
        state.loading = false;
        state.incaTimePeriodInfo = action.payload?.[0];
      })
      .addCase(fetchIncaTimePeriodDeepDive.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(fetchIncaHbcTimePeriodDeepDive.pending, (state) => {
        state.loading2 = true;
        state.error2 = false;
      })
      .addCase(fetchIncaHbcTimePeriodDeepDive.fulfilled, (state, action) => {
        state.loading2 = false;
        const inca = {
          inca_bar_chart_type: action.payload?.[0]?.data,
          inca_bar_chart_priority: action.payload?.[1]?.data,
          dataParams: {
            typeBarChartParams: action.payload?.[0]?.dataParams,
            priorityBarChartParams: action.payload?.[1]?.dataParams
          }
        }
        state.incaHbcTimePeriodInfo = inca;
      })
      .addCase(fetchIncaHbcTimePeriodDeepDive.rejected, (state) => {
        state.loading2 = false;
        state.error2 = true;
      });
  },
});

export { fetchIncaDeepDive, fetchIncaTimePeriodDeepDive, fetchIncaHbcTimePeriodDeepDive }

export const { clearSafetyIncaDeepDiveData, clearSfIncaTimePeriodDdv } = incaDeepDiveReducer.actions

export default incaDeepDiveReducer.reducer;
