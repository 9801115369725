import React from 'react';
import ReactECharts from 'echarts-for-react';
import { Empty } from 'antd';

interface PieChartProps {
    pmPercentage: string;
    cmPercentage: string;
    gmPercentage: string;
}

const PieChart: React.FC<PieChartProps> = ({ pmPercentage, cmPercentage, gmPercentage }) => {

    const SafePercentage = (percentage: string | undefined): string => {
        if (!percentage) {
            return '0';
        }
        return percentage.replace('%', '');
    };

    const pmValue = SafePercentage(pmPercentage);
    const cmValue = SafePercentage(cmPercentage);
    const gmValue = SafePercentage(gmPercentage);

    const option = {
        series: [
            {
                name: '',
                type: 'pie',
                radius: ['43%', '70%'],
                center: ['30%', '65%'],
                startAngle: 180,
                endAngle: 360,
                avoidLabelOverlap: false,
                label: {
                    show: false, 
                },
                labelLine: {
                    show: false,
                },
                data: [
                    { value: pmValue, name: 'PM', itemStyle: { color: '#0063C3' } },
                    { value: cmValue, name: 'CM', itemStyle: { color: '#2FBCB6' } },
                    { value: gmValue, name: 'GM', itemStyle: { color: '#FDB81E' } },
                ],
            },
        ],
        legend: {
            orient: 'vertical',
            right: '10%',
            top: 'center',
            itemGap: 15,
            icon: 'circle',
            itemWidth: 10, 
            itemHeight: 10,
            textStyle: {
                fontSize: 12,
                color: '#000',
            },
        },
    };

    return (
        <div style={{ width: '100%', height: '107px' }}>
            {(pmPercentage?.length > 0 || cmPercentage?.length > 0 || gmPercentage?.length > 0) ? (
                <>
                    <ReactECharts option={option} style={{ height: '135px', margin: '0 auto' }} />
                </>
            ) : (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Data Available"
                />
            )}
        </div>
    );
};

export default PieChart;
