import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { filterParams, getOktaToken } from 'utils/common-methods';
import { WORK_ORDER_DEEP_DIVE_URL } from 'utils/constants';

interface WorkOrderDeepDiveDataItem {
    issued_last_24_hours?: number;
    overdue?: number;
    work_order_number?: string;
    work_order_type?: string;
    classification?: string;
    short_desc?: string;
}

interface WorkOrderDataType {
    data: WorkOrderDeepDiveDataItem[];
    summary?: { issued_last_24_hours: number }[];
}

interface TransformedWorkOrderDeepDiveItem {
    issued_last_24_hours?: number;
    overdue?: number;
    work_order_number?: string;
    work_order_type?: string;
    classification?: string;
    short_desc?: string;
}

interface workOrderSummaryItem {
    issued_last_24_hours: number;
}

export interface TransformedData {
    workOrderDeepDive: TransformedWorkOrderDeepDiveItem[];
    summary?: { issued_last_24_hours: number }[];
}

interface workOrderDeepDiveInfoState {
    workOrderDeepDiveInfo: TransformedData | null;
    loading: boolean;
    error: boolean;
}

const initialState: workOrderDeepDiveInfoState = {
    workOrderDeepDiveInfo: null,
    loading: false,
    error: false,
};
interface FetchDataParams {
    workStream?: string | undefined,
    site?: string | undefined,
    persona?: string | undefined,
    tierNumber?: string | undefined,
    shift?: string | undefined,
    line?: string | undefined,
    building?: string | undefined,
}

const fetchWorkOrderDeepDive = createAsyncThunk<WorkOrderDataType, FetchDataParams>(
    WORK_ORDER_DEEP_DIVE_URL,
    async (params) => {
        const filteredParams = filterParams(params)
        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}${WORK_ORDER_DEEP_DIVE_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch Work Orders');
        }
        return await response.json();
    }
);

const transformData = (data: WorkOrderDataType): TransformedData => ({
    workOrderDeepDive: data.data.map((item, index) => ({
        key: index,
        overdue: item.overdue ?? 0,
        work_order_number: item.work_order_number ?? "",
        work_order_type: item.work_order_type ?? "",
        classification: item.classification ?? "",
        short_desc: item.short_desc ?? ""
    })),
    summary: data?.summary
});

const workOrderDeepDiveReducer = createSlice({
    name: 'workOrderDeepDive',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchWorkOrderDeepDive.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchWorkOrderDeepDive.fulfilled, (state, action) => {
                state.loading = false;
                state.workOrderDeepDiveInfo = transformData(action.payload);
            })
            .addCase(fetchWorkOrderDeepDive.rejected, (state) => {
                state.loading = false;
                state.error = true;
            });

    },
});

export { fetchWorkOrderDeepDive };

export default workOrderDeepDiveReducer.reducer;