import { axisLabelConfig, dataZoomConfig, layoutConfig, leftAxisConfig, nameTextStyleConfig, tooltipConfig, customConfig } from './chartConfig';
import { ChartConfigProps, COLORS, DataItem, EchartsGridProps, excludedKeys, LegendProps } from './constants';
import { getSeriesData } from './seriesData';

export function formatMonthYear(item: DataItem): string {
  const month = item.Month?.toString().padStart(2, "0") ?? "01";
  const year = item.Year?.toString().slice(-2) ?? "24";
  return `${month}/${year}`;
}
export const generateChartOptions = (chartType: string, data: any, xAxisValues: any, yAxisNames: any, 
  nameGapCustom: any, chartConfig?: ChartConfigProps[], yAxisMax: number = 0, showSlider: boolean = false, grid: EchartsGridProps = layoutConfig) => {
  const yAxisConfig = yAxisNames ? yAxisNames?.map((yAxis: any) => ({
    type: "value",
    name: yAxis.name,
    nameLocation: "middle",
    position: yAxis.position,
    nameGap: nameGapCustom ?? 50,
    min: 0,
    max: yAxis.max,
    nameTextStyle: nameTextStyleConfig,
    alignTicks: true,
    axisLabel: yAxis.position === "right" ? axisLabelConfig : leftAxisConfig,
  })) : {
    type: "value",
    nameGap: 0,
    min: 0,
    ...(yAxisMax && yAxisMax > 0 ? { max: yAxisMax } : {}),
    position: "left",
    axisLabel: {
      ...leftAxisConfig
    }
  }

  return {
    tooltip: tooltipConfig,
    grid: nameGapCustom ? customConfig : { ...layoutConfig, ...grid },
    xAxis: {
      type: "category",
      data: xAxisValues,
      axisTick: { show: false },
      axisLabel: leftAxisConfig,
    },
    yAxis: yAxisConfig,
    dataZoom: [{...dataZoomConfig[0]}, {...dataZoomConfig[1], show: showSlider}],
    series: getSeriesData(chartType, data, chartConfig),
  }
}

export function formatMTDDate(item: DataItem): string | undefined {
  const date = item.mtd_date ?? item.last_7d_date ?? item.date ?? item.compliance_date ?? item.month

  if (date) {
    const dateObj = new Date(date);
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    return `${month}/${day}`;
  }
  return undefined;
}

export function newMinMaxValue(items: DataItem[]) {
  if (!items || items.length === 0) {
    return undefined;
  }

  const numericValues: number[] = items.flatMap(item =>
    Object.entries(item)
      .filter(
        ([key, value]) =>
          !excludedKeys.includes(key) &&
          typeof value === 'number' &&
          value !== null &&
          value !== undefined
      )
      .reduce((acc, [_, value]) => acc + (value as number), 0)
  )

  const minValue = Math.min(...numericValues);
  let maxValue = Math.max(...numericValues);
  if (numericValues.length === 0) {
    return { Min: 0, Max: 100 };
  } else if (maxValue === 0) {
    return { Min: minValue, Max: 100 }
  }

  maxValue = Math.ceil(maxValue / 5) * 5

  return { Min: minValue, Max: maxValue };
}

export const getLegendItems = (data: DataItem[], chartType: string): LegendProps[] => {
  if (!data) return [];
  if (chartType === 'rft') return [];
  if (chartType === 'avgBatchDltGraph') return [];
  if (chartType === "see-cards") {
    return [
      { statusValue: "Serious", circleColor: COLORS.serious },
      {
        statusValue: "Potentially Serious",
        circleColor: COLORS.potentiallySerious,
      },
      { statusValue: "Major", circleColor: COLORS.major },
      { statusValue: "Minor", circleColor: COLORS.minor },
      { statusValue: "Non-EHS Issue", circleColor: COLORS.nonEHSIssue },
    ];
  } else if (chartType === "minor-deviation") {
    return [
      { statusValue: "NON-AGING MINOR DEVIATIONS", circleColor: "#0063C3" },
      { statusValue: "AGING MINOR DEVIATIONS", circleColor: "#FDB81E" },
      { statusValue: "TREND OF DAILY COMING DUE MINOR DEVIATIONS", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "major-deviation") {
    return [
      { statusValue: "NON-AGING MAJOR DEVIATIONS", circleColor: "#0063C3" },
      { statusValue: "AGING MAJOR DEVIATIONS", circleColor: "#FDB81E" },
      { statusValue: "TREND OF DAILY COMING DUE MAJOR DEVIATIONS", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "minor-deviation-adherence-mtd" || chartType === "minor-deviation-adherence-6m") {
    return [
      { statusValue: "ON-TIME MINOR DEVIATIONS", circleColor: "#0063C3" },
      { statusValue: "NOT ON-TIME MINOR DEVIATIONS", circleColor: "#FDB81E" },
      { statusValue: "% OF MINOR DEVIATION ADHERENCE", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "major-deviation-adherence-mtd" || chartType === "major-deviation-adherence-6m") {
    return [
      { statusValue: "ON-TIME MAJOR DEVIATIONS", circleColor: "#0063C3" },
      { statusValue: "NOT ON-TIME MAJOR DEVIATIONS", circleColor: "#FDB81E" },
      { statusValue: "% OF MAJOR DEVIATION ADHERENCE", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "minor-deviation-aging-mtd" || chartType === "minor-deviation-aging-6m") {
    return [
      { statusValue: "NON-AGING MINOR DEVIATIONS", circleColor: "#0063C3" },
      { statusValue: "AGING MINOR DEVIATIONS", circleColor: "#FDB81E" },
      { statusValue: "% OF AGING MINOR DEVIATIONS", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "major-deviation-aging-mtd" || chartType === "major-deviation-aging-6m") {
    return [
      { statusValue: "NON-AGING MAJOR DEVIATIONS", circleColor: "#0063C3" },
      { statusValue: "AGING MAJOR DEVIATIONS", circleColor: "#FDB81E" },
      { statusValue: "% OF AGING MAJOR DEVIATIONS", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "change-control-status") {
    return [
      { statusValue: "NON-AGING CHANGE CONTROLS", circleColor: "#0063C3" },
      { statusValue: "AGING CHANGE CONTROLS", circleColor: "#FDB81E" },
      { statusValue: "TREND OF DAILY COMING DUE CHANGE CONTROLS", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "change-control-adherence-mtd" || chartType === "change-control-adherence-6m") {
    return [
      { statusValue: "ON-TIME CHANGE CONTROLS", circleColor: "#0063C3" },
      { statusValue: "NON ON-TIME CHANGE CONTROLS", circleColor: "#FDB81E" },
      { statusValue: "% OF CHANGE CONTROL ADHERENCE", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "change-control-aging-mtd" || chartType === "change-control-aging-6m") {
    return [
      { statusValue: "NON CHANGE CONTROLS", circleColor: "#0063C3" },
      { statusValue: "AGING CHANGE CONTROLS", circleColor: "#FDB81E" },
      { statusValue: "% OF AGING CONTROLS", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "capa-status") {
    return [
      { statusValue: "NON-AGING CAPAS", circleColor: "#0063C3" },
      { statusValue: "AGING CAPAS", circleColor: "#FDB81E" },
      { statusValue: "TREND OF DAILY COMING CAPAS", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "capa-status-mtd" || chartType === "capa-status-adherence-6m") {
    return [
      { statusValue: "ON-TIME CAPAS", circleColor: "#0063C3" },
      { statusValue: "NOT ON TIME CAPAS", circleColor: "#FDB81E" },
      { statusValue: "% OF CAPA ADHERENCE", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "capa-status-aging-mtd" || chartType === "capa-status-aging-6m") {
    return [
      { statusValue: "NON AGING CAPAS", circleColor: "#0063C3" },
      { statusValue: "AGING CAPAS", circleColor: "#FDB81E" },
      { statusValue: "% OF AGING CAPAS", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "ratio-pm" || chartType === "ratio-pm-vs" || chartType === "percent-closure-pm") {
    return [];
  } else if (chartType === "aging-work-orders") {
    return [
      { statusValue: "OPEN", circleColor: "#0063C3" },
      { statusValue: "CLOSED", circleColor: "#FDB81E" },
    ];
  } else if (chartType === "wo-closure-adherence") {
    return [
      { statusValue: "WO CLOSURE ADHERENCE", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "wo-calibration-dis") {
    return [
      { statusValue: "PMCAL", circleColor: "#0063C3" },
      { statusValue: "CMCAL", circleColor: "#FDB81E" },
      { statusValue: "GMCAL (MTD)", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "per-calibration-closure") {
    return [
      { statusValue: "Total Workorders", circleColor: "#0063C3" },
      { statusValue: "Closure Adherence Percentage", circleColor: "#FDB81E" },
      { statusValue: "Closed Workorders", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "on-time-schedule-bv") {
    return [];
  } else if (chartType === "brrReviewTarget") {
    return [
      { statusValue: "On Time Reviewed BRR", circleColor: "#0063C3" },
      { statusValue: "Not On Time Reviewed BRR", circleColor: "#FDB81E" },
      { statusValue: "% of BRR Reviewed Within Target", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "avgBatchDltGraph") {
    return [
      { statusValue: "TOTAL DISPOSITIONED BATCHES", circleColor: "#0063C3" },
      { statusValue: "AVG. DLT PER BATCHES (LAST 7 DAYS)", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "avgTatWrtTesting") {
    return [
      { statusValue: "Chromatography_HPLC", circleColor: "#0063C3" },
      { statusValue: "Chromatography_UPLC", circleColor: "#FDB81E" },
      { statusValue: "Elisa_Immuno", circleColor: "#2FBCB6" },
      { statusValue: "RAMAN", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "brrCompletedWithinTarget") {
    return [
      { statusValue: "On Time BRR", circleColor: "#0063C3" },
      { statusValue: "Not On Time BRR", circleColor: "#FDB81E" },
      { statusValue: "% of BRR Completed Within Target", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "batchefordispositionWithTarget") {
    return [
      { statusValue: "On Time Batche Dispositioned", circleColor: "#0063C3" },
      { statusValue: "Not On Time Batche Dispositioned", circleColor: "#FDB81E" },
      { statusValue: "% of Batch Dispositioned Within Target (MTD)", circleColor: "#2FBCB6" },
    ];
  } else {
    return [
      { statusValue: "At-Risk Behavior", circleColor: COLORS.atRiskBehavior },

      {
        statusValue: "At-Risk Condition",
        circleColor: COLORS.atRiskCondition,
      },

      { statusValue: "Safe Behavior", circleColor: COLORS.safeBehavior },
    ];
  }
};