import { StatusIssueIcon, StatusPositiveIcon, ExpandArrowsIcon, OpsTooltip, OpsTooltipTrigger, OpsTooltipContent } from "@gitlab-rtsensing/component-library";
import { Alert, Card, Empty, Tag } from "antd";
import css from './CustomMetricCard.module.css'
import Calendar from "../calendar/Calendar";
import SimpleLineGraph from "../charts-graph/simple-line-graph/SimpleLineGraph";
import { useEffect } from "react";
import { callCustomMetricApiWithCurrentParams } from "reducers/customMetricParamsReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import HorizontalBarChartV2 from "components/common/HorizontalBar/HorizontalBarV2";
import CleanProgressBar from "../clean-progress-bar-reusable";
import PieChart from "components/common/custom-pie-chart/PieChart";
import { AlertStatusIconProps, CustomAlertCSSProperties, CustomMetricCardProps, HeaderProps, StringKeyAnyDataProps, StringKeyDataProps } from "./CustomMetricCard.types";
import HorizontalProgressBar from "../horizontal-progress-bar";
import StackedBarChart from "components/common/StackedBar/StackedBar";
import HorizontalBulletChart from "../horizontal-progress-bar/HorizontalBulletChart";

const headerStatusIconColor: Record<string, string> = {
  Red: 'var(--ops-sem-red-primary)',
  Green: '#2CC84D',
  Yellow: '#2CC84D'
}

const statusIconSize = {
  width: '16',
  height: '16'
}

const AlertStatusIcon: React.FC<AlertStatusIconProps> = (props) => {
  if (props.alertType === 'error') {
    return (
      <StatusIssueIcon
        fill={headerStatusIconColor.Red}
        height={statusIconSize.height}
        width={statusIconSize.width}
        {...props}
      />
    )
  } else {
    return (
      <StatusPositiveIcon
        fill={headerStatusIconColor.Green}
        height={statusIconSize.height}
        width={statusIconSize.width}
        {...props}
      />
    )
  }
}

const alertStyle: CustomAlertCSSProperties = {
  "--card-status-icon-height": `${statusIconSize.height}px`
};

const Header: React.FC<HeaderProps> = (props) => {
  if (props.isAlert) {
    return (
      <Alert
        style={alertStyle}
        rootClassName={css.customAlert}
        closable={false}
        showIcon
        icon={<AlertStatusIcon alertType={props.alertType} {...props} />}
        message={<span className={css.customHeader}>{props.title}</span>}
        type={props.alertType}
        action={
          props.setIsOpenDeepDive && props.setActiveKeyDeepDiveTab ? (
            <ExpandArrowsIcon
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                props.setIsOpenDeepDive?.(true);
                props.setActiveKeyDeepDiveTab?.(props.title);
              }}
              height={'16'}
              width={'16'}
              {...props}
            />
          )
            : <ExpandArrowsIcon
              style={{
                cursor: 'not-allowed',
                opacity: 0.5,
              }}
              onClick={() => {
                props.setIsOpenDeepDive?.(false);
                props.setActiveKeyDeepDiveTab?.("");
              }}
              height={'16'}
              width={'16'}
              {...props}
            />
        }
      />
    );
  } else {
    return (
      <span className={css.customHeader}>{props.title}</span> //icon here
    )
  }
}

const CustomMetricCard: React.FC<CustomMetricCardProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isGraph, isAlert, className, id, title, actionKey, tierLabel } = props; // Destructured at the top
  const customMetricParams = useSelector(
    (state: RootState) => state.customMetricParams
  );

  const tierNumber = customMetricParams?.customMetricParams?.tierNumber;
  let customClassNames = isGraph ? css.customCardBody : ''
  customClassNames = isAlert ? `${css.customCardHead} ${customClassNames} ${className}` : `${className} ${customClassNames} ${css.customCalendarHead}`
  useEffect(() => {
    if (
      customMetricParams &&
      actionKey &&
      tierLabel?.toLocaleLowerCase() === tierNumber
    ) {
      dispatch(callCustomMetricApiWithCurrentParams(actionKey));
    }
  }, [customMetricParams, actionKey, dispatch, tierNumber]);

  return (
    <Card id={id} className={customClassNames} size="small"
      title={<Header title={title} isAlert={props.isAlert} headerStatusColor={props.headerStatusColor} alertType={props.alertType ?? 'success'} setIsOpenDeepDive={props.setIsOpenDeepDive} setActiveKeyDeepDiveTab={props.setActiveKeyDeepDiveTab} />}
    >
      {props.isCalendar && (props.greenDates ?? props.redDates) &&
        <Calendar greenDates={props.greenDates} redDates={props.redDates} greenColor={props.greenColor} redColor={props.redColor} />}
      {props.isGrid && props.data &&
        <div className={css.cardContent}>
          {props.newInterface ? props.data?.map((item: StringKeyAnyDataProps, index: number) => {
            if (item?.isHorizontalLine) {
              return (
                <hr key={index}
                  style={{
                    border: "none",
                    backgroundColor: "#F0F0F0",
                    height: "2px"
                  }} />
              )
            } else if (item?.isCardTag) {
              return (
                <div key={index}>
                  <Tag color={"#434343"} style={{
                    padding: '0px 8px',
                    height: '16px',
                    borderRadius: '4px',
                    fontSize: '10px',
                    lineHeight: '14px',
                    fontWeight: 700,
                    fontFamily: 'Inter'
                  }}>{item.data?.cardTag}</Tag>
                </div>
              )
            } else if (item?.isCard) {
              return Object.entries(item.data)?.map(([key, value]: [string, any], i: number) => (
                <div key={i}>
                  <p className={css.cardContentRow}>
                    <span className={css.cardContentLabel}>{key.replaceAll("_", " ")}</span>
                    <span className={css.cardContentValue}>{value}</span>
                  </p>
                </div>
              ))
            } else if (item?.isCardTable) {
              const tableKey = item?.tableKey ?? 'label'
              const tableValue = item?.tableValue ?? 'value'
              const highlightedParams = item?.highlightedParams?.[0] ?? 'value'

              return item.data?.map((tableData: StringKeyDataProps, i: number) => {
                let highlightedColor = "#1F1F1F"
                if (tableData[highlightedParams] as number > 0) {
                  highlightedColor = "#0F7A2D";
                } else if (tableData[highlightedParams] as number < 0) {
                  highlightedColor = "#E01029";
                }

                return (
                  <div key={i}>
                    <p className={css.cardContentRow}>
                      <span className={css.cardContentLabel}>{tableData[tableKey]}</span>
                      <span className={css.cardContentValue} style={{ color: highlightedColor }}>{tableData[tableValue]}</span>
                    </p>
                  </div>
                )
              })
            } else if (item?.isProgressBar) {
              return (
                <div key={index} className={item.data.length > 0 ? css.aohProgressBar : css.aohEmptyProgressBar}>
                  <div key={index}>
                    <div className={css.lineLabel}>{item?.data[0]?.line}</div>
                    <div className={css.progressBarContainer}>
                      <OpsTooltip placement="right">
                        <OpsTooltipTrigger>
                          <HorizontalBulletChart
                            percentageArray={item.data}
                          />
                        </OpsTooltipTrigger>
                        {item?.data.length > 0 ? <OpsTooltipContent className={css.tipContainer}>
                          <div className={css.tipHeaderDesign}>{item?.data[0]?.line}</div>
                          <br/>
                          <div className={css.tipContentDesign}>Shift Performance</div>
                          <div className={css.tipContentDesign}>
                            <div>
                              <strong>Lag/Ahead: </strong> {item?.data[0]?.total_lag_ahead_hours} HR
                            </div>
                          </div>
                        </OpsTooltipContent> : <OpsTooltipContent className={css.tipContainer}>
                          <div className={css.tipContentDesign}>
                            No Data
                          </div>
                        </OpsTooltipContent>}
                      </OpsTooltip>
                    </div>
                  </div>

                </div>
              );
            } else if (item?.isHorizontalBarChart) {
              return (
                <div key={index} className={item.data.length > 0 ? css.aohStatusHorizontalProgressBar : css.aohEmptyProgressBar}>
                  <div className={css.legendContainer}>
                    <div className={css.legendItem}>
                      <div className={css.legendColorBlue} />
                      <span>Completed</span>
                    </div>
                    <div className={css.legendItem}>
                      <div className={css.legendLineDashed} />
                      <span>Target</span>
                    </div>
                  </div>
                  {item.data.map((dataItem: any, index: any) => (
                    <div key={index}>
                      <div className={css.lineLabel}>{dataItem[item.dataParams.yAxisKey]}</div>
                      <div className={css.progressBarContainer}>
                        <OpsTooltip placement="right">
                          <OpsTooltipTrigger>
                            <HorizontalProgressBar
                              percentageArray={[
                                {
                                  percentage: dataItem[item.dataParams.xAxisKey],
                                  flag: "BLUE",
                                  passedHour: `${dataItem.target}`,
                                  totalHour: `${dataItem.completed}`
                                },
                              ]}
                            />
                          </OpsTooltipTrigger>
                          {item?.data?.length > 0 ? <OpsTooltipContent className={css.tipContainer}>
                            <div className={css.tipHeaderDesign}>{dataItem[item.dataParams.yAxisKey]}</div>
                            <br/>
                            <div className={css.tipContentDesign}>{props.tooltipContent}</div>
                            <div className={css.tipContentDesign}>
                              <div>
                                <strong>Completed: </strong> {dataItem.completed}
                              </div>
                              <div>
                                <strong>Target:</strong> {dataItem.target}
                              </div>
                              <div>
                                <strong> Delta:</strong> {dataItem.delta}
                              </div>
                            </div>
                          </OpsTooltipContent> : <OpsTooltipContent className={css.tipContainer}>
                            <div className={css.tipContentDesign}>
                              No Data
                            </div>
                          </OpsTooltipContent>}
                        </OpsTooltip>
                      </div>
                    </div>
                  ))}
                </div>
              );
            } else if (item?.isProgressBarChart) {
              return (
                <div key={index} className={item.data.length > 0 ? css.aohStatusVerticalBar : css.aohEmptyProgressBar}>
                  <div key={index} className={css.verticalBarContainer}>
                    <OpsTooltip placement="right">
                      <OpsTooltipTrigger>
                        <StackedBarChart
                          data={item?.data}
                          chartType="on-time-schedule-bv"
                          xAxisData={item?.data.map((item: any) => item?.quarter)}
                          yAxisNames={[
                            { name: "", position: "left", max: item?.data.length > 0 && item?.data[0]?.ytd_planned }
                          ]}
                        />
                      </OpsTooltipTrigger>
                    </OpsTooltip>
                  </div>
                </div>
              );
            }
            return null
          })
            : Object.entries(props.data)?.map(([key, value]: [string, any], index: number) => {
              const keys = key.split("&")
              if (
                keys.length > 1 &&
                props.title === "Minor Deviation"
              ) {
                return (
                  <div key={key}>
                    <hr className={css.breakLine}></hr>
                    {
                      <p className={css.cardContentRow}>
                        <div>
                          <span className={css.cardContentLabel1}>
                            {keys[0]}
                          </span>
                          <span className={css.cardContentLabel2}>
                            {keys[1]}
                          </span>
                        </div>
                        <span className={((key.toLowerCase() === 'overdue' || String(key).includes('overdue')) && value > 0) ? css.errorValueColor : css.cardContentValue}>{value}</span>
                      </p>
                    }
                  </div>
                );
              } else if (props.title === "Right First Time" && Array.isArray(value)) {
                return (
                  <div key={key}>
                    {(title !== "GXP Training" && title !== "EHSS Training Compliance" && title !== "Change Control" && title !== "CAPA Status") && key !== 'OPEN DEVIATION' && index !== 0 && index % 3 === 0 && <hr className={css.breakLine}></hr>}
                    {index !== 0 && <>
                      <hr className={css.breakLine}></hr>
                    </>}
                    <span className={css.tags}>{String(key).toUpperCase()}</span>
                    {value?.length > 0 && value?.map((val: any, i: number) => {
                      return <p key={`${i}_${val}`} className={css.cardContentRow}>
                        <span className={css.cardContentLabel}>{val?.targetbatch || val?.line}</span>
                        {props.alertType === 'error' && <span className={css.cardContentValue}>{val?.deviation_record_count || val?.open_exp}</span>}
                        {props.alertType === 'success' && <span className={css.cardContentValue}>{val?.deviation_record_count || val?.open_exp}</span>}
                      </p>
                    })}
                  </div>
                );
              }
              const isBreakine = (title !== "GXP Training" && title !== "EHSS Training Compliance" && title !== "Change Control" && title !== "CAPA Status" && title !== "Batch Disposition" && title !== "% PM Adherence & Upcoming PMs") && key !== 'OPEN DEVIATION' && title !== "Calibration Closure Adherence" && index !== 0 && index % 3 === 0
              return (
                <div key={key}>
                  {isBreakine && <hr className={css.breakLine}></hr>}
                  {index === 1 && props.label && <>
                    <hr className={css.breakLine}></hr>
                    <h4 className={css.pdla}>PENDING LEARNING ACTIVITIES</h4>
                  </>}
                  <p className={css.cardContentRow}>
                    <span className={css.cardContentLabel}>{key}</span>
                    <span className={((key.toLowerCase() === 'overdue' || String(key).toLowerCase().includes('overdue') || String(key).toLowerCase().includes('mfc target')) && value > 0) ? css.errorValueColor : css.cardContentValue}>{value}</span>
                  </p>
                </div>
              )
            })}
        </div>}
      {JSON.stringify(props.data) === '{}' && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      {props.graphData && props.graphData?.length > 0 && <OpsTooltip placement="right">
        <OpsTooltipTrigger>
          <SimpleLineGraph data={props.graphData} targetReferenceValue={props.referenceLine} tipContent={props.tooltipContent} />
        </OpsTooltipTrigger>
        <OpsTooltipContent className={css.tipContainer}>
          <div className={css.tipHeaderDesign}>Trend Graph</div>
          <div className={css.tipContentDesign}>{props.tooltipContent}</div>
        </OpsTooltipContent>
      </OpsTooltip>}
      {props.progressBarType && tierLabel?.toLocaleLowerCase() !== "tier 3" && (
        <div className={props.progressBarType.length > 0 ? css.aohStatusProgressBar : css.aohEmptyProgressBar}>
          <OpsTooltip placement="right">
            <OpsTooltipTrigger>
              <CleanProgressBar
                percentageArray={props.progressBarType.map((data) => ({
                  ...data,
                  percentage: Number(data.percentage),
                }))}
              />
            </OpsTooltipTrigger>
            <OpsTooltipContent className={css.tipContainer}>
              <div className={css.tipContentDesign}>{props.tooltipContent}</div>
            </OpsTooltipContent>
          </OpsTooltip>
        </div>
      )}
      {props.pieChartType && tierLabel?.toLocaleLowerCase() === "tier 3" && (
        <OpsTooltip placement="right">
          <OpsTooltipTrigger>
            <div>
              <PieChart
                pmPercentage={props.pieChartType[0]?.pmPercentage}
                cmPercentage={props.pieChartType[0]?.cmPercentage}
                gmPercentage={props.pieChartType[0]?.gmPercentage}
              />
            </div>
          </OpsTooltipTrigger>
          <OpsTooltipContent className={css.tipContainer}>
            <div className={css.tipHeaderDesign}>Pie Chart</div>
            <div className={css.tipContentDesign}>{props.tooltipContent}</div>
            <div className={css.tipContentDesign}>
              <div>
                <strong>PM:</strong> {props.pieChartType[0]?.pmPercentage || 0}
              </div>
              <div>
                <strong>CM:</strong> {props.pieChartType[0]?.cmPercentage || 0}
              </div>
              <div>
                <strong>GM:</strong> {props.pieChartType[0]?.gmPercentage || 0}
              </div>
            </div>
          </OpsTooltipContent>
        </OpsTooltip>
      )}
      {props.horizontalgraphData && props.horizontalgraphData?.length > 0 && <OpsTooltip placement="right">
        <OpsTooltipTrigger>
          <HorizontalBarChartV2
            BehaviorObservationData={props.horizontalgraphData}
            chartType="calibration"
          />
        </OpsTooltipTrigger>
        <OpsTooltipContent className={css.tipContainer}>
          <div className={css.tipHeaderDesign}>Bar Graph</div>
          <div className={css.tipContentDesign}>{props.tooltipContent}</div>
        </OpsTooltipContent>
      </OpsTooltip>}

    </Card >
  )
}

export default CustomMetricCard
