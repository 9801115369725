import React from "react";

import { Tooltip, Typography } from "antd";

interface TooltipWrapperProps {
  text: string;
  maxWidth?: number;
}

const TooltipWrapper: React.FC<TooltipWrapperProps> = ({
  text,
  maxWidth = 200,
}) => {
  return (
    <Tooltip title={text}>
      <Typography.Paragraph ellipsis={{ rows: 3 }} style={{ maxWidth, marginBottom: 0 }}>
        {text}
      </Typography.Paragraph>
    </Tooltip>
  );
};

export default TooltipWrapper;
