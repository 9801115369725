import { Table, TableColumnsType } from "antd";
import TableCss from "./AohTable.module.css";
import Label from "../../resuable/label"

interface DataType {
  key?: React.Key;
  issueDate?: string;
  issue?: string;
  owner?: string;
  shortDescription?: string;
  type?: string;
  ehssProgramArea?: string;
  behaviourObservationType?: string;
  issued_last_24_hours?: number;
  overdue?: number | string | null;
  work_order_number?: string;
  work_order_type?: string;
  classification?: string;
  short_desc?: string;
}

interface LegendProps {
  statusValue: string | number;
  circleColor: string;
}

interface AohTableWithLabelsProps {
  tableData?: any;
  tableColumns?: any;
}

const AohTableWithLabels: React.FC<AohTableWithLabelsProps> = ({
  tableData,
  tableColumns,
}) => {
  const { tableContainer } = TableCss;

  const getLegendItems = (data: DataType[]) => {
    const legendItems: LegendProps[] = [];
    const overDue = { statusValue: "OVERDUE", circleColor: "#E01029" };
    const comingDue = {
      statusValue: "COMING DUE",
      circleColor: "#D9B500",
    };
    legendItems.push(overDue, comingDue);
    return legendItems;
  };

  const legendItems = getLegendItems(tableData);

  const showTotal = (total: number, range: [number, number]) =>
    `${range[0]} - ${range[1]} of ${total} items`;

  const columnsWithWidth = tableColumns?.map(
    (column: TableColumnsType<DataType>[number]) => ({
      ...column,
      onHeaderCell: () => ({
        style: {
          fontSize: "10px",
        },
      }),
      onCell: () => ({
        style: {
          fontSize: "12px",
        },
      }),
    })
  );

  return (
    <div className={tableContainer}>
      {legendItems.length > 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            {legendItems.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "16px",
                }}
              >
                <Label
                  StatusValue={item.statusValue}
                  circleColor={item.circleColor}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <Table
        columns={columnsWithWidth}
        dataSource={tableData}
        pagination={{
          pageSize: 5,
          showTotal,
          showSizeChanger: false,
        }}
      />
    </div>
  );
};

export default AohTableWithLabels;
