import React, { useEffect, useState } from "react";
import SummaryCard from "../../common/summaryCard/summaryCard";
import "./Quality.scss";
import { Button } from "@gitlab-rtsensing/component-library";
import { DownloadOutlined } from "@ant-design/icons";
import { RadioFilters } from "components/resuable/radio-filters/RadioFiltersQuality";
import ColorCodedTable from "components/common/table/ColorCodedTable";
import StackedBarChart from "components/common/StackedBar/StackedBar";
import AohTable from "components/common/table/AohTable";
import HorizontalBarChart from "components/common/HorizontalBar/HorizontalBarChart";
import { downloadCSV, generateCSV } from "utils/common-methods";
import { CapaEVColumns, CapaStatusColumns, ChangeControlColumns, majorDeviationColumns, minorDeviationColumns, rftColumnsTier2 } from "utils/wctConstants";
import { BlockRadioFilters } from "components/resuable/radio-filters/BlockRadioFilters";
import { StringKeyDataProps } from "utils/data-types";
import { formatMTDDate, newMinMaxValue } from "components/common/StackedBar/utils";

interface QualityTier2Props {
    data: any;
    tierLabel: string;
    activeKeyDeepDiveTab: string;
}

const QualityTier2: React.FC<QualityTier2Props> = ({
    data: { lineGraphData, tableDataPending, tableDataAcknowledge, barGraphData, summary, heatmapData, tableDataAll, tableDataOverdue, tableDataComingDue, tableDataAging, tableDataOpen, graphData, CAPAtableDataAll, CAPAtableDataOverdue, CAPAtableDataComingDue, CAPAtableDataAging, CAPAtableDataOpen, CAPAEVtableDataAll, CAPAEVtableDataOverdue, CAPAEVtableDataComingDue, CAPAEVtableDataAging, CAPAEVtableDataOpen },
    tierLabel,
    activeKeyDeepDiveTab,
}) => {
    const rftLineArr = ["All", "Pending", "Acknowledge"]
    const rftViewByArr = ["Average", "Lines"]
    const rftChartLegend = [
        { statusValue: "TARGET LINE", circleColor: "#E42E44" },
        { statusValue: "NUMBER OF PENDING EXCEPTION", circleColor: "#0063C3" },
    ]
    const [rftTableTimePeriod, setRftTableTimePeriod] = useState("All")
    const [rftGraphViewBy, setRftGraphViewBy] = useState("Average")
    const rftTableDataMap: Record<string, any> = {
        All: tableDataAll || [],
        Pending: tableDataPending || [],
        Acknowledge: tableDataAcknowledge || []
    }
    const rftLineGraphDataMap: Record<string, any> = {
        Average: lineGraphData?.avg || [],
        Lines: lineGraphData?.lines || []
    }
    const [minDevTableTimePeriod, setMinDevTableTimePeriod] = useState("Overdue")
    const [minDevTable2TimePeriod, setMinDevTable2TimePeriod] = useState("Overdue")
    const [max, setMax] = useState(0)
    const isAllOrOverdue = (minDevTableTimePeriod === "All" || minDevTableTimePeriod === "Overdue")
    const majorDeviationTableData = isAllOrOverdue
        ? (minDevTableTimePeriod === "All" ? tableDataAll : minDevTableTimePeriod === "Overdue" ? tableDataOverdue : tableDataComingDue)
        : (minDevTableTimePeriod === "Aging" ? tableDataAging : tableDataOpen)
    const minorDeviationTableData = isAllOrOverdue
        ? (minDevTableTimePeriod === "All" ? tableDataAll : minDevTableTimePeriod === "Overdue" ? tableDataOverdue : tableDataComingDue)
        : (minDevTableTimePeriod === "Aging" ? tableDataAging : tableDataOpen)
    const CapaStatusTableData = isAllOrOverdue
        ? (minDevTableTimePeriod === "All" ? CAPAtableDataAll : minDevTableTimePeriod === "Overdue" ? CAPAtableDataOverdue : CAPAtableDataComingDue)
        : (minDevTableTimePeriod === "Aging" ? CAPAtableDataAging : CAPAtableDataOpen)
    const CapaEVTableData = (minDevTable2TimePeriod === "All" || minDevTable2TimePeriod === "Overdue")
        ? (minDevTable2TimePeriod === "All" ? CAPAEVtableDataAll : minDevTable2TimePeriod === "Overdue" ? CAPAEVtableDataOverdue : CAPAEVtableDataComingDue)
        : (minDevTable2TimePeriod === "Aging" ? CAPAEVtableDataAging : CAPAEVtableDataOpen)
    const changeControlTableData = isAllOrOverdue
        ? (minDevTableTimePeriod === "All" ? tableDataAll : minDevTableTimePeriod === "Overdue" ? tableDataOverdue : tableDataComingDue)
        : (minDevTableTimePeriod === "Aging" ? tableDataAging : tableDataOpen)

    const maxValues = newMinMaxValue(graphData);

    const qualityDeepDive: any = {
        "Major Deviation": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary?.[0]}
                />
            ),
            title: "Trend of Daily Coming Due Major Deviation with Volume",
            subtitle: "In last 7 days",
            stackBar: (
                <StackedBarChart
                    data={graphData}
                    chartType="major-deviation"
                    xAxisData={graphData?.map((item: any) => formatMTDDate(item))}
                    yAxisNames={[
                        { name: "TOTAL OPEN MAJOR DEVIATIONS", position: "left", max: maxValues?.Max },
                        { name: "TREND OF DAILY COMING\nDUE MAJOR DEVIATIONS", position: "right", max: 100 },
                    ]}
                />
            ),
            title2: "Major Deviations",
            radioFilters: (
                <RadioFilters
                    timePeriod={minDevTableTimePeriod}
                    setTimePeriod={setMinDevTableTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            button: (
                <Button
                    label="Download"
                    className="download-btn"
                    icon={
                        <DownloadOutlined style={{ color: "#0063C3", fontSize: "14px" }} />
                    }
                    iconPosition="right"
                    onClick={() => {
                        if (majorDeviationTableData && majorDeviationColumns) {
                            const csvContent = generateCSV(majorDeviationTableData, majorDeviationColumns);
                            downloadCSV(csvContent, "Major_Deviation_Data.csv");
                        } else {
                            console.error("No data or columns available for download");
                        }
                    }}
                    type="secondary"
                />
            ),
            table: <AohTable
                tableData={majorDeviationTableData}
                tableColumns={majorDeviationColumns} />,
        },
        "Minor Deviation": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary?.[0]}
                />
            ),
            title: "Trend of Daily Aging Minor Deviations",
            subtitle: "In last 7 days",
            stackBar: (
                <StackedBarChart  //reference stack bar for generalize stackbar component
                    data={graphData}
                    chartType="minor-deviation"
                    xAxisData={graphData?.map((item: any) => formatMTDDate(item))}
                    yAxisNames={[
                        { name: "TREND OF DAILY COMING\nDUE MINOR DEVIATIONS", position: "left", max: maxValues?.Max },
                        { name: "TOTAL OPEN MINOR DEVIATIONS", position: "right", max: maxValues?.Max },
                    ]}
                />
            ),
            title2: "Minor Deviations",
            radioFilters: (
                <RadioFilters
                    timePeriod={minDevTableTimePeriod}
                    setTimePeriod={setMinDevTableTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            button: (
                <Button
                    label="Download"
                    className="download-btn"
                    icon={
                        <DownloadOutlined style={{ color: "#0063C3", fontSize: "14px" }} />
                    }
                    iconPosition="right"
                    onClick={() => {
                        if (minorDeviationTableData && minorDeviationColumns) {
                            const csvContent = generateCSV(minorDeviationTableData, minorDeviationColumns);
                            downloadCSV(csvContent, "Minor_Deviation_Data.csv");
                        } else {
                            console.error("No data or columns available for download");
                        }
                    }}
                    type="secondary"
                />
            ),
            table: <AohTable
                tableData={minorDeviationTableData}
                tableColumns={minorDeviationColumns} />,
        },
        "GXP Training": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary}
                />
            ),
            heatmapTitle: "Compliance Rate (%)",
            heatmap: (
                <ColorCodedTable
                    data={heatmapData}
                    metricKey="gxp"
                />
            )
        },
        "CAPA Status": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary}
                />
            ),
            title: "Trend of Daily Coming Due CAPA's with Volume",
            subtitle: "In last 6 months",
            stackBar:
                <StackedBarChart data={graphData} chartType="capa-status"
                    xAxisData={graphData?.map((item: any) => formatMTDDate(item))}
                    yAxisNames={[
                        { name: "TOTAL OPEN CAPAS", position: "left", max: maxValues?.Max },
                        { name: "TREND OF DAILY COMING CAPAS", position: "right", max: maxValues?.Max },
                    ]}
                />,
            title2: "CAPA",
            radioFilters: (
                <RadioFilters
                    timePeriod={minDevTableTimePeriod}
                    setTimePeriod={setMinDevTableTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            button: (
                <Button
                    label="Download"
                    className="download-btn"
                    icon={
                        <DownloadOutlined style={{ color: "#0063C3", fontSize: "14px" }} />
                    }
                    iconPosition="right"
                    onClick={() => {
                        if (CapaStatusTableData && CapaStatusColumns) {
                            const csvContent = generateCSV(CapaStatusTableData, CapaStatusColumns);
                            downloadCSV(csvContent, "CAPA_Status_Data.csv");
                        } else {
                            console.error("No data or columns available for download");
                        }
                    }}
                    type="secondary"
                />
            ),
            table: <AohTable
                tableData={CapaStatusTableData}
                tableColumns={CapaStatusColumns} />,
            title3: "CAPA EV",
            radioFilters3: (
                <RadioFilters
                    timePeriod={minDevTable2TimePeriod}
                    setTimePeriod={setMinDevTable2TimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            button3: (
                <Button
                    label="Download"
                    className="download-btn"
                    icon={<DownloadOutlined />}
                    iconPosition="right"
                    onClick={() => {
                        if (CapaEVTableData && CapaEVColumns) {
                            const csvContent = generateCSV(CapaEVTableData, CapaEVColumns);
                            downloadCSV(csvContent, "CapaEV_Status_Data.csv");
                        } else {
                            console.error("No data or columns available for download");
                        }
                    }}
                    type="secondary"
                />
            ),
            table3: <AohTable
                tableData={CapaEVTableData}
                tableColumns={CapaEVColumns} />
        },
        "Change Control": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary}
                />
            ),
            title: "Trend of Daily Coming Due Change Controls with Volume",
            subtitle: "In last 7 days",
            stackBar:
                <StackedBarChart
                    data={graphData}
                    chartType="change-control-status"
                    xAxisData={graphData?.map((item: any) => formatMTDDate(item))}
                    yAxisNames={[
                        { name: "TOTAL OPEN CHANGE CONTROLS", position: "left", max: maxValues?.Max },
                        { name: "TREND OF DAILY\nCOMING DUE CHANGE CONTROLS", position: "right", max: maxValues?.Max },
                    ]}
                />,
            title2: "Change Control",
            radioFilters: (
                <RadioFilters
                    timePeriod={minDevTableTimePeriod}
                    setTimePeriod={setMinDevTableTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            button: (
                <Button
                    label="Download"
                    className="download-btn"
                    icon={
                        <DownloadOutlined style={{ color: "#0063C3", fontSize: "14px" }} />
                    }
                    iconPosition="right"
                    onClick={() => {
                        if (changeControlTableData && ChangeControlColumns) {
                            const csvContent = generateCSV(changeControlTableData, ChangeControlColumns);
                            downloadCSV(csvContent, "Change_Control_Status_Data.csv");
                        } else {
                            console.error("No data or columns available for download");
                        }
                    }}
                    type="secondary"
                />
            ),
            table: <AohTable
                tableData={changeControlTableData}
                tableColumns={ChangeControlColumns} />
        },
        "Right First Time": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary}
                />
            ),
            component: (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "1em" }}>
                    <div>
                        <h2 className="tier-title">Number Of pending Exception In Each Line</h2>
                        <HorizontalBarChart
                            data={(barGraphData?.pendingExceptions || []) as StringKeyDataProps[]}
                            legendItems={rftChartLegend}
                            config={{
                                isConditionalColor: true,
                                conditionalColor: (item: any) => ({
                                    value: (item.value) || 0,
                                    itemStyle: {
                                        color: item.value > 6 ? '#E42E44' : "#0063C3"
                                    }
                                }),
                                markLine: {
                                    symbol: "none",
                                    lineStyle: {
                                      color: "#E42E44",
                                      type: "solid",
                                    },
                                    data: [
                                      {
                                        name: "TARGET LINE",
                                        xAxis: 10,
                                      },
                                    ],
                                  },
                                width: 560,
                                height: 344,
                                xAxisKey: "value",
                                yAxisKey: "name",
                                hideXAxisTicks: true,
                                hideYAxisTicks: true,
                                showYAxisLine: false
                            }} />
                    </div>
                    <div>
                        <h2 className="tier-title">Deviation In Each Line/Batch</h2>
                        <HorizontalBarChart
                            data={(barGraphData?.batchDeviations || []) as StringKeyDataProps[]}
                            legendItems={rftChartLegend}
                            config={{
                                isConditionalColor: true,
                                conditionalColor: (item: any) => ({
                                    value: (item.value) || 0,
                                    itemStyle: {
                                        color: item.value > 10 ? '#E42E44' : undefined
                                    }
                                }),
                                markLine: {
                                    symbol: "none",
                                    lineStyle: {
                                      color: "#E42E44",
                                      type: "solid",
                                    },
                                    data: [
                                      {
                                        name: "TARGET LINE",
                                        xAxis: 10,
                                      },
                                    ],
                                  },
                                width: 560,
                                height: 344,
                                xAxisKey: "value",
                                yAxisKey: "name",
                                hideXAxisTicks: true,
                                hideYAxisTicks: true,
                                showYAxisLine: false
                            }} />
                    </div>
                </div>
            ),

            title4: "Exception Pending Acknowledgement",
            lineGraphRadioFilters: (
                <BlockRadioFilters
                    setRadioFilter={setRftGraphViewBy}
                    filterLabel="VIEW BY"
                    radioFilter={rftViewByArr?.map((item) => ({ label: item, value: item }))}
                />
            ),
            lineGraph: (
                <StackedBarChart
                    data={rftLineGraphDataMap?.[rftGraphViewBy] || []}
                    chartType="rft"
                    xAxisData={rftLineGraphDataMap?.[rftGraphViewBy]?.map((item: any) => item.name)}
                    yAxisNames={[
                        { name: "", position: "left", max: undefined },
                    ]}
                />
            ),
            radioFilters: (
                <RadioFilters
                    timePeriod={rftTableTimePeriod}
                    setTimePeriod={setRftTableTimePeriod}
                    tierLabel={tierLabel}
                    customLabel="TIME PERIOD"
                    timePeriodData={rftLineArr}
                />
            ),
            table: <AohTable
                tableData={rftTableDataMap?.[rftTableTimePeriod] || []}
                tableColumns={rftColumnsTier2} />
        }
    }

    return (
        <div className="quality-tier-main">
            <div className="tier-container">
                <h2 className="tier-title">
                    {/* Summary card */}
                    {qualityDeepDive[activeKeyDeepDiveTab]?.summaryTitle}
                </h2>

            </div>
            {qualityDeepDive[activeKeyDeepDiveTab]?.summary}
            <div
                className={
                    qualityDeepDive[activeKeyDeepDiveTab]?.summary ? "mr-bottom-20" : ""
                }
            ></div>
            {/* Heatmap Card */}
            <div className="tier-container">
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.heatmapTitle}
                </h2>
            </div>
            {/* main heatmap component */}
            {qualityDeepDive[activeKeyDeepDiveTab]?.heatmap}
            <div
                className={
                    qualityDeepDive[activeKeyDeepDiveTab]?.heatmap ||
                        qualityDeepDive[activeKeyDeepDiveTab]?.table
                        ? "mr-bottom-20"
                        : ""
                }
            ></div>
            <div className="tier-container">
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.title}
                </h2>
            </div>
            <div className="tier-subtitle">
                {qualityDeepDive[activeKeyDeepDiveTab]?.subtitle}
            </div>
            {qualityDeepDive[activeKeyDeepDiveTab]?.stackBar}
            {qualityDeepDive[activeKeyDeepDiveTab]?.component}
            <div className="tier-container" style={{ marginBottom: "1em" }}>
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.title4}
                </h2>
            </div>
            {qualityDeepDive[activeKeyDeepDiveTab]?.lineGraphRadioFilters}
            {qualityDeepDive[activeKeyDeepDiveTab]?.lineGraph}
            <div className="tier-container">
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.title2}
                </h2>
                {qualityDeepDive[activeKeyDeepDiveTab]?.button}
            </div>
            <div className="tier-subtitle">
                {qualityDeepDive[activeKeyDeepDiveTab]?.subtitle2}
            </div>
            {qualityDeepDive[activeKeyDeepDiveTab]?.radioFilters}
            {qualityDeepDive[activeKeyDeepDiveTab]?.table}
            <div className="tier-container" key={'ev'}>
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.title3}
                </h2>
                {qualityDeepDive[activeKeyDeepDiveTab]?.button3}
            </div>
            <div className="tier-subtitle">
                {qualityDeepDive[activeKeyDeepDiveTab]?.subtitle3}
            </div>
            {qualityDeepDive[activeKeyDeepDiveTab]?.radioFilters3}
            {qualityDeepDive[activeKeyDeepDiveTab]?.table3}
        </div>
    )
}
export default QualityTier2
