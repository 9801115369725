import { configureStore } from '@reduxjs/toolkit';
import tierInfoReducer from 'reducers/tierInfoReducer';
import calendarReducer from 'reducers/safety-base-view/calendarReducer';
import workOrderReducer from 'reducers/safety-base-view/workOrderReducer';
import seeCardReducer from 'reducers/safety-base-view/seeCardsReducer';
import incaReducer from 'reducers/safety-base-view/incaReducer';
import capaReducer from 'reducers/safety-base-view/capaReducer';
import ehssReducer from 'reducers/safety-base-view/ehssReducer';
import lineInfoReducer from 'reducers/lineInfoReducer';

// deep dive
import seeCardsDeepDiveReducer from 'reducers/safety-deep-dive/seeCardsDeepDiveReducer';
import incaDeepDiveReducer from 'reducers/safety-deep-dive/incaDeepDiveReducer';
import workOrderDeepDiveReducer from 'reducers/safety-deep-dive/workOrderReducer';
import ehssDeepDiveReducer from 'reducers/safety-deep-dive/ehssReducer';
import capaDeepDiveReducer from 'reducers/safety-deep-dive/capaDeepDiveReducer';
import qualityChangeControlReducer from 'reducers/quality-base-view/qualityChangeControlReducer'
import qualityCAPAReducer from 'reducers/quality-base-view/qualityCAPAReducer'
import overallSummaryReducer from 'reducers/sqdipSummaryReducer';
import minorDeviationReducer from 'reducers/quality-base-view/minorDeviation';
import majorDeviationReducer from 'reducers/quality-base-view/majorDeviation'
import gmpTrainingReducer from 'reducers/quality-base-view/gmpReducer'
import deepdiveSummaryReducer from 'reducers/safety-deep-dive/deepdiveSummaryReducer';
import minorDeviationDeepDiveReducer from 'reducers/quality-deep-dive/minorDevDeepDiveReducer';
import majorDeviationDeepDiveReducer from 'reducers/quality-deep-dive/majorDevDeepDiveReducer'
import customMetricParamsReducer from 'reducers/customMetricParamsReducer'
import overallSummaryDeepdiveReducer from 'reducers/sqdipSummaryDeepdiveReducer';
import gxpDeepDiveReducer from 'reducers/quality-deep-dive/gxpReducer';
import changeControlDeepDiveReducer from "reducers/quality-deep-dive/changeControlDeepDiveReducer";
import qualityRFTReducer from 'reducers/quality-base-view/qualityRFTReducers';
import qualityCapaDeepDiveReducer from 'reducers/quality-deep-dive/qualityCapaDeepDiveReducer';
import oneClickReportReducer from 'reducers/oneClickReportReducer';
import agingWorkorderReducer from 'reducers/delivery-base-view/agingWorkorder';
import pmAdherenceReducer from 'reducers/delivery-base-view/pmAdherence'
import deliveryCalibrationReducers from 'reducers/delivery-base-view/deliveryCalibrationReducers';
import batchDispositionReducer from 'reducers/delivery-base-view/batchDispositionReducer';
import OnTimeScheduleAdherenceBVReducer from 'reducers/delivery-base-view/onTimeScheduleAdherenceReducer'

// delivery deep dive
import wctNotesReducer from 'reducers/wctNotesReducer';
import addWctNotesReducer from 'reducers/addWctNotesReducer';
import pmAdherenceDeepDiveReducer from 'reducers/delivery-deep-dive/pmAdherenceDeepDiveReducer';
import CalClosureAdherenceDeepDiveReducer from 'reducers/delivery-deep-dive/calClosureAdherenceDeepDiveReducer';
import agingWoDeepDiveReducer from 'reducers/delivery-deep-dive/agingWoDeepDiveReducer';
import qualityDdRFTReducer from 'reducers/quality-deep-dive/qualityRFTReducers';
import batchDispositionDeepDiveReducer from 'reducers/delivery-deep-dive/batchDispositionDeepDiveReducer';

// Inventory Base View
import turnOverReducer from 'reducers/inventory-base-view/turnOver'
import criticalMfcReducer from 'reducers/inventory-base-view/criticalMfc'
import kanbanHealthBVReducer from 'reducers/inventory-base-view/kanbanHealthBVReducer';

// Inventory Deep Dive View
import criticalMfcDeepDiveReducer from 'reducers/inventory-deep-dive/criticalMfc';

const store = configureStore({
  reducer: {
    tierInfo: tierInfoReducer,
    calendar: calendarReducer,
    workOrder: workOrderReducer,
    seeCardsInfo: seeCardReducer,
    incaInfo: incaReducer,
    capaInfo: capaReducer,
    ehssInfo: ehssReducer,
    lineInfo: lineInfoReducer,
    seeCardsDeepDive: seeCardsDeepDiveReducer,
    incaDeepDive: incaDeepDiveReducer,
    workOrderDeepDive: workOrderDeepDiveReducer,
    ehssDeepDive: ehssDeepDiveReducer,
    capaDeepDive: capaDeepDiveReducer,
    qualityChangeControlInfo: qualityChangeControlReducer,
    qualityCAPAInfo: qualityCAPAReducer,
    overallSummary: overallSummaryReducer,
    deepdiveSummary: deepdiveSummaryReducer,
    minorDeviation: minorDeviationReducer,
    majorDeviation: majorDeviationReducer,
    gmpTraining: gmpTrainingReducer,
    minorDeviationDeepDive: minorDeviationDeepDiveReducer,
    majorDeviationDeepDive: majorDeviationDeepDiveReducer,
    customMetricParams: customMetricParamsReducer,
    overallSummaryDeepdive: overallSummaryDeepdiveReducer,
    qualityGxpDeepDiveInfo: gxpDeepDiveReducer,
    changeControlDeepdiveInfo: changeControlDeepDiveReducer,
    qualityRFTInfo: qualityRFTReducer,
    qualityCapaDeepDiveInfo: qualityCapaDeepDiveReducer,
    oneClickReport: oneClickReportReducer,
    agingWorkorder: agingWorkorderReducer,
    pmAdherence: pmAdherenceReducer,
    deliveryCalibrationInfo: deliveryCalibrationReducers,
    batchDisposition: batchDispositionReducer,
    pmAdherenceDeepDive: pmAdherenceDeepDiveReducer,
    onTimeScheduleAdherence: OnTimeScheduleAdherenceBVReducer,
    qualityDdRFT: qualityDdRFTReducer,
    wctNotes: wctNotesReducer,
    addWctNotes: addWctNotesReducer,
    calClosureAdherenceDeepDive: CalClosureAdherenceDeepDiveReducer,
    agingWoDeepDive: agingWoDeepDiveReducer,
    turnOver: turnOverReducer,
    criticalMfc: criticalMfcReducer,
    kanbanHealthBV: kanbanHealthBVReducer,
    batchDispositionDeepDive: batchDispositionDeepDiveReducer,
    criticalMfcDeepDive: criticalMfcDeepDiveReducer
  }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
