import "./Inventory.scss";

interface InventoryTier0Props {
  data: any;
  tierLabel: string;
  activeKeyDeepDiveTab: string;
}

const InventoryTier0: React.FC<InventoryTier0Props> = ({
  data,
  tierLabel,
  activeKeyDeepDiveTab,
}) => {
  const deliveryDeepDive: any = {
    
  }
  
  return (
    <div className="delivery-tier-main">
    </div>
  );
};
export default InventoryTier0;
