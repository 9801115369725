import "./Inventory.scss";

interface InventoryTier1Props {
  data: any;
  tierLabel: string;
  activeKeyDeepDiveTab: string;
}

const InventoryTier1: React.FC<InventoryTier1Props> = ({
  data,
  tierLabel,
  activeKeyDeepDiveTab,
}) => {
  const deliveryDeepDive: any = {
    
  }
  
  return (
    <div className="delivery-tier-main">
    </div>
  );
};
export default InventoryTier1;
