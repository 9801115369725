import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BaseViewCardPropsType, FetchDataParams, QueryParamsPropsType } from 'types/wctTypes';
import { filterParams, getOktaToken } from 'utils/common-methods';
import { SEE_CARDS_URL } from 'utils/constants';
import { StringKeyDataProps } from 'utils/data-types';

interface SeeCardsOverdueItem {
    see_cards_last_24hours?: number | string;
    total_overdue?: number | string;
    total_coming_due_in_5days?: number | string;
    total_coming_due_in_2days?: number | string;
}

interface OPDItem {
    date?: string;
    see_card_last_5_days?: number | string;
    see_card_last_30_days?: number | string;
}

interface AreaAlertItem {
    count?: number | string;
    employee_cost_center_name?: string;
    see_card_count?: number | string;
}

interface SeeCardsDataType {
    overdues: SeeCardsOverdueItem
    overduesPerDay: OPDItem[];
    areaAlert: AreaAlertItem[]
    query_params: QueryParamsPropsType
}

interface TransformedData {
    seeCards: SeeCardsDataType;
}

interface SeeCardsInfoState {
    seeCardsInfo: BaseViewCardPropsType | null;
    loading: boolean;
    error: boolean;
}

const initialState: SeeCardsInfoState = {
    seeCardsInfo: null,
    loading: false,
    error: false,
}

const fetchSeeCards = createAsyncThunk<SeeCardsDataType, FetchDataParams>(
    SEE_CARDS_URL,
    async (params) => {
        const filteredParams = filterParams(params)
        const token: string = getOktaToken();

        const response = await fetch(`${process.env.REACT_APP_API_URL}${SEE_CARDS_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch seeCards');
        }
        return await response.json();
    }
)

const convertSeeCardData = (data: OPDItem[]): StringKeyDataProps[] => {
    return data.map((item) => {
        const date = new Date(item?.date ?? "");
        const options: Intl.DateTimeFormatOptions = {
            month: "short",
            day: "2-digit",
        };
        const name = date
            .toLocaleDateString("en-US", options)
            .replace(/\s/g, " ")
            .toUpperCase();
        const val = item.see_card_last_5_days === ""
            ? item.see_card_last_30_days
            : item.see_card_last_5_days
        return {
            name,
            value: val ?? 0
      };
    });
}

const transformData = (seeData: SeeCardsDataType): BaseViewCardPropsType => {
    const seeBaseViewData: BaseViewCardPropsType = {
        cardData: {},
        graphData: [],
        graphParams: {},
        cardProps: {
            alertType: 'success'
        }
    }

    const tierLabel = seeData.query_params?.tierLabel

    const seeCardsSanitizedData: StringKeyDataProps = {};
    let seeCardOverDue;
    let seeCardsLast24hours;
    let seeCardsComingDueIn5Day;
    let seeCardsComingDueIn2Day;
    if (seeData?.overdues && Object.keys(seeData?.overdues)?.length > 0) {
        seeCardOverDue = seeData?.overdues?.total_overdue;
        seeCardsLast24hours =
            seeData?.overdues?.see_cards_last_24hours;
        seeCardsComingDueIn5Day =
            seeData?.overdues?.total_coming_due_in_5days;
        seeCardsComingDueIn2Day =
            seeData?.overdues?.total_coming_due_in_2days;
    }

    if (typeof seeCardOverDue === "number" && seeCardOverDue >= 0) {
        seeCardsSanitizedData.OVERDUE = seeCardOverDue;
    }

    if (seeData?.areaAlert) {
        if (
            typeof seeData?.areaAlert[0]?.count === "number" &&
            seeData?.areaAlert[0]?.count >= 0
        ) {
            const alertArea = seeData?.areaAlert[0]?.count;
            seeCardsSanitizedData["AREA ALERT"] = alertArea;
        } else {
            seeCardsSanitizedData["AREA ALERT"] = "";
            if (seeData?.areaAlert[0]?.employee_cost_center_name) {
                seeCardsSanitizedData[
                    seeData?.areaAlert[0]?.employee_cost_center_name
                ] = seeData?.areaAlert[0]?.see_card_count ?? 0;
            }
            if (seeData?.areaAlert[1]?.employee_cost_center_name) {
                seeCardsSanitizedData[
                    seeData?.areaAlert[1]?.employee_cost_center_name
                ] = seeData?.areaAlert[1]?.see_card_count ?? 0;
            }
        }
    }

    if (typeof seeCardsLast24hours === "number" && seeCardsLast24hours >= 0) {
        seeCardsSanitizedData["SEE CARDS LAST 24 HOURS"] = seeCardsLast24hours;
    }

    if (
        typeof seeCardsComingDueIn5Day === "number" &&
        seeCardsComingDueIn5Day >= 0
    ) {
        seeCardsSanitizedData["COMING DUE IN 5 DAYS"] = seeCardsComingDueIn5Day;
    }

    if (
        typeof seeCardsComingDueIn2Day === "number" &&
        seeCardsComingDueIn2Day >= 0
    ) {
        seeCardsSanitizedData["COMING DUE (2 DAYS)"] = seeCardsComingDueIn2Day;
    }

    seeBaseViewData.cardData = seeCardsSanitizedData

    if (
        tierLabel?.toLowerCase() === "tier 1" ||
        tierLabel?.toLowerCase() === "tier 2"
    ) {
        seeBaseViewData.graphParams.tooltipContent = "Number of SEE Cards Created in last 5 days"
    } else if (tierLabel?.toLowerCase() === "tier 3") {
        seeBaseViewData.graphParams.tooltipContent = "Number of SEE Cards Created in last 30 days"
    }

    // if (
    //     (tierLabel?.toLowerCase() === "tier 1" ||
    //         tierLabel?.toLowerCase() === "tier 2") &&
    //     typeof seeCardOverDue === "number" &&
    //     seeCardOverDue > 0
    // ) {
    //     seeBaseViewData.cardProps.alertType = "error"
    // } else {
    //     seeBaseViewData.cardProps.alertType = "success"
    // }

    if (
        tierLabel?.toLowerCase() !== "tier 0" &&
        seeData?.overduesPerDay?.length
    ) {
        const inputData1: OPDItem[] = seeData?.overduesPerDay;
        const outputData: StringKeyDataProps[] = convertSeeCardData(inputData1);
        seeBaseViewData.graphData = outputData
        seeBaseViewData.graphParams.isGraph = true
    } else {
        seeBaseViewData.graphData = []
        seeBaseViewData.graphParams.isGraph = false
    }

    return seeBaseViewData
}

const seeCardsReducer = createSlice({
    name: 'seeCards',
    initialState,
    reducers: {
        clearSeeCardsData(state) {
            state.seeCardsInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSeeCards.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchSeeCards.fulfilled, (state, action) => {
                state.loading = false;
                state.seeCardsInfo = transformData(action.payload);
            })
            .addCase(fetchSeeCards.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export { fetchSeeCards };

export const { clearSeeCardsData } = seeCardsReducer.actions

export default seeCardsReducer.reducer;