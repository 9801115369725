import AohTable from "../../common/table/AohTable";
import SummaryCard from "../../common/summaryCard/summaryCard";
import "./Delivery.scss";
import { batchDispositionDeepDiveFilter, batchDispositionDeepDiveTableColumns, DataTypeKey, DEEP_DIVE_UI_CONSTANTS, DeliveryAgingTableColumnsType } from "utils/wctConstants";
import { APIResponseDataType, SummaryType, TableType } from "types/wctTypes";
import { filterbyDataType } from "utils/common-methods";
import { BlockRadioFilters } from "components/resuable/radio-filters/BlockRadioFilters";
import { useEffect, useState } from "react";
import { clearAgingWoViewByData, fetchAgingWoViewByData } from "reducers/delivery-deep-dive/agingWoDeepDiveReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { StringKeyDataProps } from "utils/data-types";
import ErrorHandler from "utils/error-handler";
import RenderTierWiseComponent from "./RenderTierWiseComponent";
import { fetchBatchDispositionFilterData } from "reducers/delivery-deep-dive/batchDispositionDeepDiveReducer";

interface DeliveryTier1Props {
    data: any;
    tierLabel: string;
    activeKeyDeepDiveTab: string;
}

const DeliveryTier1: React.FC<DeliveryTier1Props> = ({
    data,
    tierLabel,
    activeKeyDeepDiveTab
}) => {
    const dispatch = useDispatch<AppDispatch>()

    const { agingWoViewByData, loading, error } = useSelector((state: RootState) => state.agingWoDeepDive)

    const [cmFilterType, setCmFilterType] = useState<string>("cm")
    const [scheduledFilterType, setScheduledFilterType] = useState<string>("schedule")
    const [filter, setFilter] = useState<any>({})

    const summary: APIResponseDataType = filterbyDataType<SummaryType>(data, DataTypeKey.IsSummary)[0]
    const allTables: APIResponseDataType[] = filterbyDataType<TableType>(data, DataTypeKey.IsTable)
    const agingWoTable = allTables[0]

    const [tableData, setTableData] = useState<StringKeyDataProps[]>(agingWoTable?.data as StringKeyDataProps[])

    const cmFilter = agingWoTable?.dataParams?.dataFilter.filter((filter: any) => filter.filterType === "cm-gm")?.[0]
    const scheduledFilter = agingWoTable?.dataParams?.dataFilter.filter((filter: any) => filter.filterType === "schedule-unschedule")?.[0]

    const deliveryDeepDive: any = {
        "Aging Work Orders": {
            summaryTitle: DEEP_DIVE_UI_CONSTANTS.SUMMARY,
            summary: (<SummaryCard data={summary?.data} />),
            title: agingWoTable?.dataParams?.title,
            subtitle: agingWoTable?.dataParams?.subtitle,
            blockRadioFilters1: (
                <BlockRadioFilters
                    filterLabel={cmFilter?.filterLabel}
                    radioFilter={cmFilter?.filters}
                    setRadioFilter={setCmFilterType}
                />
            ),
            blockRadioFilters2: (
                <BlockRadioFilters
                    filterLabel={scheduledFilter?.filterLabel}
                    radioFilter={scheduledFilter?.filters}
                    setRadioFilter={setScheduledFilterType}
                />
            ),
            table: <AohTable tableData={tableData} tableColumns={DeliveryAgingTableColumnsType} />,
        }
    }

    useEffect(() => {
        const viewBy = `${scheduledFilterType}_${cmFilterType}` === "unschedule_gm" ? "schedule_cm" : `${scheduledFilterType}_${cmFilterType}`
        const params = {
            workStream: "manufacturing",
            site: "AOH",
            persona: "wct",
            tierNumber: "tier 1",
            viewBy
        }

        dispatch(clearAgingWoViewByData())
        dispatch(fetchAgingWoViewByData(params));
    }, [dispatch, cmFilterType, scheduledFilterType]);

    useEffect(() => {
        if (agingWoViewByData) {
            setTableData(agingWoViewByData[0].data as StringKeyDataProps[])
        }
    }, [agingWoViewByData])

    const handleRadioFilter = (filterType: any, nextValues: any) => {
        setFilter((prev: any) => ({ ...prev, [filterType]: nextValues }))
        const params = {
            workStream: "manufacturing",
            site: "AOH",
            persona: "wct",
            tierNumber: "tier 1",
            filterType: filterType,
            filterValue: nextValues

        }
        dispatch(fetchBatchDispositionFilterData(params as any));
    }

    if (activeKeyDeepDiveTab === "Batch Disposition") {
        return (<div className="delivery-tier-main">
            {data?.map((
                item: any, index: number | null | undefined) => {
                return <RenderTierWiseComponent setTableType={handleRadioFilter} activeKeyDeepDiveTab={activeKeyDeepDiveTab} tierLabel={tierLabel} key={index} data={item} activeFilter={filter} deepDiveFilters={batchDispositionDeepDiveFilter} deepDiveTableColumns={batchDispositionDeepDiveTableColumns} />
            })}
        </div>)
    } else {
        return (
            <div className="delivery-tier-main">
                <div className="tier-container">
                    <h2 className="tier-title">
                        {/* Summary card */}
                        {deliveryDeepDive[activeKeyDeepDiveTab]?.summaryTitle}
                    </h2>
                </div>
                {deliveryDeepDive[activeKeyDeepDiveTab]?.summary}
                <div
                    className={
                        deliveryDeepDive[activeKeyDeepDiveTab]?.summary ? "mr-bottom-20" : ""
                    }
                ></div>
                <div className="tier-container">
                    <h2 className="tier-title">
                        {deliveryDeepDive[activeKeyDeepDiveTab]?.title}
                    </h2>
                </div>
                <div className="tier-subtitle">
                    {deliveryDeepDive[activeKeyDeepDiveTab]?.subtitle}
                </div>
                <div className="tier-buttons-container">
                    <div className="tier-buttons-left">
                        {deliveryDeepDive[activeKeyDeepDiveTab]?.blockRadioFilters1}
                    </div>
                    <div className="tier-buttons-right">
                        {cmFilterType !== "gm" && deliveryDeepDive[activeKeyDeepDiveTab]?.blockRadioFilters2}
                    </div>
                </div>
                {loading || error ? (
                    <div className="loader">
                        <ErrorHandler isLoading={loading} isError={error} loaderColor={'BLUE'} />
                    </div>
                ) : (
                    deliveryDeepDive[activeKeyDeepDiveTab]?.table
                )}
            </div>
        );
    }
};
export default DeliveryTier1;
