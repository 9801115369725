import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BaseViewCardPropsType, FetchDataParams, QueryParamsPropsType } from 'types/wctTypes';
import { filterParams, getMonthName, getOktaToken, setData } from 'utils/common-methods';
import { BATCH_DISPOSIOTION_URL } from 'utils/constants';
import { StringKeyDataProps } from 'utils/data-types';

interface BatchDispoitionDataItem {
  overdue?: number | string;
  manager_name?: string;
  manager_staff_id?: string;
  pending?: number | string;
  target?: number | string;
  la_name?: string;
  la_code?: string;
  cost_center_name?: number | string;
  cost_center_code?: number | string;
}

interface BatchDispoitionCompliancePercentage {
  compliance_percentage?: number | string;

  shift_compliance?: number | string;
  cost_center_compliance?: number | string;
}
interface BatchDispoitionSiteCompliance {
  aoh_compliance?: number | string;
}

interface BatchDispoitionGraphItem {
  compliance_date?: string;
  cumulative_overdue?: number | string;
  cumulative_on_track?: number | string;
  compliance_rate?: number | string;
}

interface BatchDispoitionDataType {
  compliance_percentage: BatchDispoitionCompliancePercentage;
  site_compliance: BatchDispoitionSiteCompliance;
  data: BatchDispoitionDataItem[];
  target: string;
  graph_data: BatchDispoitionGraphItem[]
  query_params: QueryParamsPropsType
}

interface BatchDispoitionInfoState {
  batchDispoitionInfo: BaseViewCardPropsType | null;
  loading: boolean;
  error: boolean;
}

const initialState: BatchDispoitionInfoState = {
  batchDispoitionInfo: null,
  loading: false,
  error: false,
}

const fetchBatchDispoition = createAsyncThunk<BatchDispoitionDataType, FetchDataParams>(
  BATCH_DISPOSIOTION_URL,
  async (params) => {
    const filteredParams = filterParams(params)
    const token: string = getOktaToken();

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${BATCH_DISPOSIOTION_URL}?${new URLSearchParams(filteredParams).toString()}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch batchDispoition');
    }
    return await response.json();
  }
)

const batchDispoitionConvertedData = (data: BatchDispoitionGraphItem[]): StringKeyDataProps[] => {
  const sortedData = [...data].sort((a, b) => {
    if (
      typeof a.compliance_date === "string" &&
      typeof b.compliance_date === "string"
    ) {
      const prevDate = new Date(a.compliance_date);
      const nextDate = new Date(b.compliance_date);
      const monthDifference = prevDate.getMonth() - nextDate.getMonth();
      if (monthDifference !== 0) {
        return monthDifference;
      }
      return prevDate.getDate() - nextDate.getDate();
    }
    return 0;
  });
  const datas = sortedData.map((item) => {
    if (typeof item.compliance_date === "string") {
      const dateFormat = new Date(item.compliance_date).toLocaleString(
        "en-US",
        { month: "short", day: "numeric" }
      );
      return {
        name: dateFormat,
        value: item.compliance_rate ?? 0,
      };
    }
    return { name: "", value: "" };
  });
  return datas;
};

const transformData = (batchDispoitionData: any[]): BaseViewCardPropsType => {
  const batchDispoitionBaseViewData: BaseViewCardPropsType = {
    cardData: {},
    graphData: [],
    graphParams: {},
    cardProps: {
      highLightedParams: [],
      alertType: 'success'
    }
  }

  for (let i = 0; i < batchDispoitionData.length; i++) {
    const item = batchDispoitionData[i];
    if (item.isCard) {
      batchDispoitionBaseViewData.cardData = setData(item.data)
      if (item.highLightedParams) { batchDispoitionBaseViewData.cardProps.highLightedParams = item.highLightedParams }
    }

    if (item.isTrendGraph) {
      batchDispoitionBaseViewData.graphParams.tooltipContent = item?.tooltipContent;
      batchDispoitionBaseViewData.trendGraphData = item.data?.map((item: any) => {
        const date = new Date(item.compliance_date as string);
        let formattedDate = ''
        const monthName = getMonthName(date.getMonth());
        if (item.compliance_date.length > 5) {
          formattedDate = `${monthName} ${date.getFullYear().toString().slice(-2)}`;
        } else {
          formattedDate = `${monthName} ${date.getDate().toString().padStart(2, '0')}`;
        }
        const value =
          typeof item?.compliance_rate === "number"
            ? item?.compliance_rate.toFixed(2)
            : "0.00";
        return {
          name: formattedDate,
          value
        }
      })
    }
  }

  return batchDispoitionBaseViewData
}

const batchDispoitionReducer = createSlice({
  name: 'batchDispoition',
  initialState,
  reducers: {
    clearBatchDispoitionData(state) {
      state.batchDispoitionInfo = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBatchDispoition.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchBatchDispoition.fulfilled, (state, action) => {
        state.loading = false;
        state.batchDispoitionInfo = transformData(action.payload as any)
      })
      .addCase(fetchBatchDispoition.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });

  },
});

export { fetchBatchDispoition };

export const { clearBatchDispoitionData } = batchDispoitionReducer.actions

export default batchDispoitionReducer.reducer;