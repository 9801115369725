import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { Empty } from "antd";
import Label from "components/resuable/label";
import { generateChartOptions, getLegendItems } from "./utils";
import { layoutConfig, leftAxisConfig, tooltipConfig } from "./chartConfig";
import { getSeriesData } from "./seriesData";
import { StackedBarChartProps } from "./constants";

const StackedBarChart: React.FC<StackedBarChartProps> = ({
  data,
  chartType,
  timePeriod,
  xAxisData, // Destructure xAxisData prop
  yAxisNames, // Destructure yAxisNames prop
  nameGapCustom,
  chartConfig,
  yAxisMax,
  showSlider,
  grid
}) => {
  const [chartOptions, setChartOptions] = useState({});
  const [isChartReady, setIsChartReady] = useState(false);

  useEffect(() => {
    if (chartType === "see-cards" || chartType === "inca") {
      const options = {
        tooltip: tooltipConfig,
        grid: {
          ...layoutConfig
        },
        xAxis: {
          type: "category",
          data: data?.map((item) => item.date),
          axisTick: { show: false },
          axisLabel: {
            ...leftAxisConfig
          }
        },
        yAxis: {
          type: "value",
          axisLabel: {
            ...leftAxisConfig
          }
        },
        dataZoom: [
          {
            type: "inside",
            zoomLock: true,
            borderWidth: 12, // Border width of the slider track area
            borderColor: "#F0F0F0",
          },
          {
            type: "slider",
            show: chartType === "inca" && timePeriod !== "Last Week",
            showDetail: false,
            backgroundColor: "#f0f0f0",
            fillerColor: "#217ecf",
            // borderRadius: 12,
            borderColor: "transparent", // Border color of the slider track area
            borderRadius: 12,
            height: 20,
            handleStyle: {
              color: "#175891",
              borderRadius: 5,
              borderColor: "transparent",
              shadowColor: "transparent",
              // opacity: 0,
            },
            dataBackground: {
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                opacity: 0,
              },
            },
            brushSelect: false,
            brushStyle: {
              // opacity: 0,
            },
          },
        ],
        series: getSeriesData(chartType, data),
      };
      setChartOptions(options);
      setIsChartReady(true);
    } else {
      const options = generateChartOptions(chartType, data, xAxisData, yAxisNames, nameGapCustom, chartConfig, yAxisMax, showSlider, grid);
      setChartOptions(options);
      setIsChartReady(true);
    }
  }, [data, chartType]);

  const legendItems = chartConfig?.filter((d: any) => d.statusValue !== "") ?? getLegendItems(data, chartType);

  return (
    <div style={{ width: "100%", marginLeft: "5px" }}>
      {data && data.length > 0 ? (
        <>
          {isChartReady && <ReactECharts option={chartOptions} />}
          {legendItems.length > 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: showSlider ? "-8px auto 16px auto" : "-40px auto 16px auto"
                }}
              >
                {legendItems.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "16px",
                    }}
                  >
                    <Label
                      StatusValue={item.statusValue}
                      circleColor={item.circleColor}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No Data Available"
        />
      )}
    </div>
  );
};

export default StackedBarChart;
