import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BaseViewCardPropsType, BaseViewPieChartType, FetchDataParams, ProgressBarType, QueryParamsPropsType } from 'types/wctTypes';
import { filterParams, getOktaToken } from 'utils/common-methods';
import { PM_ADHERENCE_URL } from 'utils/constants'
import { StringKeyDataProps } from 'utils/data-types';

interface CardDataType {
    overdue_pm_wo?: number | string;
    mfg_closed_pm_total?: number | string;
    non_mfg_closed_pm_total?: number | string;
    overdue_pm_wos?: number | string;
    percentage_of_pm_wo_closure_adherence_for_the_month_mfg?: number | string;
    percentage_of_pm_wo_closure_adherence_for_the_month_non_mfg?: number | string;
    pm_wos_total_cm_gm_wos_ratio?: number | string;
}

interface GraphDataType {
    total_man_hrs_consumed_for_pm_mfg_last_7days?: number | string;
    planned_non_comm_hrs_for_pm_mfg_last_7days?: number | string;
    remaining_time?: number | string;
    no_of_pm_percentage?: number | string;
    no_of_cm_percentage?: number | string;
    no_of_gm_percentage?: number | string;
}

interface CardType {
    isCard: true;
    data: CardDataType[];
    query_params: QueryParamsPropsType;
}

interface barChartType {
    isBarChart: true;
    data: GraphDataType[];
    query_params: QueryParamsPropsType;
}

interface PieChartType {
    isPieChart: true;
    data: GraphDataType[];
    query_params: QueryParamsPropsType;
}

type CombinedDataType = (CardType | barChartType | PieChartType)[];

interface pmAdherenceInfoState {
    pmAdherenceInfo: BaseViewCardPropsType | null;
    loading: boolean;
    error: boolean;
}

const initialState: pmAdherenceInfoState = {
    pmAdherenceInfo: null,
    loading: false,
    error: false
}

const fetchPmAdherence = createAsyncThunk<CombinedDataType, FetchDataParams>(
    PM_ADHERENCE_URL,
    async (params) => {
        const filteredParams = filterParams(params)
        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}${PM_ADHERENCE_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })
        if (!response.ok) {
            throw new Error('Failed to fetch PM Adherence')
        }
        return await response.json()
    }
)

const isCardType = (item: CardType | barChartType | PieChartType): item is CardType => {
    return !!(item as CardType).isCard;
};

const isBarChartType = (item: CardType | barChartType | PieChartType): item is barChartType => {
    return !!(item as barChartType).isBarChart;
};

const isPieChartType = (item: CardType | barChartType | PieChartType): item is PieChartType => {
    return !!(item as PieChartType).isPieChart;
};

function calculatePercentage(part: number, whole: number) {
    if (whole === 0) return 0;
    return (part / whole) * 100;
}

const pmAdherenceConvertData = (data: GraphDataType[], queryParams: QueryParamsPropsType): ProgressBarType[] => {

    const tierLabel = queryParams?.tierLabel?.toLowerCase();
    if (tierLabel === "tier 2") {
        const allHours = Number(Number(data[0].total_man_hrs_consumed_for_pm_mfg_last_7days).toFixed(2))
        const passedHour = Number(Number(data[0].remaining_time).toFixed(2))

        const percentage = calculatePercentage(passedHour, allHours).toFixed(2);
        return [{ passedHour: `${passedHour} HR`, percentage, flag: "BLUE", totalHour: `${allHours} HR` }]

    }
    return [];
};

const pieChartConvertData = (data: GraphDataType[], queryParams: QueryParamsPropsType): BaseViewPieChartType[] => {
    const tierLabel = queryParams?.tierLabel?.toLowerCase();
    if (tierLabel === "tier 3") {
        const pmPercentage = data[0].no_of_pm_percentage ? Number(data[0].no_of_pm_percentage) : 0;
        const cmPercentage = data[0].no_of_cm_percentage ? Number(data[0].no_of_cm_percentage) : 0;
        const gmPercentage = data[0].no_of_gm_percentage ? Number(data[0].no_of_gm_percentage) : 0;
        const totalPercentage = pmPercentage + cmPercentage + gmPercentage;
        const normalizedPmPercentage = Math.min(100, pmPercentage);
        const normalizedCmPercentage = Math.min(100, cmPercentage);
        const normalizedGmPercentage = Math.min(100, gmPercentage);

        return [{
            pmPercentage: `${normalizedPmPercentage}%`,
            cmPercentage: `${normalizedCmPercentage}%`,
            gmPercentage: `${normalizedGmPercentage}%`,
            totalPercentage: `${totalPercentage}%`,
        }];
    }
    return [];
};

const transformData = (pmAdherenceInfo: CombinedDataType): BaseViewCardPropsType => {
    const pmAdherenceBaseViewData: BaseViewCardPropsType = {
        cardData: {},
        graphData: [],
        graphParams: {},
        cardProps: {
            alertType: 'success'
        },
        progressBarType: [],
        pieChartType: []
    }

    const tierLabel = pmAdherenceInfo?.[0].query_params?.tierLabel

    const PmAdherenceSanitizedData: StringKeyDataProps = {};
    let overduePmWo
    let mfgClosedPmTotal
    let nonMfgClosedPmTotal
    let overduePmWos
    let percentageOfPmWoClosureAdherenceForTheMonthMfg
    let percentageOfPmWoClosureAdherenceForTheMonthNonMfg
    let pmWosTotalCmGmWosRatio
    if (pmAdherenceInfo?.[0]?.data?.length && isCardType(pmAdherenceInfo[0])) {
        overduePmWo = pmAdherenceInfo?.[0]?.data[0]?.overdue_pm_wo;
        mfgClosedPmTotal = pmAdherenceInfo?.[0]?.data[0]?.mfg_closed_pm_total;
        nonMfgClosedPmTotal = pmAdherenceInfo?.[0]?.data[0]?.non_mfg_closed_pm_total;
        overduePmWos = pmAdherenceInfo?.[0]?.data[0]?.overdue_pm_wos;
        percentageOfPmWoClosureAdherenceForTheMonthMfg = pmAdherenceInfo?.[0]?.data[0]?.percentage_of_pm_wo_closure_adherence_for_the_month_mfg;
        percentageOfPmWoClosureAdherenceForTheMonthNonMfg = pmAdherenceInfo?.[0].data[0]?.percentage_of_pm_wo_closure_adherence_for_the_month_non_mfg;
        pmWosTotalCmGmWosRatio = pmAdherenceInfo?.[0].data[0]?.pm_wos_total_cm_gm_wos_ratio;
    }

    if (typeof overduePmWo === "number" && overduePmWo >= 0) {
        PmAdherenceSanitizedData["OVERDUE PM WOS"] = overduePmWo;
    }
    if (typeof mfgClosedPmTotal === "string" && mfgClosedPmTotal.includes("/")) {
        const [closed, total] = mfgClosedPmTotal.split("/").map(Number);
        if (!isNaN(closed) && !isNaN(total) && closed >= 0 && total >= 0) {
            PmAdherenceSanitizedData["CLOSED/TOTAL MFG PM WO FOR THE MONTH"] = `${closed}/${total}`;
        }
    }
    if (typeof nonMfgClosedPmTotal === "string" && nonMfgClosedPmTotal.includes("/")) {
        const [closed, total] = nonMfgClosedPmTotal.split("/").map(Number);
        if (!isNaN(closed) && !isNaN(total) && closed >= 0 && total >= 0) {
            PmAdherenceSanitizedData["CLOSED/TOTAL NON-MFG PM WO FOR THE MONTH"] = `${closed}/${total}`;
        }
    }

    if (typeof overduePmWos === "number" && overduePmWos >= 0) {
        PmAdherenceSanitizedData["OVERDUE PM WOS"] = overduePmWos;
    }
    if (typeof pmWosTotalCmGmWosRatio === "number" && pmWosTotalCmGmWosRatio >= 0) {
        PmAdherenceSanitizedData["PM WOS/TOTAL CM & GM WOS"] = pmWosTotalCmGmWosRatio;

        const sanitizePercentage = (percentage: string) => {
            if (typeof percentage === "string" && percentage.includes("%")) {
                const numericValue = parseFloat(percentage.replace("%", ""));
                return !isNaN(numericValue) ? numericValue : 0;
            }
            return 0;
        };
        if (typeof percentageOfPmWoClosureAdherenceForTheMonthMfg === "string") {
            const sanitizedMfgPercentage = sanitizePercentage(percentageOfPmWoClosureAdherenceForTheMonthMfg);
            if (sanitizedMfgPercentage >= 0) {
                PmAdherenceSanitizedData["% PM WO CLOSURE ADHERENCE MFG"] = `${Math.round(sanitizedMfgPercentage)} %`;
            }
        }
        if (typeof percentageOfPmWoClosureAdherenceForTheMonthNonMfg === "string") {
            const sanitizedNonMfgPercentage = sanitizePercentage(percentageOfPmWoClosureAdherenceForTheMonthNonMfg);
            if (sanitizedNonMfgPercentage >= 0) {
                PmAdherenceSanitizedData["% PM WO CLOSURE ADHERENCE NON-MFG"] = `${Math.round(sanitizedNonMfgPercentage)} %`;
            }
        }
    }
    pmAdherenceBaseViewData.cardData = PmAdherenceSanitizedData

    if (pmAdherenceBaseViewData.progressBarType?.length === 0 && tierLabel?.toLowerCase() === "tier 2") {
        pmAdherenceBaseViewData.graphParams.tooltipContent = "No Data"
    } else if (tierLabel?.toLowerCase() === "tier 3") {
        pmAdherenceBaseViewData.graphParams.tooltipContent = "Proportion of PM, CM, and GM within the total"
    } else {
        pmAdherenceBaseViewData.graphParams.tooltipContent = "No Data"
    }

    // code commentde don't remove to further uses

    // if (
    //     tierLabel?.toLowerCase() === "tier 2" &&
    //     typeof overduePmWo === "number" &&
    //     overduePmWo > 0
    // ) {
    //     pmAdherenceBaseViewData.cardProps.alertType = "error"
    // } else if (
    //     tierLabel?.toLowerCase() === "tier 3" &&
    //     typeof overduePmWos === "number" &&
    //     overduePmWos > 0
    // ) {
    //     pmAdherenceBaseViewData.cardProps.alertType = "error"
    // } else {
    //     pmAdherenceBaseViewData.cardProps.alertType = "success"
    // }

    if (
        (tierLabel?.toLowerCase() === "tier 2") &&
        pmAdherenceInfo?.[1]?.data?.length &&
        isBarChartType(pmAdherenceInfo?.[1])
    ) {
        const inputData1: GraphDataType[] = pmAdherenceInfo?.[1]?.data;
        const queryParams: QueryParamsPropsType = pmAdherenceInfo?.[1]?.query_params;
        const outputData = pmAdherenceConvertData(inputData1, queryParams);
        pmAdherenceBaseViewData.progressBarType = outputData;
    } else {
        pmAdherenceBaseViewData.progressBarType = [];
        pmAdherenceBaseViewData.graphParams.isGraph = false;
    }

    if (
        tierLabel?.toLowerCase() === "tier 3" &&
        pmAdherenceInfo?.[1]?.data?.length &&
        isPieChartType(pmAdherenceInfo?.[1])
    ) {
        const inputData1: GraphDataType[] = pmAdherenceInfo?.[1]?.data;
        const queryParams: QueryParamsPropsType = pmAdherenceInfo?.[1]?.query_params;
        const outputData = pieChartConvertData(inputData1, queryParams);
        pmAdherenceBaseViewData.pieChartType = outputData;
    } else {
        pmAdherenceBaseViewData.pieChartType = [];
        pmAdherenceBaseViewData.graphParams.isGraph = false;
    }

    return pmAdherenceBaseViewData;
}

const pmAdherenceReducer = createSlice({
    name: 'pmAdherence',
    initialState,
    reducers: {
        clearPmAdherenceData(state) {
            state.pmAdherenceInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPmAdherence.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchPmAdherence.fulfilled, (state, action) => {
                state.loading = false;
                state.pmAdherenceInfo = transformData(action.payload)
            })
            .addCase(fetchPmAdherence.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export { fetchPmAdherence };

export const { clearPmAdherenceData } = pmAdherenceReducer.actions

export default pmAdherenceReducer.reducer;