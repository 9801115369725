import AohDropdown from "components/common/dropdown/aohdropdown";
import StackedBarChart from "components/common/StackedBar/StackedBar";
import { formatMTDDate, newMinMaxValue } from "components/common/StackedBar/utils";
import SummaryCard from "components/common/summaryCard/summaryCard";
import AohTable from "components/common/table/AohTable";
import { DualLineAreaGraph } from "components/resuable/charts-graph/dual-line-area-graph";
import RadioFiltersV2 from "components/resuable/radio-filters/RadioFiltersV2";
import { Button } from "@gitlab-rtsensing/component-library";
import { DownloadOutlined } from "@ant-design/icons";
import { downloadCSV, generateCSV, getMonthName, setCapsLabel } from "utils/common-methods";
import { DEEP_DIVE_UI_CONSTANTS } from "utils/wctConstants";

const RenderTierWiseComponent = ({ data, setTableType, activeKeyDeepDiveTab, tierLabel, activeFilter, handleMenuClick, extraFilterselect, deepDiveFilters, deepDiveTableColumns}: { data: any, setTableType?: (filterType: any, value: any) => any, activeKeyDeepDiveTab: string, tierLabel: string, activeFilter?: any, handleMenuClick?: any, extraFilterselect?: any, deepDiveFilters?: any, deepDiveTableColumns: any }) => {
    const renderTitle = (title: string) => (
        <><div className={"mr-bottom-20"}></div>
            <div className="tier-container">
                <h2 className="tier-title">{title}</h2>
            </div>
        </>);

    const renderSubtitle = (subtitle: string) => (
        <div className="tier-subtitle">{subtitle}</div>
    );

    const renderRadioFilters = (filterLabel: string, filterType: string, extraFilterLabel?: string, extraFilterData?: any) => (
        <>
            <RadioFiltersV2
                selectedFilter={activeFilter[filterType as keyof typeof activeFilter]}  // this has to be check once data param is fixed
                filterLabel={DEEP_DIVE_UI_CONSTANTS[filterLabel as keyof typeof DEEP_DIVE_UI_CONSTANTS]}
                radioFilter={deepDiveFilters[filterType as keyof typeof deepDiveFilters]}
                setRadioFilter={(value) => typeof setTableType === 'function' && setTableType(filterType, value)}
            />
            {extraFilterLabel && <AohDropdown
                items={extraFilterData}
                title={DEEP_DIVE_UI_CONSTANTS[extraFilterLabel as keyof typeof DEEP_DIVE_UI_CONSTANTS]}
                dropOptions={extraFilterselect[filterType as keyof typeof deepDiveFilters]}
                handleMenuClick={(selected) => handleMenuClick(selected, filterType)}
            />}
        </>

    );

    const renderDownloadButton = (data: any[], columns: any[]) => (
        <Button
            label="Download"
            className="download-btn"
            icon={
                <DownloadOutlined style={{ color: "#0063C3", fontSize: "14px" }} />
            }
            iconPosition="right"
            onClick={() => {
                if (data && columns) {
                    const csvContent = generateCSV(data, columns);
                    downloadCSV(csvContent, "Batch_Disposition_Deep_Dive.csv");
                } else {
                    console.error("No data or columns available for download");
                }
            }}
            type="secondary"
        />
    )

    if (data?.isSummary) {
        return (
            <>
                {data?.graphParams?.title && renderTitle(data.graphParams.title)}
                {data?.graphParams?.subTitle && renderSubtitle(data.graphParams.subTitle)}
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={data?.data}
                />
            </>
        );
    }

    if (data.isTable) {
        return (
            <>
                {data?.graphParams?.title && renderTitle(data.graphParams.title)}
                {data?.graphParams?.subTitle && renderSubtitle(data.graphParams.subTitle)}
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {data?.graphParams?.filterLabel && renderRadioFilters(data.graphParams.filterLabel, data.filterType)}
                    {data?.downloadButton && renderDownloadButton(data?.data, deepDiveTableColumns[data?.filterType as keyof typeof deepDiveTableColumns] || [])}
                </div>
                <AohTable
                    tableData={data?.data}
                    tableColumns={deepDiveTableColumns[data?.filterType as keyof typeof deepDiveTableColumns] || []}
                />
            </>
        );
    }

    if (data.isStackBarChart) {
        return (
            <>
                {data?.graphParams?.title && renderTitle(data.graphParams.title)}
                {data?.graphParams?.subTitle && renderSubtitle(data.graphParams.subTitle)}
                {data?.graphParams?.filterLabel && <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {renderRadioFilters(data.graphParams.filterLabel, data.filterType, data?.extraFilterLabel, data?.extraFilterData?.map((i: { Line: any; }) => ({ key: i.Line, label: i.Line })))}
                </div>
                }
                <StackedBarChart
                    data={data?.data}
                    chartType={data?.filterType}
                    xAxisData={data?.data?.map((item: any) => item.month ? getMonthName(item.month - 1) : item.test || item.week)}
                    yAxisNames={data?.graphParams?.yAxisNames?.map((item: any) => ({ ...item, max: newMinMaxValue(data?.data)?.Max }))}
                />
            </>
        );
    }
    if (data.isRunChart) {
        return (
            <>
                {data?.graphParams?.title && renderTitle(data.graphParams.title)}
                {data?.graphParams?.subTitle && renderSubtitle(data.graphParams.subTitle)}
                {data?.graphParams?.filterLabel && <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {renderRadioFilters(data.graphParams.filterLabel, data.filterType, data?.extraFilterLabel, data?.extraFilterData?.map((item: any) => ({ key: item.Line, label: setCapsLabel(item.Line) })))}
                </div>
                }

                {/* // this has to be check once data param is fixed */}
                <DualLineAreaGraph
                    data={data?.data}
                    config={{
                        areaKey: 'range',
                        xAxisKey: 'month',
                        lineKey: 'avg',
                    }}
                />
            </>
        );
    }
    return null;
};

export default RenderTierWiseComponent;