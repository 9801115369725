import { useEffect, useRef, useState } from "react";
import "./index.scss";

interface percentDetail {
  line: string;
  shift: string;
  shift_name: string;
  total_lag_ahead_hours: number;
}

interface ProgressType {
  percentageArray: percentDetail[];
}

const HorizontalBulletChart: React.FC<ProgressType> = ({ percentageArray }) => {
  const [boxWidth, setBoxWidth] = useState<number>(1);
  const containerRef = useRef<HTMLDivElement>(null);
  const onTimeValue = 0;
  useEffect(() => {
    const currentWidth = containerRef.current?.clientWidth;
    setBoxWidth(currentWidth ?? 1);
  }, [containerRef]);

  const isBeforeOnTime = parseFloat(percentageArray[0]?.total_lag_ahead_hours.toString()) < onTimeValue;
  const isAfterOnTime = parseFloat(percentageArray[1]?.total_lag_ahead_hours.toString()) > onTimeValue;
  const containerClass = isBeforeOnTime
    ? "child-div-red-container"
    : isAfterOnTime
      ? "child-div-green-container"
      : "child-div-empty-container";

  const topLag = Math.min(Math.abs(percentageArray[0]?.total_lag_ahead_hours), 50);
  const topShift = percentageArray[0]?.shift_name;
  const bottomLag = Math.min(
    Math.abs(percentageArray[1]?.total_lag_ahead_hours),
    50
  );
  const bottomShift = percentageArray[1]?.shift_name;

  return (
    <div className="horizontal-bar-container">
      <div ref={containerRef} className="horizontal-progress-container-no-round">
        {percentageArray.length > 0 && (
          <>
            <div className="horizontal-progress-bar-wrapper">
              <div className="horizontal-progress-bar-child-wrapper">
                <div
                  className={containerClass}
                  style={{
                    width: `${topLag}%`,
                    left: topLag > 0 ? `${50 - topLag}%` : "50%",
                    position: "absolute",
                    zIndex: 0,
                    height: "6px",
                    opacity: topShift === "previous" ? 0.3 : 1,
                    overflow: "hidden",
                  }}
                />
                <div className="horizontal-progress-bar-child-wrapper">
                  <div
                    className={containerClass}
                    style={{
                      width: `${bottomLag}%`,
                      left: bottomLag > 0 ? `${50 - bottomLag}%` : "50%",
                      position: "absolute",
                      zIndex: 100,
                      opacity: bottomShift === "previous" ? 0.3 : 1,
                      overflow: "hidden",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="horizontal-progress-labels">
              {percentageArray.map((data: percentDetail, i: number) => (
                <div
                  key={i}
                  className="black-line-container"
                  style={{
                    left: `50%`,
                    transform: "translateX(-50%)",
                  }}
                >
                  <div className="progress-black-line " />
                  <div className="ontime-label">ONTIME</div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HorizontalBulletChart;
