// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Calendar_calendar__Rzy4f {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0;
  gap: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}

.Calendar_day__YsyHs {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: 20px;
  border: 1px solid transparent;
}

.Calendar_green__vUyzr {
  color: #1F1F1F !important;
  background-color: #F0FFF0;
  background-color: var(--green-color, #F0FFF0); /* Use custom green color if provided */
}

.Calendar_red__fH-fx {
  color: #1F1F1F !important;
  background-color: #FFE7E6;
  background-color: var(--red-color, #FFE7E6); /* Use custom red color if provided */
}

.Calendar_disabled__PfTbV {
  color: #BFBFBF;
  background-color: transparent;
}

.Calendar_active__Eh6sR {
  color: #0063C3;
  border-color: #0063C3;
  font-weight: 700;
  background-color: transparent;
}

.Calendar_day__YsyHs:hover {
  color: #0063C3;
  border-color: #0063C3;
  cursor: default;
}
`, "",{"version":3,"sources":["webpack://./src/components/resuable/calendar/Calendar.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;EACrC,WAAM;EAAN,MAAM;EACN,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,yBAAyB;EACzB,YAAY;EACZ,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,yBAA6C;EAA7C,6CAA6C,EAAE,uCAAuC;AACxF;;AAEA;EACE,yBAAyB;EACzB,yBAA2C;EAA3C,2CAA2C,EAAE,qCAAqC;AACpF;;AAEA;EACE,cAAc;EACd,6BAA6B;AAC/B;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,gBAAgB;EAChB,6BAA6B;AAC/B;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,eAAe;AACjB","sourcesContent":[".calendar {\n  display: grid;\n  grid-template-columns: repeat(7, 1fr);\n  gap: 0;\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 18px;\n  text-align: center;\n}\n\n.day {\n  display: flex;\n  justify-content: center;\n  /* align-items: center; */\n  height: 20px;\n  border: 1px solid transparent;\n}\n\n.green {\n  color: #1F1F1F !important;\n  background-color: var(--green-color, #F0FFF0); /* Use custom green color if provided */\n}\n\n.red {\n  color: #1F1F1F !important;\n  background-color: var(--red-color, #FFE7E6); /* Use custom red color if provided */\n}\n\n.disabled {\n  color: #BFBFBF;\n  background-color: transparent;\n}\n\n.active {\n  color: #0063C3;\n  border-color: #0063C3;\n  font-weight: 700;\n  background-color: transparent;\n}\n\n.day:hover {\n  color: #0063C3;\n  border-color: #0063C3;\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calendar": `Calendar_calendar__Rzy4f`,
	"day": `Calendar_day__YsyHs`,
	"green": `Calendar_green__vUyzr`,
	"red": `Calendar_red__fH-fx`,
	"disabled": `Calendar_disabled__PfTbV`,
	"active": `Calendar_active__Eh6sR`
};
export default ___CSS_LOADER_EXPORT___;
