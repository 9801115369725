import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { FetchDataParams } from 'types/wctTypes'
import { filterParams, getMonthName, getOktaToken, setCapsLabel, setData } from 'utils/common-methods'
import { DELIVERY_CALIBRATION_URL } from 'utils/constants'
import { StringKeyDataProps } from 'utils/data-types'

interface CalibrationType {
    tooltipContent: string | number
    highlightedParams: string[]
    isHorizontalChart: boolean
    isTrendGraph: boolean
    data: any
    isCard?: Boolean
}

interface CalibrationDataType {
    horizontalChart: any
    cardData: StringKeyDataProps
    cardProps: CardProps
    trendGraphData: StringKeyDataProps[]
    graphParams?:any
}

interface CardProps {
    highlightedParams?: string[]
    alertType: "error" | "success"
}
interface CalibrationInfoStateType {
    deliveryCalibrationInfo: CalibrationDataType | null
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    error: boolean
}

const initialState: CalibrationInfoStateType = {
    deliveryCalibrationInfo: null,
    status: 'idle',
    error: false,
}

const fetchDeliveryCalibration = createAsyncThunk<CalibrationType, FetchDataParams>(
    DELIVERY_CALIBRATION_URL,
    async (params) => {
        const filteredParams = filterParams(params)
        const token: string = getOktaToken();

        const response = await fetch(
            `${process.env.REACT_APP_API_URL}${DELIVERY_CALIBRATION_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        }
        );

        if (!response.ok) {
            throw new Error('Failed to fetch batchDispoition');
        }
        return await response.json();
    }
)

const transformData = (calibration: CalibrationType[]): CalibrationDataType => {
    const deliveryCalibrationInfo: CalibrationDataType = {
        cardData: {},
        cardProps: {
            alertType: 'success',
        },
        trendGraphData: [],
        horizontalChart: [],
        graphParams: {}
    }

    for (let i = 0; i < calibration.length; i++) {
        const item = calibration[i];
        if (item.isCard) {
            deliveryCalibrationInfo.cardData = setData(item.data)
            if (item.highlightedParams) { deliveryCalibrationInfo.cardProps.highlightedParams = item.highlightedParams }
        }

        if (item.isTrendGraph) {
            deliveryCalibrationInfo.graphParams.tooltipContent = item?.tooltipContent;
            deliveryCalibrationInfo.trendGraphData = item.data?.map((item: any) => ({
                name: getMonthName(item.Month as number),
                value: item?.cal_percentage_6m
            }))
        }

        if (item.isHorizontalChart) {
            deliveryCalibrationInfo.graphParams.tooltipContent = item?.tooltipContent;
            deliveryCalibrationInfo.horizontalChart = item.data?.map((item: any) => {
                const name = setCapsLabel(String(item?.classification))
                    return {
                        name,
                        value: item.count
                    };
            });
        }
    }

    return deliveryCalibrationInfo
}

const deliveryCalibrationSlice = createSlice({
    name: 'deliveryCalibration',
    initialState,
    reducers: {
        clearDeliveryCalibrationData(state) {
            state.deliveryCalibrationInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDeliveryCalibration.pending, (state) => {
                state.status = 'loading'
                state.error = false
            })
            .addCase(fetchDeliveryCalibration.fulfilled, (state, action: PayloadAction<CalibrationType>) => {
                state.status = 'succeeded'
                state.deliveryCalibrationInfo = transformData(action.payload as any)
            })
            .addCase(fetchDeliveryCalibration.rejected, (state) => {
                state.status = 'failed'
                state.error = true
            })
    }
})

export { fetchDeliveryCalibration }

export const { clearDeliveryCalibrationData } = deliveryCalibrationSlice.actions

export default deliveryCalibrationSlice.reducer