import moment from "moment";
import {
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  DefaultLegendContent,
  ResponsiveContainer,
} from "recharts";

interface DualLineAreaGraphPropsType {
  config: {
    xAxisKey: string;
    areaKey: string;
    lineKey: string;
  };
  data: Array<{
    name: string;
    minMax: Array<number>;
    lineValue: number;
  }>;
}

// Function to format date string to month
const formatDateToMonth = (dateString: string) => {
  if (!dateString) {
    return 'N/A';
  }
  const checkDateFormat = dateString.includes('-');
  if (!checkDateFormat) {
    return dateString;
  }
  const momentCheck = moment(dateString, 'YYYY-MM-DD');
  if (!momentCheck.isValid()) {
    return dateString;
  }
  return momentCheck.format('DD-MMM');
};

export const DualLineAreaGraph = ({ data, config }: DualLineAreaGraphPropsType) => {
  const { xAxisKey, areaKey, lineKey } = config;
  return (
    <ResponsiveContainer width="98%">
    <ComposedChart
      data={data || []}
      margin={{
        top: 10,
        right: 30,
        left: 0,
        bottom: 0,
      }}
    >
      {/* <CartesianGrid strokeDasharray="3 3" /> */}
      <XAxis
        dataKey={xAxisKey}
        tickFormatter={formatDateToMonth} />
      <YAxis />
      <Tooltip />
      <Area
        dataKey={areaKey}
        stroke="none"
        fill="#0063c3"
        fillOpacity={0.2}
        baseValue="dataMin"
      />
      <Line
        type="linear"
        dataKey={lineKey}
        stroke="#0063c3"
        strokeWidth={2}
        dot={{ stroke: "none", strokeWidth: 1, r: 4, strokeDasharray: '', fill: "#0063c3" }} />
    </ComposedChart>
    </ResponsiveContainer>
  );
};
